<template>
  <div cookie v-if="active">
    <div class="holder">
      <p v-t:cookie></p>
      <a class="accept-btn" @click="accept" v-t:cookieBtn></a>
    </div>
  </div>
</template>

<script>
import { getCookie, setCookie } from '@/utils';

export default {
  name: 'Cookie',
  data() {
    return {
      active: false,
    };
  },
  methods: {
    accept() {
      setCookie('cookie-allow', 1);
      this.active = false;
    },
  },
  mounted() {
    if(this.lang !== 'ko') this.active = !getCookie('cookie-allow');

  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";

[cookie] { .fix; .lb; .p(12,18); .wf; .-box; z-index: 99; .flex; .justify-center;
  .holder { .rel; .p(16, 24, 66); .bgc(#000); color: #fff; .br(8); .crop; .fs(14, 22, -.25); .medium; .max-w(700); }
  p a { .underline; }
  .accept-btn { .abs; .rb(16, 16); .p(6, 24); .bgc(#393939); .br(4); .crop; }
}
</style>
