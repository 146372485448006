<template>
  <div slider :class="{initialized}" @click="clicked">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <slot/>
      </div>
    </div>
    <div class="swiper-scrollbar" v-if="scrollbar"></div>
    <div class="swiper-pagination" v-if="pagination"></div>
    <div class="swiper-navigation" v-if="navigation">
      <a class="swiper-button-prev"/>
      <a class="swiper-button-next"/>
    </div>
    <div class="plain" v-if="usePlain" :data-stay="plainStay">
      <slot/>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Swiper from 'swiper/swiper-bundle.min';
import { closest, elementIndex } from '@/utils';

export default {
  name: 'Slider',
  props: {
    pagination: { type: Boolean, default: false },
    paginationType: { type: String, default: 'bullets' },
    navigation: { type: Boolean, default: false },
    loop: { type: Boolean, default: false },
    centeredSlides: { type:Boolean, default: false },
    scrollbar: { type:Boolean, default: false },
    thumbs: { type:Boolean, default: false },
    freeMode: { type:Boolean, default: false },
    useThumbs: { type:Boolean, default: false },
    usePlain: { type:Boolean, default: false },
    plainStay: { type:Boolean, default: false },
    preventTouchMove: { type:Boolean, default: false },
    spaceBetween: { type: Number, default: undefined },
    slidesPerView: { type: String, default: undefined },
    effect: { type: String, default: undefined },
    breakpoints: { type: Object, default: undefined },
    delayed: { type: Boolean, default: false },
    speed: {type: Number, default: 700 },
    threshold: { type: Number, default: 5 },
    watchOverflow: {type: Boolean, default: false},
    clickActive: {type: Boolean, default: false},
    autoplay: {type: Boolean, default: false},
    initialSlide: {type: Number, default: 0},
    option: Object,
  },
  data() {
    return {
      slider: null,
      intervalID: -1,
      opt: null,
      timer: null,
      changedListener: [],
      sliderMoveListener: [],
      initialized: false,
    };
  },
  mounted() {
    if (!this.useThumbs) this.init();
  },
  beforeDestroy() {
    if (this.slider) this.slider.destroy();
  },
  methods: {
    async init(thumbsSwiper) {
      await document.fonts.ready;
      this.intervalID = setInterval(() => {
        if (_.every(this.$el.querySelectorAll('img'), e => e.complete)) {
          window.clearInterval(this.intervalID);
          this.opt = _.extend({
            loop: this.loop,
          }, this.option);

          if (this.pagination) {
            this.opt.pagination = {
              el: this.$el.querySelector('.swiper-pagination'),
              clickable: true,
              type: this.paginationType
            }
          }

          if (this.autoplay) {
            this.opt.autoplay = { delay: 5000 };
          }

          if (thumbsSwiper) {
            this.opt.thumbs = { swiper: thumbsSwiper, autoScrollOffset: 1, multipleActiveThumbs: false };
          }

          if (this.spaceBetween) {
            this.opt.spaceBetween = this.spaceBetween;
          }

          if (this.centeredSlides) {
            this.opt.centeredSlides = this.centeredSlides;
          }

          if (this.slidesPerView) {
            this.opt.slidesPerView = this.slidesPerView;
          }

          if (this.freeMode) {
            this.opt.freeMode = true;
          }

          if (this.thumbs) {
            this.opt.watchSlidesVisibility = true;
            this.opt.watchSlidesProgress = true;
          }

          if (this.breakpoints) {
            this.opt.breakpoints = this.breakpoints;
          }

          if (this.effect) {
            this.opt.effect = this.effect;
            this.opt.fadeEffect = { crossFade: true };
          }

          if (this.speed) {
            this.opt.speed = this.speed;
          }

          if (this.preventTouchMove) {
            this.opt.allowTouchMove = false;
          }

          if (this.watchOverflow) {
            this.opt.watchOverflow = true;
          }

          if (this.threshold) {
            this.opt.threshold = this.threshold;
          }

          if (this.initialSlide) {
            this.opt.initialSlide = this.initialSlide;
          }

          if (this.scrollbar) {
            this.opt.scrollbar = {
              el: this.$el.querySelector('.swiper-scrollbar'),
              hide: false,
            };
          }

          if (!this.opt.on) this.opt.on = {};
          if (this.opt.on.slideChange) this.changedListener.push(this.opt.on.slideChange);
          this.opt.on.slideChange = this.slideChanged;
          if (this.opt.on.sliderMove) this.sliderMoveListener.push(this.opt.on.sliderMove);
          this.opt.on.sliderMove = this.sliderMove;

          if (this.delayed) {
            const offsetRatio = 0.5;
            this.opt.watchSlidesProgress =true;
            this.opt.on.progress = () => {
              if (!this.slider) return;
              _.forEach(this.slider.slides, slide => {
                const t = slide.progress * this.slider.width * offsetRatio;
                slide.querySelector(".img").style.transform = "translate3d(" + t + "px, 0, 0)"
              });
            };

            this.opt.on.setTransition = (ignored, speed) => {
              if (!this.slider) return;
              _.forEach(this.slider.slides, slide => {
                slide.style.transition = speed + "ms";
                slide.querySelector(".img").style.transition = speed + "ms";
              });
            };
          }

          if (this.$el.querySelector('.swiper-wrapper > .swiper-slide') === null) {
            _.forEach(this.$el.querySelectorAll('.swiper-wrapper > *'), (e) => {
              e.classList.add('swiper-slide');
            });
          }

          if (this.$props.navigation) {
            this.opt.navigation = {
              nextEl: this.$el.querySelector('.swiper-button-next'),
              prevEl: this.$el.querySelector('.swiper-button-prev'),
            };
          }

          this.slider = new Swiper(
            this.$el.querySelector('.swiper-container'),
            this.opt,
          );
          this.$emit('initialized', this.slider);
          this.initialized = true;
        }
      }, 100);
    },
    stopAuto() {
      this.slider.autoplay.stop();
    },
    startAuto() {
      this.slider.autoplay.start();
    },
    prev() {
      this.slider.slidePrev();
    },
    next() {
      this.slider.slideNext();
    },
    go(index) {
      if (this.slider) this.slider.slideTo(index);
    },
    now() {
      return this.slider ? this.slider.realIndex : 0;
    },
    disable() {
      if (this.slider) {
        this.slider.destroy(true, false);
        this.$el.querySelector('.swiper-wrapper').style.transform = 'none';
      }
    },
    enable() {
      this.slider = new Swiper(
        this.$el.querySelector('.swiper-container'),
        this.opt,
      );
    },
    clicked(e) {
      if (this.clickActive) {
        const el = closest(e.target, '.swiper-slide');
        if (!el) return;
        const index = elementIndex(el);
        this.go(index);
      } else {
        const el = closest(e.target, '[data-click]');
        if (el) this.$emit('click', el.dataset.click);
      }
    },
    slideChanged() {
      this.$emit('changed', this.now());
      _.forEach(this.changedListener, listener => listener(this.now()));
    },
    sliderMove() {
      _.forEach(this.sliderMoveListener, listener => listener(this.now()));
    },
    setTimer(timer) {
      this.changedListener.push(timer.changed);
      this.sliderMoveListener.push(timer.sliderMove);
    },
    tiedUp(other) {
      other.controller.control = this.slider;
      this.slider.controller.control = other;
    }
  },
};

</script>

<style lang="less">
@import "~@/less/proj.less";

[slider] { .hidden;
  &.initialized { .visible; }
}
</style>
