<template>
  <div module-item :class="info.type">
    <template v-if="info.type === 'prepend-desc'">
      <p v-html="info.value.desc"></p>
      <h3 v-html="info.value.text"></h3>
    </template>
    <template v-else-if="info.type === 'h0'">
      <h3>{{ info.value.text }}</h3>
    </template>
    <component :is="info.type" v-else>{{ info.value.text }}</component>
  </div>
</template>

<script>

export default {
  name: 'Title',
  props: ['value'],
  computed: {
    info() {
      return this.value;
    },
  },
}
</script>

