<template>
  <main news-article :class="type" :key="info.slug">
    <p-section :theme="theme" class="hero" v-if="hero">
      <div class="text-area">
        <p class="tag">{{ formDate(info.publishDtt) }} <span>{{ codeLabel(info.priorityCategory) }}</span></p>
        <h2>{{ hero.title }}</h2>
        <div class="desc" v-if="hero.desc" v-html="hero.desc"></div>
        <div class="share-btn-wrap">
          <SnsShare type="news-article" :value="info"></SnsShare>
        </div>
      </div>
      <div class="kv-img" v-if="hero.image && type !== 'common' && type !== 'full-video'">
        <cdn-img :src="hero.image.pc" class="hidden-ml-down" />
        <cdn-img :src="hero.image.mobile" class="hidden-tp-up"/>
      </div>
      <div class="kv-img" v-if="hero.video && type === 'full-video'">
        <cdn-video playsinline muted autoplay loop :src="hero.video.pc || hero.video" class="hidden-ml-down" />
        <cdn-video playsinline muted autoplay loop :src="hero.video.mobile || hero.video" class="hidden-tp-up" />
      </div>
    </p-section>
    <p-section theme="default">
      <template v-for="(item, i) in content">
        <Component :is="item.comp" :value="item" :key="`${item.type}-${i}`" v-if="item.value"/>
        <hr class="line" :key="`${item.type}-${i}`" v-else-if="item.comp === 'AppendixLine'"/>
      </template>
    </p-section>
    <section class="tags" v-if="!isEmpty(tags)">
      <ul>
        <li v-for="tag in tags" :key="tag"><a @click="onSearch(tag)">#{{ tag }}</a></li>
      </ul>
    </section>
    <section class="related" v-if="!isEmpty(related)">
      <p class="name">RELATED</p>
      <ul>
        <li v-for="item in related" :key="item.sq">
          <locale-link :to="`/${root}/article/${item.slug}`">
            <div class="img-wrap">
              <img v-if="root === 'play'" class="load-img" src="/img/common/play-loading-img-h.png"/>
              <div v-else class="load-news"></div>
              <cdn-img :src="item.image" />
            </div>
            <div class="text">
              <p class="tag">{{ formDate(item.publishDtt) }} <span>{{ codeLabel(item.priorityCategory) }}</span></p>
              <p class="title">{{ item.title }}</p>
            </div>
          </locale-link>
          <div v-if="root === 'play'" class="sns-wrap">
            <SnsShare type="news-article" :value="item" :share-path="getSlugUrl(item)"></SnsShare>
          </div>
        </li>
      </ul>
    </section>
  </main>
</template>

<script>
import oax from '@/utils/oax';
import CdnImg from '@/views/components/CdnImg';
import Slider from '@/views/components/Slider';
import AudioPlayer from '@/views/components/AudioPlayer';
import Title from '@/views/components/modules/Title';
import BasicText from '@/views/components/modules/BasicText';
import TitleText from '@/views/components/modules/TitleText';
import Quote from '@/views/components/modules/Quote';
import Profile from '@/views/components/modules/Profile';
import Footnote from '@/views/components/modules/Footnote';
import DotList from '@/views/components/modules/DotList';
import NumberList from '@/views/components/modules/NumberList';
import Buttons from '@/views/components/modules/Buttons';
import SingleImage from '@/views/components/modules/SingleImage';
import Banner from '@/views/components/modules/Banner';
import DoubleImage from '@/views/components/modules/DoubleImage';
import Carousel from '@/views/components/modules/Carousel';
import CarouselDot from '@/views/components/modules/CarouselDot';
import LinkUpload from '@/views/components/modules/LinkUpload';
import SingleVideo from '@/views/components/modules/SingleVideo';
import Blank from '@/views/components/modules/Blank';
import Reference from '@/views/components/modules/Reference';
import Author from '@/views/components/modules/Author';
import Audio from '@/views/components/modules/Audio';
import SnsShare from "@/views/components/SnsShare";
import { i18n } from '@/plugins/i18n';
import CdnVideo from '@/views/components/CdnVideo';

export default {
  name: 'news-article',
  components: {
    CdnVideo,
    SnsShare, Audio, Author, Reference, Blank, SingleVideo, LinkUpload, CarouselDot, Carousel, DoubleImage, Banner, SingleImage, Buttons, NumberList, DotList, Footnote, Profile, Quote, TitleText, BasicText, Title, AudioPlayer, Slider, CdnImg },
  data() {
    return {
      info: null,
      related: null,
    };
  },
  metaInfo() {
    if (!this.info) return null;
    return {
      title: this.info.title,
      meta: [
        { vmid: 'title', content: this.info.metaTitle || this.$t('meta.title'), },
        { vmid: 'description', content: this.info.metaDesc || this.$t('meta.description'), },
        { vmid: 'image', content: this.info.metaImage ? `${process.env.VUE_APP_CDN_HOST}/${this.mediaVal(this.info.metaImage).location}` : '/img/og.png', },
        { vmid: 'path', content: this.$route.path.replace(new RegExp(`^/(${i18n.routeMatches()})`), '') },
      ],
    }
  },
  computed: {
    theme() {
      return this.type in this.values('full', 'full-video') ? 'dark-header' : 'default';
    },
    type() {
      return this.info.type;
    },
    hero() {
      return this.info.hero;
    },
    content() {
      return this.info.content;
    },
    tags() {
      return this.info.tagList?.split(',');
    },
  },
  methods: {
    onSearch(tag) {
      // this.$root.$emit('search', tag);
      this.localeLink(`/search?searchData=${tag}`);
    },
  },
  mounted() {
    this.$store.commit('theme', this.theme);
  },
  async asyncData({ route }) {
    const info = (await oax.get(`/api/article/${route.meta.root}/${encodeURIComponent(route.params.slug)}`, { lang: route.params.locale || 'ko' })).data;
    if (!info) throw new Error("404");
    const related = (await oax.get(`/api/article/related/${ info.sq }`, { lang: route.params.locale || 'ko' })).data;

    const contents = info.contentEncode === 'base64' ? JSON.parse(decodeURIComponent(atob(info.contents))) : JSON.parse(info.contents);
    info.hero = contents.hero;
    info.content = contents.content;

    return { info, related };
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[news-article] {.pb(140);
  a{text-decoration: underline; text-underline-position: under;}
  em{font-style: italic;}
  .hero{.p(132,0,48); .rel; z-index: 2;
    .text-area{.m-mw;
      h2,.tag,.share-btn-wrap{.wf;}
    }
    h2{.fs(28,1.5,-0.25); color:#1e1e1e; .pb(36); }
    .tag{.fs(12,1.5,-0.25); color:#333; .pb(24);
      span{.ib; .pl(12); .medium;}
    }
    .desc{
      p{.rel; .pl(15); .fs(16,1.94,-0.25); color:#1e1e1e; .medium;
        &:before {.cnt; .abs; .lt(0,15); .wh(4); .t-yc; background-color: #1e1e1e; .br(50%);}
      }
    }
    [sns-share]{.wh(40); .bgc(transparent); .mb(48); .bg('/img/common/btn-share-black.png'); .contain; .no-repeat;}
    [cdn-img]{.wf; .block;}
  }
  &.common .hero{.pb(36);
    .kv-img{.max-w(1100); margin:0 auto; .hide;}
    [cdn-img]{.wf;}
    .share-btn-wrap{
      [sns-share]{.m(0);}
    }
  }
  &.magazine .hero{.tc; .max-w(1920); .mh-c;
    .kv-img{.wf; .h(500); .rel; overflow:hidden;
      [cdn-img]{.f; object-fit: cover; display:block;}
    }
    .share-btn-wrap{
      [sns-share]{.mh-c;}
    }
  }
  &.webtoon .hero{.tc; .pb(36);
    .share-btn-wrap{
      [sns-share]{.m(0);}
    }
  }
  &.full .hero,
  &.full-video .hero{.w(100%); height:100vh; .-box; .mb(48); .rel;
    .text-area{.abs; z-index:2; .wf; .m(0); .p(0,18); .-box; .lb(50%,0); transform:translateX(-50%);}
    .tag,h2,.share-btn-wrap{.rel; color:#fff; .tc;}
    button{.bg('/img/common/btn-share-white.png');}
    .kv-img{z-index:0; .f; .abs; overflow:hidden; .lt(0,0);
      [cdn-img],[cdn-video]{.f; object-fit: cover;}
    }
    .share-btn-wrap{
      [sns-share]{.mh-c;}
    }
  }
  &.webtoon{
    .hero{
      .share-btn-wrap{
        [sns-share]{.mh-c;}
      }
    }
    .close-image{.p;
      img{.block;}
    }
  }
  .h0 h3{.fs(48,1.08,-0.25); .extra-bold; .p(16,0); color:#1e1e1e; .m-mw;}
  h3{.fs(26,1.5,-0.25); .bold; .p(16,0); color:#1e1e1e; .m-mw;}
  h4{.fs(20,1.6,-0.25); .bold; .p(16,0); color:#1e1e1e; .m-mw;}
  h5{.fs(18,1.5,-0.25); .bold; .p(20,0,10); color:#1e1e1e; .m-mw;}
  .prepend-desc{.pt(16);
    p{.fs(14,1.71); color:#004c98; .bold; .pb(8); .m-mw;}
    h3{.pt(0);}
  }
  .body-text{ .p(16,0); .m-mw;
    p{.fs(16,1.94,-0.25); color:#555; .min-h(16);}
  }
  .subtitle-text{.m-mw;
    i{.hide};
    h5{.pb(0); .m(0);}
    .body-text{.m(0);}
  }
  .list-text, .list-text-1, .list-text-2, .list-text-3, .list-text-4{.m-mw;
    .title{.rel; .mt(16);
      i{.abs; .wh(20); .lt(0,14); transform:translateY(-50%); .ib; .bg('/img/common/list-head.png'); .contain;}
      h5{.p(0); .pl(20); .m(0); .fs(16,1.94,-0.25);}
    }
    .body-text{.m(0);}
    &.list-text-1 .title i { .bg('/img/common/list-head-1.png'); }
    &.list-text-2 .title i { .bg('/img/common/list-head-2.png'); }
    &.list-text-3 .title i { .bg('/img/common/list-head-3.png'); }
    &.list-text-4 .title { i { .hide; } h5 { .pl(0); }}
  }
  .small-text{.fs(14,1.71,-0.25); color:#555; .m-mw; .p(16,0);
    p { .min-h(14); }
  }

  .short-quote,.long-quote,.en-quote{.p(60,0); .tc;.m-mw;}
  .quote-comma{.block; .mh-c; .wh(24); .contain;
    &:first-of-type{.mb(36); .bg('/img/common/quote-comma.png'); .contain;}
    &:last-of-type{.mt(36); .bg('/img/common/quote-comma-close.png');}
  }
  .short-quote{
    p.main{.fs(20,1.7,-0.25); color:#1e1e1e; .bold; }
    .sub{.fs(16,1.5,-0.25); .medium; color:#888; .pt(24);}
  }
  .long-quote{
    p.main{.fs(18,1.78,-0.25); color:#555; .medium; }
    .sub{.fs(15,1.67,-0.25); color:#555; .pt(24);}
  }
  .en-quote{
    p.main{.fs(22,1.55,-0.25); color:#555; .medium; }
    .sub{.fs(15,1.67,-0.25);color:#555; .pt(12);}
  }
  .profile{.m-mw;
    .name{.fs(20,1.6,-0.25); color:#333; .bold; .p(16,0,8);}
    .sum{.fs(16,1.94,-0.25); color:#333; .pb(24); .medium;}
    .body-text{.m(0); .p(0,0,24);}
    hr{.block; .-t(#ddd); .mb(16);}
  }
  .footnote{.m-mw;
    li{.fs(12,1.67,-0.25); color: #014890; .pt(12);
      span{.ib; .vat;
        &:first-of-type{.w(24);}
        &:last-of-type{ width: calc(100% - 28px);}
      }
    }
  }
  .dot-list{ .m-mw; .p(16,0);
    p { .rel; .pl(18); .fs(16,1.94,-0.25); color:#555;
      &:before { .cnt; .abs; .lt(0,16); .wh(4); .t-yc; background-color: #555; .br(50%); }
    }
  }
  .indent-dot-list{.m-mw; .p(0,0,16,20);
    p { .rel; .pl(22); .fs(16,1.94,-0.25); color:#555;
      &:before { .cnt; .abs; .lt(0,16); .wh(4); .t-yc; background-color: #555; .br(50%); }
    }
  }
  .number-list{.m-mw; .p(16,0); counter-reset: number;
    p{.rel; .pl(26); .rel;counter-increment: number 1; .fs(16,1.94,-0.25); color:#555;
      &:before{.abs; .lt(0,0);  content: counter(number)'.';
      }
    }
  }
  .basic-btn,.desc-btn,.title-basic-btn,.title-desc-btn {.m-mw; .-b(#ddd);
    .title{.fs(20,1.45,-0.25); color:#1e1e1e; .bold; .p(48,0,20);}
    a,.none-btn{.fs(15,1.4,-0.25); color:#333; .bold;  .m(24,0); .pr(56); .wf; .block; .rel; text-decoration: none; .-box;
      i{.contain; .wh(20); .ib; .vam; .abs; .rt(0,0);
        &.landing{.bg('/img/common/btn-icon-landing.png');}
        &.outlink{.bg('/img/common/btn-icon-outlink.png');}
        &.download{.bg('/img/common/btn-icon-download.png');}
      }
    }
    .desc{.fs(15,1.67,-0.25); color:#888; .w(85%); .pb(24);}
    &.desc-btn, &.title-desc-btn{
      a,.none-btn{.mb(10);}
    }
  }
  .basic-image, .basic-image-left, .basic-image-right, .full-image{.p(48,0);
    img{.wf; .block;}
    .desc{.fs(14,1.58,-0.25); color:#888; .pt(16); .tc;}
  }
  .basic-image-left .desc { margin-left: 0 !important; .tl; }
  .basic-image-right .desc { margin-right: 0 !important; .tr; }
  .basic-image, .close-image, .zero-image,
  .basic-image-left, .close-image-left, .zero-image-left,
  .basic-image-right, .close-image-right, .zero-image-right {.m-mw;}
  .close-image, .close-image-left, .close-image-right {.p(8,0);}
  .zero-image, .zero-image-left, .zero-image-right {.p(0,0);}
  .full-image{
    .desc{.m-mw;}
  }
  .basic-image-left, .close-image-left,.zero-image-left { .tl; img { .ib; }}
  .basic-image-right, .close-image-right,.zero-image-right { .tr; img { .ib; }}
  .banner{.m-mw; .p(12,0); .block;
    a{.block;
      img{.wf;}
    }
  }
  .double-image{.p(48,0); .m-mw;
    .left{.mb(24);}
    .left, .right{.block;
      img{.wf; .block;}
    }
    .desc{.fs(12,1.58,-0.25); color:#888; .pt(16); .tc;}
  }
  .carousel { .crop; }
  .basic-carousel{.w(284); .m-mw; .p(48,0);
    .swiper-container{overflow:visible;.w(284);
      .slide-item{ .block; .mr(16);
        .image-area{.wf; .h(179); overflow:hidden;justify-content: center; align-content: center; .flex;
          img{.wf; .block; max-height:100%; object-fit: cover;}
        }
        &:last-of-type{.m(0);}
        .desc{.fs(12,1.58,-0.25); color:#888; .pt(16); .tc;}
      }
    }
    .swiper-navigation{.hide; z-index:5;
      .swiper-button-prev,.swiper-button-next{color:transparent; .wh(36); .contain; .block; }
      .swiper-button-prev {.lt(-54,50%); transform:translateY(-50%); .bg('/img/common/carousel-prev.png'); }
      .swiper-button-next{.rt(-54,50%); transform:translateY(-50%); .bg('/img/common/carousel-next.png');}
      .swiper-button-disabled{.o(0);}
    }
  }
  .dot-carousel{.wf; .p(48,0); .rel;
    .swiper-container{
      .slide-item { .block;
        .image-area {.wf; .h(248); overflow: hidden;justify-content: center; align-content: center; .flex;
          img {.wf; .block; max-height:100%; object-fit: cover;}
        }
        .desc{.fs(12,1.58,-0.25); color:#888; .pt(16); .tc;}
      }

    }
    .swiper-navigation{.hide; z-index:5;
      .swiper-button-prev,.swiper-button-next{color:transparent; .wh(36); .contain;}
      .swiper-button-prev {.lt(0,50%); transform:translateY(-50%); .bg('/img/common/carousel-prev.png'); }
      .swiper-button-next{.rt(0,50%); transform:translateY(-50%); .bg('/img/common/carousel-next.png');}
      .swiper-button-disabled{.o(0);}
    }
    .swiper-pagination{.l(50%); transform: translateX(-50%); .rel; .block; .mt(20);
      .swiper-pagination-bullet{.m(0,6); .wh(8); .bgc(#ddd); .o(1);}
      .swiper-pagination-bullet-active{.bgc(#888);}
    }
  }
  .youtube{.p(48,16);
    .video-wrap{.rel; .wf; .pt(56.25%);
      .youtube-video{.abs; .lt(0,0); .rb(0,0); .f; }
    }
    .desc{.fs(12,1.58,-0.25); color:#888; .pt(16); .tc;}
  }
  .single-video, .single-video-loop {.m-mw; .p(48,0);
    video{.wf;}
    .desc{.fs(12,1.58,-0.25); color:#888; .pt(16); .tc;}
  }
  .big-video, .big-video-loop { .wf; .max-w(1920); .mh-c;
    video{.wf;}
    .desc{.fs(12,1.58,-0.25); color:#888; .pt(16); .tc;}
  }
  .line{.m-mw; .mt(16); .mb(16); .-t(#ddd);}
  .blank{
    &.blank-1{.h(24);}
    &.blank-2{.h(48);}
    &.blank-25{.h(60);}
    &.blank-4{.h(96);}
    &.blank-5{.h(120);}
  }
  .reference{.m-mw; .p(60,0);
    .title{.p(24,0); .fs(14,1.71,-0.25); color:#555; .medium; .-t(#ddd); .-b(#ddd);}
    .text{.pt(16);
      p{.fs(12,1.83,-0.25); color:#555; .pb(16);
        a{color:#014890; text-decoration: none;}
      }
    }
  }
  .author,.author-title{ .m-mw; .p(16,0);
    .profile-img{.wh(60); .ib; .vam;
      img{.f;.br(50%);}
    }
    .text-area{.ib; width:calc(100% - 66px); .-box; .pl(24); .vam;
      .name{ .fs(16,1.5,-0.25); .medium;}
    }
    .text{.fs(14,1.57,-0.25); color:#666; .pt(16);}
  }
  .audio{.m-mw; .p(16,0);
    .desc{.pt(16); .fs(12,1.58,-0.25); color:#333; .medium;}
  }


  section{
    &.tags{.pt(48); .m-m;
      ul{
        li{.ib; .mr(10); .mb(10);
          a {.block; .fs(14); .br(4); .medium; .c(#666); .-a(#e0e0e0); .p(4,8,5); text-decoration: none;}
        }
      }
    }
    &.related{.pt(135); .m-m; .proj-max-w;
      .name{.fs(24); .bold; .pb(24);}
      >ul{display: grid; grid-template-columns: 1fr;
        li{.mb(48);
          a{text-decoration: none;}
          &:last-of-type{.mb(0);}
          .no-touch &:hover{
            .img-wrap{
              img {transform: scale(1.1);}
            }
          }
          .img-wrap{.mb(20); .wf; aspect-ratio: 17 / 9; overflow:hidden; .rel;
            img{.f; object-fit: cover; transform: scale(1); transition: transform 1s cubic-bezier(0, 0, 0.13, 1.04);}
          }
          p{.c(#333);}
          .tag{.fs(12);  .pb(10);
            span{.ml(12); .ib; .medium;}
          }
          .title{.fs(18,1.61,-0.25); .bold;}
        }
      }
    }
  }
  &.play section.related{
    >ul{
      li{ .mb(20); .rel;
        .img-wrap{ aspect-ratio: 9 / 15; .mb(0);}
        p{.c(#fff);}
        .text{.abs; .wf; .-box; .lb(0,0); .p(36,24);   background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));}
        .sns-wrap {.abs; .lt(0, 0); .wf; .h(70); background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
          [sns-share] { .rel; .wh(24); .m(12, 14); .abs; .rt(0, 0);
            a.btn{.bg('/img/common/ic-share-more.png'); .no-repeat; .contain;}
            .share-popup { .rt(0, 25); transform-origin: right top;}
          }
        }
      }
    }
  }
}

@media (min-width: @screen-tp-min) {
  [news-article] { .pb(180);
    .hero{.p(132,0,48);
      h2{.fs(34,1.5,-0.25); .w(650); .mh-c;}
      .text-area{.t-mw;}
    }
    &.common .hero{
      .desc{.pb(36);}
      .share-btn-wrap{
        [sns-share]{.m(0);}
      }
    }
    &.magazine .hero{
      .kv-img{aspect-ratio: 16 / 9; height:auto;}
    }
    &.full .hero,
    &.full-video .hero{.p(0); .wf; height:auto;  aspect-ratio: 16 / 9;
      .text-area{.p(0); .t-mw;}
      h2{.pb(24);}
      button{.mb(36);}
    }
    .h0 h3{.fs(48,1.08,-0.25); .t-mw; .p(20,0);}
    h3{.fs(26,1.5,-0.25); .t-mw; .p(20,0);}
    h4{.fs(20,1.6,-0.25); .t-mw; .p(20,0);}
    h5{.t-mw;}
    .prepend-desc{.pt(20);
      p{.t-mw;}
    }
    .body-text{.p(20,0); .t-mw; }
    .subtitle-text{.t-mw;}
    .list-text, .list-text-1, .list-text-2, .list-text-3, .list-text-4{.t-mw;
      .title{.mt(20);
        h5{.pl(24); }
      }
    }
    .small-text{.t-mw; .p(20,0);}
    .short-quote,.long-quote,.en-quote{.t-mw;}
    .short-quote{
      p.main{.fs(24,1.58,-0.25);}
      .sub{.fs(16,1.63,-0.25);}
    }
    .long-quote{
      p.main{.fs(20,1.7,-0.25);}
    }
    .en-quote{
      .quote-comma{
        &:first-of-type{.mb(24);}
        &:last-of-type{.mt(24);}
      }
    }
    .profile{.t-mw;
      .name{.p(20,0,8);}
      .sum{.fs(16,1.75,-0.25); .pb(20);}
      .body-text{.m(0); .p(0,0,36);}
      hr{.block; .-t(#ddd); .mb(20);}
    }
    .footnote{.t-mw;}
    .dot-list{.t-mw; .p(20,0);}
    .indent-dot-list{.t-mw; .p(0,0,20,19);}
    .number-list{.t-mw; .p(20,0);}
    .basic-btn,.desc-btn,.title-basic-btn,.title-desc-btn {.w(384); .mh-c;
      .title{.p(48,0,20);}
    }
    .basic-image, .basic-image-left, .basic-image-right, .full-image{.p(60,0);
      .desc{.pt(16); .t-mw;}
    }
    .close-image, .close-image-left, .close-image-right {.p(12,0);}
    .zero-image, .zero-image-left, .zero-image-right {.p(0,0);}
    .basic-image, .close-image, .zero-image,
    .basic-image-left, .close-image-left, .zero-image-left,
    .basic-image-right, .close-image-right, .zero-image-right {.w(588); .mh-c;
      img{width:auto; margin:0 auto; .max-w(100%);}
    }
    .basic-image-left, .close-image-left, .zero-image-left,
    .basic-image-right, .close-image-right, .zero-image-right {
      img { margin:0; }
    }
    .banner{.t-mw;}
    .double-image{.p(60,0); .w(588); .mh-c;
      .left, .right{.w(50%); .ib; .-box;}
      .left{.m(0); .pr(10);}
      .right{.pl(10);}
      .desc{.pt(20);}
    }
    .basic-carousel{ .t-mw; .p(60,0); .rel;
      .swiper-container{.t-mw; .w(375); .m(0);
        .slide-item{ .mr(20);
          .image-area{.h(236); .wf;}
          .desc{.pt(20);}
        }
      }
      .swiper-navigation{.block;}
    }
    .dot-carousel{.w(588); .mh-c; .p(60,0);
      .swiper-container{.w(588); .mh-c;
        .slide-item {.wf;
          .image-area { .h(389); .wf;}
          .desc{.t-mw; .pt(20);}
        }
      }
      .swiper-navigation{.block;}
      .swiper-pagination{.mt(24);}
    }
    .youtube{.p(60,0); .w(588); .mh-c;
      .desc{.pt(20);}
    }
    .single-video, .single-video-loop {.w(588); .mh-c; .p(60,0);
      .desc{.pt(20);}
    }
    .big-video, .big-video-loop {
      .desc{.pt(20);}
    }
    .line{.t-mw; .mt(20); .mb(20);}
    .reference{.t-mw;
      .text{.pt(20);
        p{.fs(14,1.57,-0.25); .pb(20);}
      }
    }
    .author,.author-title{ .t-mw; .p(20,0);
      .text{.pt(20);}
    }
    .audio{.t-mw; .p(20,0);
      .desc{.pt(20);}
    }


    section{
      &.tags{.p(84,0,0); .t-mw;}
      &.related{.pt(140); .t-m;
        >ul{grid-template-columns: 1fr 1fr; column-gap: 18px;
          li{.mb(56);}
        }
      }
    }
    &.play section.related{
      >ul{
        li{.mb(18);
          .sns-wrap { [sns-share]{.wh(20); .m(12);}}
        }
      }
    }
  }
}

@media (min-width: @screen-tl-min) {
  [news-article]{ .pb(200);
    .hero{.p(164,0,72);
      h2{.fs(36,1.5,-0.25); .w(782); .pb(38);}
      .text-area{.pc-mw;}
      .tag{.pb(22);}
      .desc{
        p{.fs(18,1.89,-0.25);}
      }
    }
    &.common .hero{
      .tag{.pb(24);}
    }
    &.full .hero,
    &.full-video .hero{.mb(72);
      .text-area{.pc-mw;}
      button{.mb(48);}
    }
    .h0 h3{.fs(64,1.1,-0.25); .p(24,0); .pc-mw;}
    h3{.fs(34,1.5,-0.25); .p(24,0); .pc-mw;}
    h4{.fs(26,1.5,-0.25); .p(24,0); .pc-mw;}
    h5{.fs(20,1.6,-0.25); .p(24,0,12); .pc-mw;}
    .prepend-desc{.pt(24);
      p{.pb(10); .pc-mw;}
      h3{.pt(0);}
    }
    .body-text{ .p(24,0); .pc-mw;
      p{.fs(18,1.89,-0.25); .min-h(18);}
    }
    .subtitle-text{.pc-mw;}
    .list-text, .list-text-1, .list-text-2, .list-text-3, .list-text-4{.pc-mw;
      .title{.mt(24);
        i{.wh(24); .lt(0,16);}
        h5{.pl(24); .fs(18,1.89,-0.25);}
      }
      .body-text{.m(0);}
    }
    .small-text{.pc-mw; .p(24,0,12);}
    .short-quote,.long-quote,.en-quote{.p(84,0); .pc-mw;}
    .quote-comma{.wh(30);
      &:first-of-type{.mb(60);}
      &:last-of-type{.mt(60);}
    }
    .short-quote{
      p.main{.fs(28,1.64,-0.25); }
      .sub{.fs(16,1.63,-0.25); .pt(36);}
    }
    .long-quote{
      p.main{.fs(24,1.67,-0.25);}
      .sub{.fs(16,1.63,-0.25); .pt(36);}
      .quote-comma{
        &:first-of-type{.mb(48);}
        &:last-of-type{.mt(48);}
      }
    }
    .en-quote{
      p.main{.fs(26,1.54,-0.25);}
      .sub{.pt(16);}
      .quote-comma{
        &:first-of-type{.mb(36);}
        &:last-of-type{.mt(36);}
      }
    }
    .profile{.pc-mw;
      .name{.fs(22,1.45,-0.25); .p(24,0,8);}
      .sum{.fs(18,1.89,-0.25); .pb(24);}
      .body-text{ .p(0,0,48);}
      hr{.mb(24);}
    }
    .footnote{.pc-mw;
      li{.fs(14,1.57,-0.25);}
    }
    .dot-list{ .pc-mw;.p(24,0);
      p { .fs(18,1.89,-0.25);}
    }
    .indent-dot-list{.pc-mw; .pb(24);
      p { .fs(18,1.89,-0.25);}
    }
    .number-list{.pc-mw; .p(24,0);
      p {.fs(18,1.89,-0.25);}
    }
    .basic-btn,.desc-btn,.title-basic-btn,.title-desc-btn {.w(560);
      .title{.fs(20,1.6,-0.25); .p(60,0,24);}
      a,.none-btn{.fs(16,1.5,-0.25); .m(40,0); .pr(80);}
      .desc{.pb(40);}
      &.desc-btn, &.title-desc-btn{
        a,.none-btn{.mb(12);}
      }
    }
    .basic-image, .basic-image-left, .basic-image-right, .full-image {.p(72,0);
      .desc{.pc-mw;}
    }
    .basic-image, .close-image, .zero-image,
    .basic-image-left, .close-image-left, .zero-image-left,
    .basic-image-right, .close-image-right, .zero-image-right {.w(782);}
    .banner{.pc-mw;}
    .double-image{.p(72,0); .w(782);}
    .basic-carousel{ .pc-mw; .p(72,0);
      .swiper-container{.w(441);
        .slide-item{ .mr(24);
          .image-area{.h(278); .wf;}
          .desc{.pt(24);}
        }
      }
      .swiper-navigation{
        .swiper-button-prev {.lt(-70,50%);}
        .swiper-button-next{.rt(-70,50%);}
      }
    }
    .dot-carousel{.w(782);.p(72,0);
      .swiper-container{.w(782);
        .slide-item {
          .image-area { .h(519);}
          .desc{.pc-mw; .pt(24);}
        }
      }
      .swiper-navigation{.block;}
      .swiper-pagination{.mt(24);}
    }
    .youtube{.p(72,0); .w(782);
      .desc{.pt(24);}
    }
    .single-video, .single-video-loop {.w(782); .p(72,0);
      .desc{.pt(24);}
    }
    .big-video, .big-video-loop {
      .desc{.pt(24);}
    }
    .line{.pc-mw; .mt(24); .mb(24);}
    .reference{.pc-mw; .p(84,0);
      .title{.fs(16,1.63,-0.25);}
      .text{.pt(24);
        p{.pb(24);}
      }
    }
    .author,.author-title{ .pc-mw; .p(24,0);
      .text-area{
        .name{ .fs(16,1.5,-0.25); .medium;}
        .text{.pt(8);}
      }
    }
    .author{
      .profile-img{.vat;}
    }
    .audio{.pc-mw; .p(24,0);
      .desc{.pt(24);}
    }


    section{
      &.tags{.pt(96); .pc-mw;}
      &.related{.pt(165);
        >ul{ grid-template-columns: 1fr 1fr 1fr; column-gap: 14px;
          li{.mb(0);}
        }
      }
    }
    &.play section.related{
      >ul{
        li{.mb(0);
          .text{.p(28,22);}
          .sns-wrap { [sns-share]{.m(12,14);}}
        }
      }
    }
  }
}

@media (min-width: @screen-dm-min) {
  [news-article] { .pb(280);
    .hero{.p(160,0,72);
      h2{.fs(48,1.5,-0.25);  .pb(36);}
      .tag{.pb(24);}
      .desc{
        p{.fs(18,1.89,-0.25);}
      }
    }
    &.magazine .hero,
    &.webtoon .hero{
      .text-area{.w(1100);}
    }
    &.full .hero,
    &.full-video .hero{
      h2{.pb(36);}
      .text-area{ .w(1100);}
    }
    .h0 h3{.fs(80,1.1,-0.25);}
    h3{.fs(40,1.5,-0.25);}
    h4{.fs(28,1.57,-0.25);}
    .prepend-desc{
      p{.pb(6); }
    }
    .basic-image, .close-image, .zero-image,
    .basic-image-left, .close-image-left, .zero-image-left,
    .basic-image-right, .close-image-right, .zero-image-right {.w(1100);}
    .double-image{.w(1100);}
    .short-quote{
      p.main{.fs(32,1.63,-0.25); }
    }
    .long-quote{
      p.main{.fs(26,1.65,-0.25);}
    }
    .basic-carousel{
      .swiper-container{.w(538);
        .slide-item{
          .image-area{.h(340);}
        }
      }
    }
    .dot-carousel{.w(1100);
      .swiper-container{.w(1100);
        .slide-item {
          .image-area { .h(730);}
        }
      }
    }
    .youtube{.w(1100);}
    .single-video, .single-video-loop {.w(1100);}


    section{
      &.tags{.pt(120); .pc-mw;}
      &.related{.pt(180); .pc-m;
        .name{.fs(26); .pb(32);}
        >ul{column-gap: 24px;}
      }
    }
    &.play section.related{
      >ul{
        li{.mb(0);
          .text{.p(36,30);}
          .sns-wrap {.h(110);
            [sns-share]{.wh(24); .m(16);}
          }
        }
      }
    }
  }
}
@media (min-width: @screen-dl-min) {
  [news-article] {
    &.common .hero{.pb(48);}
    section{
      &.related{.pt(220); .pcl-m;
        .name{.fs(36); .pb(48);}
        >ul{column-gap: 33px;
          li{.mb(84);
            .img-wrap{.mb(26);}
            .tag{.fs(16);  .pb(13);
              span{.ml(16);}
            }
            .title{.fs(24,1.58,-0.25);}
          }
        }
      }
    }
    &.play section.related{
      >ul{
        li{
          .text{.p(48,40);}
          .sns-wrap {[sns-share]{.wh(32); .m(19,21);}}
        }
      }
    }
  }
}
</style>
