<template>
  <div module-item :class="info.type" v-html="info.value"></div>
</template>

<script>

export default {
  name: 'DotList',
  props: ['value'],
  computed: {
    info() {
      return this.value;
    },
  },
}
</script>

