<template>
  <div class="frame-wrap">
    <iframe :src="host" frameborder="0" width="100%" height="100%"></iframe>
    <PlayNovelPopup v-if="slug" :slug="slug" @close="close"/>
  </div>
</template>

<script>
import PlayNovelPopup from '@/views/components/popup/PlayNovelPopup';
export default {
  name: 'play-novel',
  components: { PlayNovelPopup },
  data() {
    return {
      slug: '',
    };
  },
  metaInfo() {
    return {
      title: 'PLAY NOVEL',
    }
  },
  computed: {
    host() {
      return `${process.env.VUE_APP_HOST}/play-novel-inc${this.lang !== 'ko' ? '/en/' : ''}`;
    },
  },
  methods: {
    receiveMessage(e) {
      if (this.isEmpty(e.data.slug) || e.data.slug in this.values('undefined','comingSoon')) return;
      this.slug = e.data.slug;
    },
    close() {
      this.slug = '';
    },
  },
  mounted() {
    this.$store.commit('theme', 'viewer');
    if (typeof window !== 'undefined') window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') window.removeEventListener('message', this.receiveMessage)
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";

.frame-wrap { .abs; .lt; .wf; .fvh; .fs(0); }
</style>>