<template>
  <div search>
    <div class="head">
      <div class="inner">
        <a class="search" @click="searchAct"></a>
        <input v-model="searchData" placeholder="Search…" @keydown.prevent.enter="searchAct">
        <a class="close" @click="close"></a>
      </div>
    </div>

    <section class="no-list" v-if="isEmpty(newsList) && isEmpty(playList)">
      <p>검색 결과가 없습니다.</p>
    </section>
    <section class="result" v-else>
      <div class="tab">
        <ul>
          <li :class="{ on: tab === 'play' }" @click="activate('play')"  v-if="lang in values('ko','en','jp')"><p>PLAY</p><span>{{ playList && playList.length }}</span></li>
          <li :class="{ on: tab === 'news' }" @click="activate('news')"><p>NEWS</p><span>{{ newsList && newsList.length }}</span></li>
        </ul>
      </div>
      <div class="contents">
        <ul class="play-result" v-if="tab === 'play'">
          <li :class="{'has-video': item.video}" v-for="(item, i) in playList" :key="i" @mouseenter="over" @mouseleave="out">
            <a @click="routeViewer(item)">
              <img class="load-img" src="/img/common/play-loading-img-h.png" alt="loading image"/>
              <cdn-video :src="item.video" muted playsinline class="over-video" v-if="item.video && !touch"/>
              <cdn-img :src="item.image" />
              <div class="text">
                <p class="tag">{{ formDate(item.publishDtt) }} <span>{{ codeLabel(item.priorityCategory) }}</span></p>
                <p class="title">{{ item.title }}</p>
              </div>
            </a>
            <div class="sns-wrap">
              <SnsShare type="play-main" :value="item" :share-path="getSlugUrl(item)"></SnsShare>
            </div>
          </li>
        </ul>
        <ul class="news-result" v-if="tab === 'news'">
          <li v-for="(item, i) in newsList" :key="i">
            <a @click="linkTo(item.slug, '/news/article/')">
              <div class="img-wrap">
                <div class="load-news"></div>
                <cdn-img :src="item.image" />
              </div>
              <div class="text">
                <p class="tag">{{ formDate(item.publishDtt) }} <span>{{ codeLabel(item.priorityCategory) }}</span></p>
                <p class="title">{{ item.title }}</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import _ from 'lodash';
import SnsShare from "@/views/components/SnsShare";
import oax from '@/utils/oax';
import CdnImg from '@/views/components/CdnImg';
import CdnVideo from '@/views/components/CdnVideo';

function initialState() {
  return {
    searchData: '',
    quickList: null,
    newsList: null,
    playList: null,
    tab: '',
  };
}
export default {
  name: 'search',
  components: { CdnVideo, CdnImg, SnsShare},
  data() {
    return initialState();
  },
  watch: {
    async $route(to, from) {
      if (to.params.locale !== from.params.locale) {
        await this.getQuickLink();
      } else {
        await this.getList();
      }
    },
  },
  methods: {
    over(e) {
      if (this.touch) return;
      const video = e.target.querySelector('.over-video');
      if (this.isEmpty(video)) return;
      if (!this.videoIsPlaying(video)) video.play();
    },
    out(e) {
      if (this.touch) return;
      const video = e.target.querySelector('.over-video');
      if (this.isEmpty(video)) return;
      if (this.videoIsPlaying(video)) video.pause();
      video.currentTime = 0;
    },
    routeViewer(item) {
      this.$store.commit('resetPage', { code: item.priorityCategory, url: this.$route.path });
      this.localeLink(this.getSlugUrl(item));
    },
    activate(e) {
      if (this.isEmpty(this[`${e}List`])) return;
      this.tab = e;
    },
    searchAct(e) {
      if (e?.isComposing) return;
      if (this.isEmpty(this.searchData)) return;
      this.$router.push({ query: { searchData: this.searchData } });
    },
    search(tag) {
      this.searchData = tag;
      this.getList();
    },
    close() {
      // this.searchData = '';
      // this.newsList = this.playList = null;
      if (this.$store.state.resetPage?.url) {
        this.localeLink(this.$store.state.resetPage.url);
      } else this.localeLink('/');
    },
    async getList() {
      this.newsList = (await oax.get('/api/article/news', { lang: this.lang, search: encodeURIComponent(this.searchData)})).data.list;
      this.playList = (await oax.get('/api/article/play', { lang: this.lang, search: encodeURIComponent(this.searchData)})).data.list;
      this.arrangeData();
      if (!this.tab) this.tab = this.playList.length ? 'play' : 'news';
    },
    arrangeData() {
      this.playList.forEach(item => {
        item.video = this.matchMediaType(JSON.parse(item.contentEncode === 'base64' ? decodeURIComponent(atob(item.contents)) : item.contents)?.content[0], 'video');
      });
    },
    async getQuickLink() {
      const { data } = await oax.get(`/api/quickLink`, { lang: this.lang });
      const list = _.uniqBy(_.map(data, row => _.pickBy(row, _.identity)), 'priorityCategory').slice(0, 4);
      this.quickList = this.fullCodeList.filter(row => list.some(e => e.priorityCategory === row.code));
    },
  },
  mounted() {
    console.log('tab : ' +  this.tab);
    this.$store.commit('theme', 'search');
    this.getQuickLink();
    this.searchData = this.$route.query.searchData;
    if (this.searchData) this.getList();
  },
  beforeDestroy() {
    this.$store.commit('resetPage', null);
  },
  async asyncData({ route }) {
    if (!route.query.searchData) return;
    const lang = route.params.locale || 'ko';

    const newsList = (await oax.get('/api/article/news', { lang, search: encodeURIComponent(route.query.searchData)})).data.list;
    const playList = (await oax.get('/api/article/play', { lang, search: encodeURIComponent(route.query.searchData)})).data.list;

    return { newsList, playList };
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[search] { background: #fff;
  .head{.p(27,18,28); .wf; .-box; .-b(#f4f4f4); .sticky; .lt(0); .bgc(#fff); z-index:2;
    .inner{.proj-max-w;}
    .search{.ib; .wh(24); .vam; .bgc; .contain; .no-repeat; .bg('/img/common/search-ic-search.png');}
    .close{.ib; .fr; .wh(24); .ib; .contain; .no-repeat; .bg('/img/common/search-ic-close.png'); .bgc; .vam; .o(1); transform:rotate(0); transition:opacity .2s ease, transform .3s ease;
      .no-touch &:hover{.o(0.6); transform:rotate(180deg);}
    }
    input{.ml(16); .fs(22,1.32); .bold; .w(75%);
      &::placeholder{.c(#a9a9a9);}
    }
  }
  section{.proj-max-w;}
  .init{.m-m; .pt(32);.rel;
    label{.fs(14,1.57); .c(#a9a9a9);}
    ul{.pt(10);
      li {
        a{.p(2,0); .fs(16,1.81,-0.3); .c(#333);
          .no-touch &:hover{.c(#888);}
        }
      }
    }
  }
  &.open{
    .init{
      ul{
        li {.o(1); .t-y(0);}
      }
    }
  }
  .no-list {.tc; .pt(108); .fs(16); .medium;}
  .result {.m-m; .pt(48); .rel; .bgc(#fff); min-height:calc(100vh - 85px);
    .tab {.mb(32);
      ul {
        li {.medium; .ib; .mr(16); cursor: pointer; .c(#a9a9a9); transition: color .4s ease;
          p {.fs(14);.rel; .ib; .vat;
            &:after {content: ''; .w(0); .h(1.4); .abs; .lb(50%, -2); .t-xc; .bgc(#1e1e1e); transition: width .6s cubic-bezier(0.4, -0.03, 0, 1.03);}
          }
          span {.ib; .vat; .fs(9); .ml(4);}
          &.on {.c(#1e1e1e);
            p:after {.wf;}
          }
        }
      }
    }
    .contents {
      .play-result {display: grid; grid-template-columns: 1fr;
        >li {.rel; overflow: hidden; aspect-ratio: 9 / 15; .mb(20); .c(#fff);
          [cdn-img] {.f; object-fit: cover; transform: scale(1); .o(1); transition: transform 1s cubic-bezier(0, 0, 0.13, 1.04), opacity .6s ease;}
          .over-video { .abs; .lt; .f; transform: scale(1); transition: transform 1s cubic-bezier(0, 0, 0.13, 1.04);}
          .text {.abs; .wf; .-box; .lb(0, 0); .p(36, 24); background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
            .tag {.fs(12); .pb(12);
              span {.medium; .ib; .ml(8);}
            }
            .title {.fs(18, 1.61, -0.25); .bold;}
          }
          .sns-wrap {.abs; .lt(0, 0); .wf; .h(70); background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
            [sns-share] {.wh(24); .m(12, 14); .abs; .rt(0, 0);
              a.btn{.bg('/img/common/ic-share-more.png'); .no-repeat; .contain;}
            }
          }
          .no-touch &:hover{
            [cdn-img], video{transform:scale(1.1);}
            &.has-video [cdn-img] { .o(0); }
          }
        }
      }
      .news-result {display: grid; grid-template-columns: 1fr;
        li {.rel; .mb(48);
          a {.block;}
          .img-wrap {.mb(20); aspect-ratio: 17/9;overflow: hidden; .rel;
            img {.rel; .f; object-fit: cover; transform: scale(1); transition: transform 1s cubic-bezier(0, 0, 0.13, 1.04);}
          }
          .text {.c(#333);
            .tag {.fs(12); .pb(10);
              span {.medium; .ib; .ml(12);}
            }
            .title {.fs(18, 1.61, -0.25); .bold;}
          }
          .no-touch &:hover {
            .img-wrap img {transform: scale(1.1);}
          }
        }
      }
    }
  }
  .dark &{ background: #000;
    .head{.bgc(#000); .-b(#222);
      .search{.bg('/img/common/search-ic-search-dark.png');}
      .close{.bg('/img/common/search-ic-close-dark.png'); }
      input{.c(#fff);
        &::placeholder{.c(#888);}
      }
    }
    .init{
      label{.c(#888);}
      ul li a{ .c(#ebebeb);}
    }
    .result{ .bgc(#000);
      .tab{
        ul li{.c(#666);
          p:after{.bgc(#fff);}
          &.on{.c(#fff);}
        }
      }
    }
    .contents{
      .news-result{
        li .text{.c(#fff);}
      }
    }
  }
}
@media (min-width: @screen-tp-min) {
  [search] {
    .head{.p(27,36,28);
      input{.ml(24);}
    }
    .init{.p(33,36);}
    .result{.t-m; .pt(60);
      .tab{.mb(24);}
      .contents{
        .play-result {grid-template-columns: 1fr 1fr; column-gap: 18px;
          >li {.mb(18);
            .sns-wrap {[sns-share] {.wh(20); .m(12);}}
          }
        }
        .news-result{grid-template-columns: 1fr 1fr; column-gap: 18px;
          li{.mb(56);}
        }
      }
    }
  }
}
@media (min-width: @screen-tl-min) {
  [search] {
    .head{.p(31,36,32);
      .search{.wh(25);}
      .close{ .wh(25);}
      input{.fs(26);}
    }
    .init{.p(32,36);
      ul{.pt(8);
        li a{.p(4,0);}
      }
    }
    .result{.pt(60); min-height:calc(100vh - 99px);
      .tab{.mb(24);}
      .contents{
        .play-result {grid-template-columns: 1fr 1fr 1fr; column-gap: 14px;
          >li {.mb(14);
            .text{.p(28,22);}
            .sns-wrap {[sns-share]{.m(12,14);}}
          }
        }
        .news-result{grid-template-columns: 1fr 1fr 1fr; column-gap: 14px;
          li{.mb(72);}
        }
      }
    }
  }
}
@media (min-width: @screen-dm-min) {
  [search] {
    .head{.p(43,48,44);
      .search{.wh(36); }
      .close{.wh(36);}
      input{.ml(36); .fs(36); .w(80%);}
    }
    .no-list {.pt(160); .fs(24);}
    .init{.p(32,48);}
    .result{.pt(100);min-height:calc(100vh - 135px);
      .tab{.mb(32);
        ul{
          li{.mr(24);
            p{.fs(16);}
            span{.fs(11); .ml(5);}
          }
        }
      }
      .contents{
        .play-result {column-gap: 24px;
          >li {.mb(24);
            .text{.p(36,30);
              .tag{
                span{.ml(12);}
              }
              .title{.fs(22,1.59,-0.25);}
            }
            .sns-wrap {.h(110);
              [sns-share]{.wh(24); .m(16);}
            }
          }
        }
        .news-result{column-gap:24px;
          li{.mb(84);}
        }
      }
    }
  }
}
@media (min-width: @screen-dl-min) {
  [search] {
    .head{.p(58,60);
      .search{.wh(44);}
      .close{.wh(44);}
      input{.ml(48); .fs(48);}
    }
    .init{.p(44,60);
      label{.fs(18);}
      ul{.pt(12);
        li a{.p(8,0); .fs(22);}
      }
    }
    .result{.pt(120);min-height:calc(100vh - 180px);
      .tab{.mb(49);
        ul{
          li{.mr(32);
            p{.fs(22);}
            span{.fs(15);}
          }
        }
      }
      .contents{
        .play-result {column-gap: 33px;
          >li {.mb(33);
            .text{.p(48,40);
              .tag{.fs(16); .pb(16);
                span{.ml(16);}
              }
              .title{.fs(28,1.57,-0.25);}
            }
            .sns-wrap {[sns-share]{.wh(32); .m(19,21);}}
          }
        }
        .news-result{column-gap:24px;
          li {.mb(108);
            .img-wrap {.mb(26);}
            .text {
              .tag {.fs(16); .pb(13);
                span {.ml(16);}
              }
              .title {.fs(24, 1.58, -0.25);}
            }
          }
        }
      }
    }
  }
}
</style>