<template>
  <div module-item :class="info.type">
    <p class="title">References</p>
    <div class="text" v-html="info.value"></div>
  </div>
</template>

<script>

export default {
  name: 'Reference',
  props: ['value'],
  computed: {
    info() {
      return this.value;
    },
  },
}
</script>

