<template>
  <div>
    <h1>NC PLAY 운영 정책</h1>
    <p>
      <s>NC PLAY 운영 정책</s><br>
      시행 일자: 2022. 08. 12<br>
      <br>
      <br>
      <s>1. NC PLAY 운영 목표</s><br>
      주식회사 엔씨소프트(이하 “NC”)는 NC PLAY(https://about.ncsoft.com)를 통해 기업을 둘러싼 다양한 이야기를 생생하게 담아내고자 합니다. NC의 기업 철학과 역량을 담은 콘텐츠를 디지털에 최적화된 형태로 제공하여 독자들에게 새로운 브랜드 경험을 선사할 것입니다.<br>
      NC PLAY 운영진은 독자들에게 즐거움과 유익함을 제공하는 양질의 콘텐츠를 제작하기 위해 노력하겠습니다.<br>
      <br>
      <br>
      <s>2. NC PLAY 사용</s><br>
      ① NC PLAY에서 제공하는 모든 정보는 NC 또는 제3자의 소중한 자산입니다. 방문자는 NC PLAY를 자유롭게 방문하고 구독할 수 있으나, 그 외의 방법으로 콘텐츠를 이용하거나 외부에 공유하려면 NC의 “콘텐츠 이용 안내”를 준수하여야 합니다.<br>
      ② NC PLAY의 콘텐츠는 NC PLAY 게재를 목적으로 작성자에게서 제공받은 게시물이나 정보를 포함합니다. NC PLAY의 콘텐츠는 NC의 공식 입장과 다를 수 있으며, NC의 의견을 대표하지 않습니다.<br>
      ③ NC PLAY는 콘텐츠 내용과 관련된 타 사이트의 링크를 포함하고 있습니다. NC는 링크되어 있는 사이트나 NC PLAY에서 제공하는 어떠한 정보, 제언 또는 광고도 보증하지 않습니다.<br>
      <br>
      <br>
      <s>3. 사생활 보호 </s><br>
      ① NC PLAY 방문자는 개인정보를 기입하지 않고도 NC PLAY에 게재된 정보, 뉴스 및 포스팅을 자유롭게 열람, 구독할 수 있습니다.<br>
      ② 방문자가 NC PLAY를 방문할 때 방문자의 브라우저가 보낸 서버 로그 정보가 자동으로 저장될 수 있습니다. 위 서버 로그는 NC PLAY 방문자의 웹 요청, 방문한 사이트 주소, 브라우저 유형 및 언어, 방문 또는 포스팅 날짜와 시간, 쿠키 등 방문자의 브라우저를 규정하는 정보를 포함하고 있으며, NC는 이를 NC PLAY를 개선하는 데 사용할 수 있습니다.<br>
      ③ NC PLAY는 방문자나 방문자의 컴퓨터 정보를 인터넷 폼인 ‘쿠키’를 통해 수집하고 저장할 수 있습니다. 캐릭터로 이루어진 소규모 파일인 쿠키는 NC PLAY 방문자가 처음 방문할 때 브라우저를 규정해 방문자의 컴퓨터에 저장됩니다. 쿠키는 방문자가 NC PLAY를 보다 유용하게 사용할 수 있도록 합니다. 대부분의 브라우저는 쿠키를 적용하도록 설정되어 있지만 방문자는 직접 브라우저를 재설정하여 모든 쿠키 적용을 거부하거나 쿠키 전송 시 명시하도록 정할 수 있습니다. 하지만 쿠키가 작동하지 않거나 삭제되었을 경우 NC PLAY 서비스가 제대로 작동하지 않을 수 있습니다.<br><br>

      (1) 쿠키 등 사용 목적<br>
      방문자의 접속 빈도나 방문 시간 등에 대한 분석, 방문자의 취향과 관심 분야에 대한 파악 및 자취 추적, 방문 회수 파악 등을 통해 개인 맞춤 서비스를 제공합니다. 방문자의 웹/모바일 서비스 방문 기록 및 활동 로그는 인구통계학적 특성에 기반한 추정 성별/연령대와 관심사에 따른 맞춤형 광고를 위해 인크로스㈜와 뉴타입이미지웍스에 제공할 수 있습니다.<br><br>

      (2) 쿠키 설정 거부 방법<br>
      방문자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 방문자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인하거나, 혹은 모든 쿠키의 저장을 거부할 수 있습니다. 다만 쿠키 저장을 거부할 경우 일부 서비스 이용이 어려울 수 있습니다.<br><br>

      [쿠키 설정 방법]<br>
      ① Internet Explorer: 웹브라우저 상단의 [도구] → [인터넷 옵션] → [개인정보] → [고급]<br>
      ② Chrome: 웹브라우저 오른쪽 상단의 [⋮] → [설정] → [개인정보 및 보안] → [쿠키 및 기타 사이트 데이터]
      <br>
      <br>
      <s>4. 운영 정책의 변경</s><br>
      본 NC PLAY 운영 정책은 변경될 수 있습니다. NC는 본 NC PLAY 운영 정책 변경 시 이를 공지를 통해 공유할 예정입니다. 변경된 NC PLAY 운영 정책의 내용은 유효한 날짜별로 페이지 상단에 게재됩니다.<br>
      <br>
      <br>
      <br>
      <s>콘텐츠 이용 안내</s><br>
      시행 일자: 2022. 08. 12<br>
      <br>
      <br>
      NC PLAY가 직접 제작한 모든 콘텐츠는 원본을 편집, 변형, 가공하지 않는 선에서 언론 보도 및 학술 목적으로 활용할 수 있습니다. 콘텐츠 사용자는 해당 소스의 출처를 반드시 표기해주시기 바랍니다.<br><br>

      다만 외부로부터 제공받은 일부 콘텐츠는 사용 및 공유에 제한이 있습니다. 영상/이미지/글 등 콘텐츠 하단에 작성자 이름이 별도로 표시된 경우, NC PLAY 게재를 목적으로 작성자에게서 제공받은 콘텐츠입니다. 따라서 공유 혹은 사용 시에는 별도로 작성에게 사용 허가를 받으셔야 합니다.<br><br>

      NC PLAY의 콘텐츠를 활용한 2차 저작을 기획하거나 활용과 관련하여 추가 문의가 있는 경우 NC PLAY 운영팀으로 문의 바랍니다.<br><br>

      NC PLAY 운영팀: ncsoft_brandcommunicationteamd@ncsoft.com
    </p>

  </div>
</template>

<script>

export default {
  name: 'PolicyKR',
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[policy]{.p(140,0); .m-m;
  h1{.pb(72); .fs(28,1.5,-0.25); .bold; .tc; .c(#1e1e1e);}
  p{.fs(16,1.94,-0.25); .c(#555);
    s{.bold; .c(#333);}
  }
}
@media (min-width: @screen-tp-min) {
  [policy]{.p(132,0,180);
    h1{.pb(84);}
    p{.w(480); .mh-c;}
  }
}
@media (min-width: @screen-tl-min) {
  [policy]{.p(144,0,200);
    h1{.pb(96); .fs(36,1.5,-0.25);}
    p{.w(650); .fs(18,1.89,-0.25);}
  }
}
@media (min-width: @screen-dm-min) {
  [policy]{.p(166,0,280);
    h1{.pb(120); .fs(44,1.5,-0.25);}
  }
}
@media (min-width: @screen-dl-min) {
  [policy]{.p(204,0,300);
    h1{.pb(132); .fs(48,1.5,-0.25);}
  }
}
</style>