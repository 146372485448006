<template>
  <div module-item :class="info.type">
    <cdn-img :src="info.value.image.pc" filekey="news" class="hidden-ml-down"/>
    <cdn-img :src="info.value.image.mobile" filekey="news" class="hidden-tp-up"/>
    <div class="desc" v-if="info.value.desc" v-html="enterToBr(info.value.desc)"></div>
  </div>
</template>

<script>
import CdnImg from '@/views/components/CdnImg';
export default {
  name: 'SingleImage',
  components: { CdnImg },
  props: ['value'],
  computed: {
    info() {
      return this.value;
    },
  },
};
</script>

