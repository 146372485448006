export default {
  methods: {
    url(path) {
      if (typeof document === 'undefined') return;
      return document.location.origin + ( path ? `${this.$i18n('path')}${path}` : document.location.pathname );
    },
    shareFacebook(path) {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${ encodeURIComponent(this.url(path)) }`, 'share', 'width=800,height=800');
    },
    shareTwitter(path) {
      window.open(`https://twitter.com/share?url=${ encodeURIComponent(this.url(path)) }`, 'share', 'width=800,height=800');
    },
    shareKakao(path, data) {
      const title = data.title;
      const imageUrl = `${this.cdnHost}/${this.mediaVal(data.image).location}`;
      window.Kakao.Link.sendDefault({
        objectType:'feed',
        content:{
          title, imageUrl,
          link:{
            mobileWebUrl: this.url(path),
            webUrl: this.url(path)
          }
        },
        buttonTitle:'링크를 확인하세요',
      });

    },
    shareLinkedin(path) {
      window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${ encodeURIComponent(this.url(path)) }`, 'share', 'width=800,height=800');
    },
    shareReddit(path) {
      window.open(`http://www.reddit.com/submit?url=${ encodeURIComponent(this.url(path)) }`, 'share', 'width=800,height=800');
    },
  }
}
