<template>
  <div>
    <h1>NC PLAY Operation Policy</h1>
    <p>
      <s>NC PLAY Operation Policy</s><br>
      Effective on August 12, 2022<br>
      <br>
      <br>
      <s>1. Purpose of NC Play</s><br>
      NCSOFT (“NC”) aims to deliver diverse stories surrounding it through NC PLAY (https://about.ncsoft.com). We will deliver new brand experience to the audience through providing content reflecting NC’s corporate philosophy and capability in forms that are optimized for digital environment.<br>
      Team NC PLAY will strive to produce quality content to provide much joy and benefit to the audience.
      <br>
      <br>
      <br>
      <s>2. Use of NC PLAY</s><br>
      ① All information provided through NC PLAY is valuable assets of NC or third parties. Visitors may freely visit and subscribe to NC PLAY, while they should comply with NC’s “Guide to Content Use” in case they use the content for other purposes or share it with external parties.<br>
      ② The content of NC PLAY contains posts and information provided by their creators for posting on NC PLAY. The content of NC PLAY may be irrelevant to the official opinion of NC and does not represent its opinion.<br>
      ③ NC PLAY includes links to other websites related to the content. NC does not provide guarantee with regards to any linked website or any information, suggestion, or advertisement provided by NC PLAY.<br>
      <br>
      <br>
      <s>3. Privacy</s><br>
      ① Visitors of NC PLAY may freely access and subscribe to the information, news, and posts posted on NC PLAY without registering their personal information.<br>
      ② In case a visitor visits NC PLAY, the server logs sent by their browser may be automatically saved. The server logs include information to identify the user’s browser, including web request, address of visited website, browser type and language, time of visit or post, and cookie and NC may use them to improve NC PLAY.<br>
      ③ NC PLAY may collect and save information about the visitor or their computer using ‘cookies’. Cookies are small-sized files composed of characters; cookies will identify NC PLAY visitor’s browser upon their first visit and will be saved in the computer. Cookies help visitors to better use NC PLAY. While most browsers would allow cookies, visitors may refuse all cookies or have the browser request their approval for sending of cookies through changing their browser settings. However, NC PLAY service may not work properly in case cookies are not allowed or deleted.<br><br>
      (1) Purpose of cookie use<br>
      NC PLAY provides customized services through understanding the user’s preference and interest, following their traces, and understanding their number of visits based on frequency and time of their visits. The logs of user’s web and mobile service visits and activities may be provided to Incross Co., Ltd. and Newtype Imageworks for advertisement customized based on the visitor’s gender, age, and interests estimated through demographics.<br><br>
      (2) How to reject cookies<br>
      All users have the right to choose whether to accept cookies or not. Therefore, users may allow all cookies, request their confirmation upon saving of each cookie, or reject all cookies through changing settings of their web browser. However, in case you reject cookies, there may be difficulties in getting some services.<br><br>
      [How to allow cookies]<br>
      ① Internet Explorer: [Tools] (On the top of the web browser) → [Internet Options] → [Privacy] → [Advanced]<br>
      ② Chrome: [⋮] (On the top right corner of the web browser) → [Setting] → [Privacy and Security] → [Cookies and Other Site Data]<br>
      <br>
      <br>
      <s>4. Change of operation policy</s><br>
      This operation policy is subject to change. In case there is such a change, NC will share the news through notification. Changes in the operation policy will be displayed at the top of the page in a chronical order.<br>
      <br>
      <br>
      <br>
      <s>Guide to Content Use</s><br>
      Effective on August 12, 2022<br>
      <br>
      <br>
      All content directly produced by NC PLAY may be used for media and academic purposes unless the original version is edited, transformed, or processed. Users are strongly advised to state the source of content.<br><br>

      However, use and sharing of some content provided by external parties are limited. In case the creator’s name is separately stated at the bottom of content, including video, image, and text, it means that the content was provided by the creator for posting on NC PLAY. Therefore, users should obtain a separate approval from the creator for sharing or use.<br><br>

      Please contact NC PLAY operation team in case you wish to produce a secondary work using NC PLAY’s content or have questions about their use.<br><br>

      NC PLAY operation team: ncsoft_brandcommunicationteamd@ncsoft.com
    </p>
  </div>
</template>

<script>

export default {
  name: 'PolicyEN',
};
</script>



<style lang="less">
@import "~@/less/proj.less";
[policy]{.p(140,0); .m-m;
  h1{.pb(72); .fs(28,1.5,-0.25); .bold; .tc; .c(#1e1e1e);}
  p{.fs(16,1.94,-0.25); .c(#555);
    s{.bold; .c(#333);}
  }
}
@media (min-width: @screen-tp-min) {
  [policy]{.p(132,0,180);
    h1{.pb(84);}
    p{.w(480); .mh-c;}
  }
}
@media (min-width: @screen-tl-min) {
  [policy]{.p(144,0,200);
    h1{.pb(96); .fs(36,1.5,-0.25);}
    p{.w(650); .fs(18,1.89,-0.25);}
  }
}
@media (min-width: @screen-dm-min) {
  [policy]{.p(166,0,280);
    h1{.pb(120); .fs(44,1.5,-0.25);}
  }
}
@media (min-width: @screen-dl-min) {
  [policy]{.p(204,0,300);
    h1{.pb(132); .fs(48,1.5,-0.25);}
  }
}
</style>
