<template>
  <div module-item :class="info.type">
    <div class="video-wrap">
      <iframe class="youtube-video" :src="`https://www.youtube.com/embed/${info.value.linkId}`" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="desc" v-html="enterToBr(info.value.desc)" v-if="info.value.desc"></div>
  </div>
</template>

<script>

export default {
  name: 'LinkUpload',
  props: ['value'],
  computed: {
    info() {
      return this.value;
    },
  },
};
</script>

