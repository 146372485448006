<template>
  <div module-item :class="info.type">
    <a :href="info.value.image.url" target="_blank">
      <cdn-img :src="info.value.image.pc" filekey="news" class="hidden-ml-down"/>
      <cdn-img :src="info.value.image.mobile" filekey="news" class="hidden-tp-up"/>
    </a>
  </div>
</template>

<script>
import CdnImg from '@/views/components/CdnImg';
export default {
  name: 'Banner',
  components: { CdnImg },
  props: ['value'],
  computed: {
    info() {
      return this.value;
    },
  },
};
</script>

