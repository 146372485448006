<template>
  <div module-item :class="info.type">
    <ul>
      <li v-for="(row, i) in info.value" :key="i">
        <span v-html="enterToBr(row.sup)"></span>
        <span v-html="enterToBr(row.text)"></span>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'Footnote',
  props: ['value'],
  computed: {
    info() {
      return this.value;
    },
  },
};
</script>

