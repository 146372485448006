<template>
  <div play-shorts>
    <div class="content">
      <cdn-img :src="src" v-if="matchMediaType(src, 'image')"/>
      <template v-if="matchMediaType(src, 'video')">
        <div class="default"></div>
        <cdn-video :src="src" autoplay :muted="soundMuted" playsinline @timeupdate="updated" @ended="ended"/>
        <div class="video-controls">
          <a class="screen-btn hidden-tp-up" @click="clickScreen" :class="{open: screenOpen}"></a>
          <a class="play-btn" @click="togglePlay" :class="{pause: isPaused}"></a>
          <a class="sound-btn" @click="toggleSound" :class="{mute: soundMuted}"></a>
        </div>
      </template>
      <div class="remote">
        <a @click="active(activated-1)" class="in-prev"></a>
        <a @click="active(activated+1)" class="in-next"></a>
      </div>
      <div class="controls">
        <a class="bar" @click="active(i-1)" v-for="i in content.length" :key="i">
          <div class="progress-bar"></div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import CdnVideo from '@/views/components/CdnVideo';
import CdnImg from '@/views/components/CdnImg';
export default {
  name: 'PlayShorts',
  components: { CdnImg, CdnVideo },
  data() {
    return {
      activated: 0,
      playTime: 0,
      intervalID: 0,
      imageDuration: 5000,
      video:null,
      screenOpen:false,
      isPaused:false,
    };
  },
  props: {
    content: { type: Array, default: null },
    max: { type: Number, default: 0 },
  },
  watch: {
    activated() {
      this.reset();
    },
  },
  computed: {
    src() {
      return this.content[this.activated];
    },
    soundMuted() {
      return this.$store.state.soundMuted;
    },
  },
  methods: {
    active(num) {
      if(num >= this.content.length) {
        this.$emit('prev');
      } else if (num < 0) {
        this.$emit('next');
      } else this.activated = num;
    },
    updateBar(current, duration) {
      const progress = this.$el.querySelectorAll('.bar')[this.activated].querySelector('.progress-bar');
      progress.classList.add('easing');
      progress.style.width = (current * 100 ) / (this.max ? Math.min(duration,this.max) : duration) + '%'
    },
    updated(e) {
      const video = e.target;
      this.video = e.target;

      if (this.max && video.currentTime >= this.max) {
        this.ended();
        return;
      }
      this.updateBar(video.currentTime, video.duration);
    },
    ended() {
      this.active(this.activated+1);
    },
    reset() {
      this.isPaused = false;
      const mediaType = this.getMediaType(this.content[this.activated]);
      clearInterval(this.intervalID);
      if (mediaType === 'image') {
        let time = 0;
        this.intervalID = setInterval(() => {
          if (++time * 100 > this.imageDuration) {
            clearInterval(this.intervalID);
            this.ended();
          }
          this.updateBar(time * 100, this.imageDuration);
        }, 100);
      }
      const progress = this.$el.querySelectorAll('.progress-bar');
      progress.forEach((el, i) => {
        el.style.width = i < this.activated ? '100%' : 0;
        el.classList.remove('easing');
      });
    },
    clickScreen(){
      this.screenOpen = !this.screenOpen;
      this.$emit('full');
    },
    togglePlay(){
      if (this.video.paused) this.video.play();
      else this.video.pause();
      this.isPaused = this.video.paused;
    },
    toggleSound(){
      this.$store.commit('soundMuted', !this.video.muted);
    }
  },
  mounted() {
    this.reset();
    this.video = this.$el.querySelector('video');
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[play-shorts] { .rel; .f;
  .content { .rel; .f; overflow: hidden;
    .default{.abs; .f; .lt(0,0); .bgc(#000);}
    [cdn-video], [cdn-img]{ .block; .f; .rel;}
    .video-controls{.abs; .lt(18,28); z-index:1;
      a{.wh(24); .mr(20); .ib; .contain; .no-repeat; transition:opacity .3s ease;
        .no-touch &:hover{.o(0.6);}
        &.screen-btn{.bg('/img/common/ic-white-full-screen.png');
          &.open{.bg('/img/common/ic-white-full-screen-close.png');}
        }
        &.play-btn{.bg('/img/common/ic-white-pause.png');
          &.pause{.bg('/img/common/ic-white-play.png');}
        }
        &.sound-btn{.bg('/img/common/ic-white-sound.png');
          &.mute{.bg('/img/common/ic-white-mute.png');}
        }
      }
    }
  }
  .remote{.abs; .lt(0,50%); .t-yc; .wf; .h(70%);
    a{.hf; .ib; transition:opacity .3s ease;
      .no-touch &:hover{.o(0.6);}
      &.in-prev{.w(30%);}
      &.in-next{.w(70%);}
    }
  }
  .controls { .abs; .lt; .wf; .p(0,18,86); .-box; .flex-center; background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 23%, rgba(0, 0, 0, 0));
    .bar { .rel; .inline-flex; .align-center; .h(26); .ml(2); .-box; .pointer; flex-grow: 1;
      &:before { .cnt; .wf; .h(2); background-color: rgba(255, 255, 255, 0.3); }
      &:first-of-type { .ml(0); }
      .progress-bar { .abs; .lt(0,50%); .t-yc; .w(0); .h(2); .bgc(#fff); pointer-events: none;
        &.easing { transition: width .3s linear; }
      }
    }
  }
}
@media (min-width: @screen-tp-min) {
  [play-shorts] {
    .content {
      .video-controls{.lt(26,54);
        a{.wh(32); .mr(26);}
      }
    }
    .remote{height:auto; .wf;
      a{ .contain; .no-repeat; .abs;
        &.in-prev{.wh(35); .l(14); .bg('/img/common/btn-inner-prev.png');}
        &.in-next{.wh(35); .r(14); .bg('/img/common/btn-inner-next.png')}
      }
    }
    .controls {.p(0,26,240);
      .bar { .h(54);
        &:before { .cnt; .wf; .h(2); background-color: rgba(255, 255, 255, 0.3); }
      }
    }
    @media (min-width: @screen-tp-min) and (max-height: 768px) {
      .content {
        .video-controls{.lt(20,42);
          a{.wh(25); .mr(22);}
        }
      }
      .remote{
        a{
          &.in-prev{.wh(28); .l(10);}
          &.in-next{.wh(28); .r(10);}
        }
      }
      .controls {.p(0,20,190);
        .bar { .h(42);
          &:before { .h(2); }
        }
      }
    }
  }
}
@media (min-width: @screen-tl-min) {
  [play-shorts] {
    .content {
      .video-controls{.lt(34,71);
        a{.wh(42); .mr(36);}
      }
    }
    .remote{
      a{
        &.in-prev{.wh(47); .l(14);}
        &.in-next{.wh(47); .r(14);}
      }
    }
    .controls {.p(0,34,320);
      .bar { .h(71);
        &:before { .h(3); }
      }
    }
    @media (min-width: @screen-tl-min) and (max-height: 1024px) {
      .content {
        .video-controls{.lt(20,42);
          a{.wh(25); .mr(22);}
        }
      }
      .remote{
        a{
          &.in-prev{.wh(24); .l(10);}
          &.in-next{.wh(24); .r(10);}
        }
      }
      .controls {.p(0,20,190);
        .bar { .h(42);
          &:before { .h(2); }
        }
      }
    }
  }
}
@media (min-width: @screen-dm-min) {
  [play-shorts] {
    .content {
      .video-controls{.lt(20,42);
        a{.wh(25); .mr(22);}
      }
    }
    .remote{
      a{
        &.in-prev{.wh(28); .l(14);}
        &.in-next{.wh(28); .r(14);}
      }
    }
    .controls {.p(0,20,190);
      .bar { .h(42);
        &:before { .h(2); }
      }
    }
  }
}
@media (min-width: @screen-dl-min) {
  [play-shorts] {
    .content {
      .video-controls{.lt(26,55);
        a{.wh(33); .mr(30);}
      }
    }
    .remote{
      a{
        &.in-prev{.wh(36); .l(18);}
        &.in-next{.wh(36); .r(18);}
      }
    }
    .controls {.p(0,26,254);
      .bar { .h(55);
        &:before { .h(3); }
      }
    }
  }
}
</style>