<template>
  <main news-article>
    <h3>sample page</h3>
  </main>
</template>

<script>

export default {
  name: 'news-sample',
  components: {},
  data() {
    return {
      info: null
    };
  },
  mounted() {
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
</style>
