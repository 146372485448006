<template>
  <div module-item :class="info.type">
    <i class="quote-comma"></i>
    <p class="main" v-html="enterToBr(info.value.contents)"></p>
    <div class="sub" v-html="enterToBr(info.value.writer)"></div>
    <i class="quote-comma"></i>
  </div>
</template>

<script>

export default {
  name: 'Quote',
  props: ['value'],
  computed: {
    info() {
      return this.value;
    },
  },
}
</script>

