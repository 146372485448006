<template>
  <div audio-player>
    <audio ref="audio" :src="t0" @timeupdate="updated" @loadedmetadata="set"/>
    <div class="player" :class="isPaused === false ? 'active' : null">
      <a class="play-btn" @click="togglePlay"></a>
      <p class="sec">{{ time }} / {{ duration }}</p>
      <div class="progress-bar" @click="clickProgress">
        <div class="bar-bgc">
          <span :style="{ width: progress+'%'}"></span>
        </div>
      </div>
      <div class="volume" :class="volume">
        <a class="volume-btn" @click="setVolume(0)"><span></span></a>
        <a class="volume-btn" @click="setVolume(1)"><span></span></a>
        <a class="volume-btn" @click="setVolume(2)"><span></span></a>
        <a class="volume-btn" @click="setVolume(3)"><span></span></a>
        <a class="volume-btn" @click="setVolume(4)"><span></span></a>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'AudioPlayer',
  data() {
    return {
      t0:'',
      audio:null,
      intervalId: null,
      isPaused: true,
      time:'00:00',
      progress:0,
      duration:'00:00',
      volume:'volume-4',
    }
  },
  props: {
    src: { type: String, default: null },
  },
  watch: {
    src: {
      handler(v) {
        this.t0 = v || '';
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    play() {
      this.isPaused = false;
      this.audio.play();
    },
    pause() {
      this.isPaused = true;
      this.audio.pause();
    },
    togglePlay() {
      if (this.isPaused) this.play();
      else this.pause();
    },
    updated(){
      this.time = this.setTime(this.audio.currentTime.toFixed());
      this.progress = (100/this.audio.duration) * this.audio.currentTime;
      if(this.audio.duration.toFixed() === this.audio.currentTime.toFixed() && this.audio.currentTime.toFixed() !== 0){
        this.togglePlay();
      }
    },
    setTime(seconds){
      const min = parseInt((seconds % 3600) /60) < 10 ? '0'+ parseInt((seconds % 3600)/ 60) : parseInt((seconds % 3600) /60);
      const sec = seconds % 60 < 10 ? '0'+seconds % 60 : seconds % 60;
      return min+':'+sec
    },
    set(){
      this.duration = this.setTime(this.audio.duration.toFixed());
    },
    clickProgress(e){
      this.progress = (100/e.target.clientWidth) * e.offsetX;
      this.audio.currentTime = (this.audio.duration.toFixed()*this.progress)/100 ;
    },
    setVolume(i){
      this.volume = 'volume-'+ String(i);
      if(i === 0) this.audio.volume = 0;
      else if(i === 1) this.audio.volume = 0.25;
      else if(i === 2) this.audio.volume = 0.5;
      else if(i === 3) this.audio.volume = 0.75;
      else this.audio.volume = 1;
    }
  },
  mounted(){
    this.audio =this.$refs.audio;
  }
}
</script>

<style lang="less">
@import "~@/less/proj.less";

[audio-player] {z-index:99; .rel;
  .player{ .bgc(#000); .p(5,24); .br(4);.fs(0,0);
    .play-btn{.o(1); .wh(20); .bgc(transparent); .mr(0); .ib; .bg('/img/common/ic-white-play.png'); .contain; .vam;}
    .sec{.ib; .w(98); .-box; color:#fff; .vam; .m(0); .p(0,18,0,12); .fs(10,1.8,-0.25); .bold;}
    .progress-bar{.rel; .w(calc(100% - 180px));  .ib; .vam; .p(10,0); .bgc(transparent); cursor:pointer;
      .bar-bgc{.h(4); .wf;pointer-events: none;.bgc(#717171);
        span{.hf; .w(50%); .block; .bgc(#fff); }
      }
    }
    .volume{.ib; .ml(24); .vam;
      .volume-btn{.p(1); .bgc(transparent); .ib;
        span{.w(2); .bgc(#717171); .block;}
        &:nth-of-type(1) span{.h(3);}
        &:nth-of-type(2) span{.h(5);}
        &:nth-of-type(3) span{.h(7);}
        &:nth-of-type(4) span{.h(9);}
        &:nth-of-type(5) span{.h(11);}
      }
      &.volume-0{
        .volume-btn:nth-of-type(1) span{.bgc(#fff);}
      }
      &.volume-1{
        .volume-btn:nth-of-type(1) span,
        .volume-btn:nth-of-type(2) span{.bgc(#fff);}
      }
      &.volume-2{
        .volume-btn:nth-of-type(1) span,
        .volume-btn:nth-of-type(2) span,
        .volume-btn:nth-of-type(3) span{.bgc(#fff);}
      }
      &.volume-3{
        .volume-btn:nth-of-type(1) span,
        .volume-btn:nth-of-type(2) span,
        .volume-btn:nth-of-type(3) span,
        .volume-btn:nth-of-type(4) span{.bgc(#fff);}
      }
      &.volume-4{
        .volume-btn span{.bgc(#fff);}
      }
    }
    &.active{
      .play-btn{.bg('/img/common/ic-white-pause.png');}
      .progress-bar{
        span{transition:width .3s linear}
      }
    }
  }
}
@media (min-width: @screen-tp-min) {
  [audio-player] {
    .player{
      .volume {.ib; .ml(24); .vam;
        .volume-btn {.p(1); .bgc(transparent);
          span {.w(3); .bgc(#717171); .block;}
          &:nth-of-type(1) span {.h(4);}
          &:nth-of-type(2) span {.h(6);}
          &:nth-of-type(3) span {.h(8);}
          &:nth-of-type(4) span {.h(10);}
          &:nth-of-type(5) span {.h(12);}
        }
      }
    }
  }
}

@media (min-width: @screen-tl-min) {
  [audio-player] {}
}

@media (min-width: @screen-dm-min) {
  [audio-player] {}
}
</style>