<template>
  <main policy>
    <component :is="policy"/>
  </main>
</template>

<script>
import PolicyEN from '@/views/pages/policy-EN';
import PolicyKR from '@/views/pages/policy-KR';
import PolicyJP from '@/views/pages/policy-JP';

export default {
  name: 'policy',
  components: {},
  data() {
    return {
      policy: null,
    };
  },
  methods: {
    setData() {
      if(this.$i18n('lang') === 'ko') this.policy = PolicyKR;
      else if(this.$i18n('lang') === 'jp') this.policy = PolicyJP;
      else this.policy = PolicyEN;
    },
  },
  mounted() {
    this.$store.commit('theme', 'default');
    this.setData();
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
</style>
