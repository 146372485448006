<template>
  <div play-wide>
    <div class="wrap">
      <div class="video-wrap">
        <cdn-video :src="content" autoplay :muted="soundMuted" controls playsinline @ended="ended" @volumechange="volumechange" v-if="isVideo"/>
        <cdn-img :src="content" filekey="play" v-else/>
      </div>
    </div>
  </div>
</template>

<script>
import CdnVideo from '@/views/components/CdnVideo';
import CdnImg from '@/views/components/CdnImg';

export default {
  name: 'PlayWide',
  components: { CdnImg, CdnVideo },
  data() {
    return {
      screenOpen: false,
      isPaused: false,
      isMuted: true,
      time: '00:00',
      progress: 0,
      barIn: false,
    };
  },
  props: {
    content: { type: String, default: null },
  },
  computed: {
    isVideo() {
      return /\.(mp4|webm)/.test(this.content);
    },
    video() {
      return this.$el.querySelector('video');
    },
    soundMuted() {
      return this.$store.state.soundMuted;
    },
  },
  methods: {
    ended() {
      this.$emit('next');
    },
    volumechange() {
      this.$store.commit('soundMuted', this.video.muted)
    },

  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[play-wide] { .rel; .f;
  .wrap {.rel; .lt(50%, 50%); .t-xyc;
    .video-wrap{.f; .rel;
      img { .block; }
      video, img { .wf; .rel; }
      p{.f; .abs; .lt(0,0); cursor: pointer;}
    }

  }
  @media (max-width: @screen-ml-max) and (max-height: 515px) {
    .wrap { .f; .rel;
      .video-wrap{
        video, img {.rel; .f; object-fit: contain; }
      }
    }
  }
}
@media (min-width: @screen-tp-min) {
  [play-wide] {
    .wrap {
      .play {.wh(68);}
    }
    @media (min-width: @screen-tp-min) and (max-height: 768px) {
      .wrap { .f; .rel;
        .video-wrap{
          video, img {.rel; .f; object-fit: contain; }
        }
      }
    }
  }
}
@media (min-width: @screen-tl-min) {
  [play-wide] {
    .wrap {
      .controls {.rb(36, 24);
        a { .wh(32);
          &.mute {.mr(24);}
        }
      }
    }
    @media (min-width: @screen-tl-min) and (max-height: 1024px) {
      .wrap { .f; .rel;
        .video-wrap{
          video, img {.rel; .f; object-fit: contain; }
        }
      }
    }
  }
}
@media (min-width: @screen-dm-min) {
  [play-wide] {
    .wrap { .f; .rel;
      .video-wrap{
        video, img {.rel; .f; object-fit: contain; }
      }
    }
  }
}
</style>
