import _get from 'lodash/get';

/**
 * @typedef {
 *  { [locale: string]: { country: string, language: string } }
 * } LocaleMap
 */

const koreanWordFinalSoundCheck = word => {
  let lastCode = word.charCodeAt(word.length - 1);
  if (lastCode < 58) return [1, 1, 0, 1, 0, 0, 1, 1, 1, 0][lastCode - 48];
  if (lastCode < 91) lastCode += 32;
  if (lastCode < 123) return [0, 1, 1, 0, 0, 0, 1, 0, 0, 0, 1, 1, 1, 1, 0, 1, 1, 0, 0, 1, 0, 0, 0, 0, 0, 0][lastCode - 97];
  return (lastCode - 0xAC00) % 28 > 0;
};

export default class BI18n {
  #data;
  #defaultLocale;
  #fallbackLang;
  /** @type {LocaleMap} */
  #localeMap;
  #useLocales;

  /**
   * @param { Record } data
   * @param { { locales: LocaleMap, defaultLocale: string } } config
   */
  constructor({ data, config }) {
    this.#data = data;
    this.#localeMap = config.locales;
    this.#useLocales = Object.keys(config.locales);
    this.#defaultLocale = config.defaultLocale;
    this.#fallbackLang = this.languageByRouteLocale(config.defaultLocale);
  }

  localeByRouteLocale(routeLocale) {
    return routeLocale || this.#defaultLocale;
  }

  pathByRouteLocale(routeLocale) {
    return !routeLocale || routeLocale === this.#defaultLocale ? '' : '/' + routeLocale;
  }

  languageByRouteLocale(routeLocale) {
    return this.#localeMap[routeLocale || this.#defaultLocale].language;
  }

  countryByRouteLocale(routeLocale) {
    return this.#localeMap[routeLocale || this.#defaultLocale].country;
  }

  matches() {
    return this.#useLocales.filter(locale => locale !== this.#defaultLocale);
  }

  routeMatches() {
    return this.matches().join('|');
  }

  /**
   * @param {string} key
   * @param {string} lang
   * @param {boolean} plural
   * @param {boolean} noMark
   * @param {*?} options
   * @returns {string}
   */
  translate(key, lang, plural, noMark, options) {
    if (!key) throw new Error('[BI18n] empty key');
    let curr = _get(this.#data[lang], key);
    curr = curr ?? _get(this.#data[this.#fallbackLang], key);
    let t = curr
      ? (typeof curr === 'string')
        ? curr
        : (curr[lang] || curr[this.#fallbackLang])
      : undefined;
    if (t === undefined)
      return noMark ? null : `@@${ key }@@`;
    let params;
    if (plural) {
      const pl = t.split('|').map(s => s.trim());
      if (typeof options !== 'number') throw new Error('[BI18n] only number option is allowed for pluralization');
      if (options < 1) t = pl[0]; // count 가 0 혹은 음수라면 첫번째
      else if (options > 1) t = pl[pl.length - 1]; // count 가 1 보다 크다면 마지막 항목
      else if (pl.length > 2) t = pl[1]; // count 가 1 이고 복수 항목이 2개 이상 이면 2번째
      else t = pl[0]; // 아니면 첫번째
      params = { n: options, count: options };
    } else {
      params = options; // || paramInKey;
    }
    if (params) {
      return t.replace(/{([\w.]+)(?:\|([^,}]+),([^,}]+))?}/g, (_, key, v1, v2) => {
        const word = _get(params, key);
        if (word === undefined) return `!!${ key }!!`;
        if (v1 && v2) return word + (koreanWordFinalSoundCheck(word) ? v1 : v2);
        else return word;
      });
    } else return t;
  }
}
