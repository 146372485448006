<template>
  <img cdn-img :src="val" :alt="description">
</template>

<script>
export default {
  name: 'CdnImg',
  data() {
    return {
    };
  },
  props: {
    filekey: { type: String, default: null },
    src: { type: String, default: null },
  },
  computed: {
    val() {
      return `${this.cdnHost}/${this.location}`;
    },
    location() {
      return this.mediaVal(this.src)?.location ?? '';
    },
    sq() {
      return this.mediaVal(this.src)?.sq ?? '';
    },
    description() {
      if (!this.$store.state.images) return '';
      return this.$store.state.images.find(row => row.sq === this.sq)?.description ?? '';
    },
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";

[cdn-img] { .block; max-width: 100%; }
</style>