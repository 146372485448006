<template>
  <div module-item :class="info.type">
    <Slider class="basic-carousel" navigation slidesPerView="1">
      <div class="slide-item" v-for="(row, i) in info.value" :key="i">
        <div class="image-area">
          <cdn-img :src="row.image.pc" filekey="news" class="hidden-ml-down"/>
          <cdn-img :src="row.image.mobile" filekey="news" class="hidden-tp-up"/>
        </div>
        <div class="desc" v-html="row.desc" v-if="row.desc"></div>
      </div>
    </Slider>
  </div>
</template>

<script>
import CdnImg from '@/views/components/CdnImg';
import Slider from '@/views/components/Slider';

export default {
  name: 'Carousel',
  components: { Slider, CdnImg },
  props: ['value'],
  computed: {
    info() {
      return this.value;
    },
  },
};
</script>

