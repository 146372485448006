<template>
  <video cdn-video :class="fit" :autoplay="autoplay" :loop="loop" :muted="muted" :playsinline="playsinline" controlsList="nodownload" :src="val"
         @timeupdate="$emit('timeupdate', $event)" @ended="$emit('ended')" @click="$emit('click')" @volumechange="$emit('volumechange')"/>
</template>

<script>
// import oax from '@/utils/oax';

export default {
  name: 'CdnVideo',
  data() {
    return {
    };
  },
  props: {
    filekey: { type: String, default: null },
    src: { type: String, default: null },
    autoplay: {type: Boolean, default: false },
    loop: {type: Boolean, default: false },
    muted: {type: Boolean, default: true },
    playsinline: {type: Boolean, default: false },
    fit: { type: String, default: 'cover' },
  },
  computed: {
    val() {
      return `${this.cdnHost}/${this.location}`;
    },
    location() {
      return this.mediaVal(this.src)?.location ?? '';
    },
    sq() {
      return this.mediaVal(this.src)?.sq ?? '';
    },
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";

[cdn-video] { .block;
  &.cover { object-fit: cover; }
  &.contain { object-fit: contain; }
}
</style>