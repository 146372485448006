<template>
  <div notice-modal>
    <div class="dim"></div>
    <div class="content">
      <div v-html="content"></div>
      <a @click="$emit('resolve')">Confirm</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoticeModal',
  props: {
    options: Object
  },
  computed: {
    content() {
      return this.options && this.options.content;
    },
  },
  mounted() {
    this.$scroll.freeze();
  },
  beforeDestroy() {
    this.$scroll.release();
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[notice-modal] { .tc; color: #000;
  .dim { .abs; .f; .lt; .bgc(rgba(0,0,0,0.3)); }
  .content { .w(300); .min-h(100); .br(4); .p(40,30,80); .fs(24); .bgc(#fff); .abs; .lt(50vw,50vh); .t-xyc;
    a { .abs; .lb(50%,25); .t-xc; .p(10,15); .ib; .mt(20); .fs(16); .br(4); .bgc(#111); color: #fff; }
  }
}

@media (min-width: @screen-tp-min) {
  [notice-modal] .content { .w(420); .p(60,30,120);
    a { .b(30); }
  }
}
</style>
