<template>
  <main news-main>
    <section class="hero">
      <locale-link :to="`/news/article/${hero.url}`">
        <div class="kv-wrap">
          <div class="load-news"></div>
          <cdn-img :src="hero.kvPc" class="hidden-ml-down"/>
          <cdn-img :src="hero.kvMobile" class="hidden-tp-up"/>
        </div>
        <div class="info">
          <p>{{ formDate(hero.date) }}<span>{{ codeLabel(hero.category) }}</span></p>
          <h2>{{ hero.title }}</h2>
        </div>
      </locale-link>
    </section>
    <section class="featured">
      <h3>FEATURED</h3>
      <Slider scrollbar navigation slidesPerView="auto">
        <div v-for="item in featured" :key="item.odr">
          <div class="slider-item" @click="linkTo(item.url)">
            <div class="inner">
              <div class="load-news"></div>
              <cdn-img :src="item.image"/>
              <p v-html="enterToBr(item.txt)"></p>
            </div>
          </div>
        </div>
      </Slider>
    </section>
    <section class="latest" v-if="latest && latest.length">
      <h3>LATEST</h3>
      <ul>
        <li v-for="item in latest" :key="item.slug">
          <a @click="linkTo(item.slug, '/news/article/')">
            <div class="img-wrap">
              <div class="load-news"></div>
              <cdn-img :src="item.image" />
            </div>
            <p>{{ formDate(item.publishDtt) }} <span>{{ codeLabel(item.priorityCategory) }}</span></p>
            <p>{{ item.title }}</p>
          </a>
        </li>
      </ul>
      <locale-link to="/news/all" class="more-btn"><p>View More</p><p class="icon"><span></span></p></locale-link>
    </section>
    <section class="categories">
      <h3>CATEGORIES</h3>
      <ul>
        <li v-for="item in categories" :key="item.odr"><locale-link :to="item.url">{{ item.text }}</locale-link></li>
      </ul>
    </section>
  </main>
</template>

<script>
import oax from '@/utils/oax';
import Slider from '@/views/components/Slider';
import CdnImg from '@/views/components/CdnImg';

export default {
  name: 'newsMain',
  components: { Slider, CdnImg, },
  data() {
    return {
      info: null,
    };
  },
  computed: {
    hero() {
      return this.info.hero;
    },
    featured() {
      return this.info.featured;
    },
    latest() {
      return this.info.latest;
    },
    categories() {
      return this.info.categories;
    },
  },
  mounted() {
    this.$store.commit('theme', 'default');
    // console.log(this.info);
  },
  async asyncData({route}) {
    const info = (await oax.get('/api/main/news', { lang: route.params.locale || 'ko' })).data;
    if (!info) throw new Error("404");

    return { info };
  }
};

</script>

<style lang="less">
@import "~@/less/proj.less";

[news-main] {.pt(60); .pb(140);
  section{
    h3{.fs(24);}
    &.hero{.rel; .proj-max-w;
      a{.block;}
      .kv-wrap{.wf; aspect-ratio: 3 / 4; .rel;
        img{.f; object-fit: cover; .rel;}
      }
      .info{.abs; .lb(0,0); .p(32,18); .c(#fff);
        p{.fs(12); .pb(10);
          span{.ml(12); .medium;}
        }
        h2{.fs(20,1.5,-0.25); .bold;}
      }
    }
    &.featured{.m-m; .mt(96); .rel; .proj-max-w; .wf; overflow-x:hidden; .-box;
      h3{.pb(24);}
      .swiper-container {overflow: visible;
        .swiper-wrapper {
          .swiper-slide {.wh(100%, 267); .max-w(325);
            &:last-of-type .slider-item {.p;}
            .slider-item {.f; .pr(12); .-box; cursor: pointer;
              .inner {.rel; .f; overflow: hidden;
                img {.f; object-fit: cover; transform: scale(1); transition: transform 1s cubic-bezier(0, 0, 0.13, 1.04);}
                p {.abs; text-transform:uppercase; z-index: 9; .lt(50%, 50%); .t-xyc; .ff-hvd-black; .c(#fff); .fs(38, 0.97); .tc;}
                .no-touch &:hover {
                  img {transform: scale(1.1);}
                }
              }
            }
          }
        }
      }
      .swiper-scrollbar { .wf; .h(2); .bgc(#ddd); .mt(40);
        .swiper-scrollbar-drag{.bgc(#333);}
      }
      .swiper-navigation{.hide;}
    }
    &.latest{.m-m; .mt(96); .rel; .proj-max-w; .tc;
      h3{.pb(24); .tl;}
      ul{display: grid; grid-template-columns: 1fr;
        li{.mb(40); .tl;
          &:last-of-type{.mb(0);}
          .no-touch &:hover{
            .img-wrap{.rel;
              img {transform: scale(1.1); .rel;}
            }
          }
          .img-wrap{.mb(20); .wf; aspect-ratio: 17 / 9; overflow:hidden; .rel;
            img{.rel; .f; object-fit: cover; transform: scale(1); transition: transform 1s cubic-bezier(0, 0, 0.13, 1.04);}
          }
          p {.c(#333);
            &:first-of-type {.fs(12); .pb(10);
              span {.ml(12); .ib; .medium;}
            }
            &:last-of-type {.fs(18, 1.61, -0.25); .bold;}
          }
        }
      }
      .more-btn{text-transform:uppercase; .rel; .fs(16,1.38); .bold; .c(#333); .ib;  .mt(72);
        p{.ib; .vam;
          &.icon{.wh(18); .ml(8); .rel; overflow: hidden; transform:translateX(-10%); transition: transform .6s ease;
            span{ .f; .abs; .lt(0); .contain; .no-repeat; .bg('/img/common/btn-icon-landing.png');}
          }

        }
        &:after{content:''; .abs; .w(0); .h(2); .bgc(#333); .lb(0,-5); transition:width .6s cubic-bezier(0.4,-0.03, 0, 1.03);}
        .no-touch &:hover{
          &:after{.wf;}
          p{
            &.icon{transform:translateX(20%);}
          }
        }
      }
    }
    &.categories{.m-m; .mt(132); .rel; .proj-max-w;
      h3{.pb(32);}
      ul{
        li {.mr(32);.ib;
          &:last-of-type{.mr(0);}
          a{.ff-hvd-black; -webkit-text-stroke: 1px #1e1e1e; .c(#fff); .fs(50,66);  transition:color .4s ease;
            .no-touch &:hover{.c(#1e1e1e);}
          }
        }
      }
    }
  }
}

@media (min-width: @screen-tp-min) {
  [news-main] {.pb(160);
    section{
      &.hero{.t-m;
        .kv-wrap{ aspect-ratio: 16 / 9;}
        .info{.p(24); .l(36); .w(498);}
      }
      &.featured {.t-m;
        .swiper-container{
          .swiper-wrapper{
            .swiper-slide{.wh(407,321); max-width:none;
              .slider-item{ .pr(16);
                .inner{
                  p{.fs(46,0.98);}
                }
              }
            }
          }
        }
        .swiper-scrollbar { .hide;}
        .swiper-navigation{.block; .abs; .rt(36,8);
          a{.rel; .wh(24); .ib; .vat; .lt(0); .t-xy(0,0); .m; .contain; .no-repeat; .bgc; .c;
            .no-touch &:hover{.o(.6);}
            &:after{.hide;}
          }
          .swiper-button-prev{.mr(20); .bg('/img/common/ic-swipe-prev-dark.png');}
          .swiper-button-next{.bg('/img/common/ic-swipe-next-dark.png');}
        }
      }
      &.latest{.t-m;
        ul{grid-template-columns: 1fr 1fr;column-gap: 18px;
          li{.mb(56);
            &:nth-last-child(2){.mb(0);}
          }
        }
      }
      &.categories{.mt(132); .t-m;
        h3{.pb(84);}
        ul{
          li {.mr(36);
            a{.fs(68,84);}
          }
        }
      }
    }
  }
}

@media (min-width: @screen-tl-min) {
  [news-main] {.pb(200);
    section{
      &.hero{
        .info{.p(30); .w(600);
          p{.fs(12); .pb(14);}
          h2{.fs(24,1.5,-0.25);}
        }
      }
      &.featured {.mt(108);
        .swiper-container{
          .swiper-wrapper{
            .swiper-slide{.wh(476,378);
              .slider-item{
                .inner{
                  p{.fs(54,0.98);}
                }
              }
            }
          }
        }
      }
      &.latest{.mt(108);
        ul{.tl; grid-template-columns: 1fr 1fr 1fr;column-gap: 14px;
          li{.mb(72);
            &:nth-last-child(3){.mb(0);}
          }
        }
        .more-btn{.mt(96);}
      }
      &.categories{.mt(132);
        h3{.pb(48);}
        ul{
          li {.mr(56);
            a{.fs(88,106);}
          }
        }
      }
    }
  }
}
@media (min-width: @screen-dm-min) {
  [news-main] {.pt(80); .pb(260);
    section{
      h3{.fs(26);}
      &.hero{.pc-m;
        .info{.p(48); .w(900); .l(48);
          p{.fs(12); .pb(14);}
          h2{.fs(34,1.54,-0.25);}
        }
      }
      &.featured {.pc-m; .mt(160);
        h3{.pb(32);}
        .swiper-container{
          .swiper-wrapper{
            .swiper-slide{.wh(682,533);
              .slider-item{ .pr(24);
                .inner{
                  p{.fs(74,0.99);}
                }
              }
            }
          }
        }
        .swiper-navigation{.rt(48,0);
          a{ .wh(32);}
          .swiper-button-prev{.mr(24);}
        }
      }
      &.latest{.mt(160); .pc-m;
        ul{column-gap: 24px;
          li{.mb(84);}
        }
        .more-btn{.mt(111);
          p{.fs(24);
            &.icon{.wh(28); .ml(12);}
          }
        }
      }
      &.categories{.mt(180); .pc-m;
        h3{.pb(60);}
        ul{
          li {.mr(80);
            a{.fs(125,145);}
          }
        }
      }
    }
  }
}
@media (min-width: @screen-dl-min) {
  [news-main] {.pb(280);
    section{
      h3{.fs(36);}
      &.hero{.pcl-m;
        .info{.p(60); .w(1100); .l(60);
          p{.fs(16); .pb(14);}
          h2{.fs(44,1.5,-0.25);}
        }
      }
      &.featured{.pcl-m; .mt(180);
        h3{.pb(48);}
        .swiper-container{
          .swiper-wrapper{
            .swiper-slide{.wh(739,607);
              .slider-item{ .pr(27);
                .inner{
                  p{.fs(84,0.99);}
                }
              }
            }
          }
        }
        .swiper-navigation{.r(60);
          a{.wh(42);}
          .swiper-button-prev{.mr(32);}
        }
      }
      &.latest{.mt(180); .pcl-m;
        h3{.pb(48);}
        ul{column-gap: 33px;
          li{.mb(84);
            p{
              &:first-of-type{.fs(16);  .pb(13);
                span{.ml(16);}
              }
              &:last-of-type{.fs(24,1.58,-0.25);}
            }
          }
        }
        .more-btn{.mt(140);
          p{.fs(30);
            &.icon{.wh(32); .ml(22);}
          }
        }
      }
      &.categories{.mt(200); .pcl-m;
        h3{.pb(72);}
        ul{
          li {.mr(104);
            a{.fs(167,194);  -webkit-text-stroke: 1.3px #1e1e1e;}
          }
        }
      }
    }
  }
}
@media (min-width: @screen-dx-min) {
  [news-main] {
    section{
      &.featured {
        .swiper-container{
          .swiper-wrapper{
            .swiper-slide{.wh(955,785);
              .slider-item{ .pr(34);}
            }
          }
        }
        .swiper-navigation{
          a{.wh(42);}
          .swiper-button-prev{.mr(32);}
        }
      }
      &.categories{
        ul{
          li {.mr(120);}
        }
      }
    }
  }
}
</style>
