<template>
  <div module-item :class="info.type">
    <template v-if="info.type.includes('loop')">
      <video loop muted autoplay playsinline :src="`${cdnHost}/${info.value.video}`"></video>
    </template>
    <template v-else>
      <video controls :src="`${cdnHost}/${info.value.video}`"></video>
    </template>
    <div class="desc" v-html="enterToBr(info.value.desc)" v-if="info.value.desc"></div>
  </div>
</template>

<script>

export default {
  name: 'SingleVideo',
  props: ['value'],
  computed: {
    info() {
      return this.value;
    },
  },
};
</script>