import AXIOS from 'axios';
import qs from 'qs';
import _ from 'lodash';

let store;

const axios = AXIOS.create({
  baseURL: process.env.VUE_APP_API_HOST,
  withCredentials: true
})

axios.interceptors.request.use((request) => {
  if (!request.params) request.params = {};
  if (store) request.params.lang = store.state.lang;
  if (request.method === 'get') {
    request.params._ = _.now();
  }
  return request;
});

const trimOrPass = row => (row && typeof row === 'string' ? _.trim(row) : row);

const pick = v => (v !== undefined && v !== '' && v !== null);

const prepParam = data => _.pickBy(_.mapValues(_.omit(data, 'files'), trimOrPass), pick);

const get = (url, data, config) => {
  const params = prepParam(data);
  return axios.get(url, _.assign(config, { params }));
};

const del = (url, data, config) => {
  const params = prepParam(data);
  return axios.delete(url, _.assign(config, { params }));
};


const $multipart = (url, params, config) => {
  _.assign(config, { headers: { 'content-type': 'multipart/form-data' } });
  const formData = new FormData();
  _.forEach(params, (item, key) => {
    if (`${item}` === 'FILE_INPUT_MODEL') {
      _.forEach(item.add, (file) => {
        formData.append(key, file);
      });
      _.forEach(item.remove, (remove) => {
        formData.append('removeFiles', remove);
      });
      _.forEach(item.exist, (exist) => {
        formData.append('existFiles', exist.sq);
      });
    } else {
      formData.append(key, item);
    }
  });
  return axios.post(url, formData, config);
};

const put = (url, data, config) => {
  if (typeof data === 'string') return axios.put(url, data, config);
  const params = prepParam(data);
  if (_.some(params, row => `${row}` === 'FILE_INPUT_MODEL')) return $multipart(url, params, config);
  return axios.put(url, qs.stringify(params), config);
};

const patch = (url, data, config) => {
  if (typeof data === 'string') return axios.patch(url, data, config);
  const params = prepParam(data);
  if (_.some(params, row => `${row}` === 'FILE_INPUT_MODEL')) return $multipart(url, params, config);
  return axios.patch(url, qs.stringify(params), config);
};

const post = (url, data, config) => {
  if (typeof data === 'string') return axios.post(url, data, config);
  const params = prepParam(data);
  if (_.some(params, row => `${row}` === 'FILE_INPUT_MODEL')) return $multipart(url, params, config);
  return axios.post(url, qs.stringify(params), config);
};

const multipart = (url, data, config) => {
  const params = prepParam(data);
  return $multipart(url, params, config);
};

const validation = (el, option) => {
  const errors = el.querySelectorAll('.error');
  if (errors.length) {
    let fn = alert;
    if (typeof option === 'function') {
      fn = option;
    } else {
      el.classList.add('requested');
    }
    fn(errors[0].getAttribute('title') || (store.state.lang === 'ko' ? '필수항목을 모두 기입해 주세요.' : 'Please fill in all required fields.'));
    const input = (errors[0].tagName === 'INPUT' || errors[0].tagName === 'TEXTAREA') ? errors[0] : errors[0].querySelector('input, textarea');
    if (input) input.focus();
  }
  return errors.length === 0;
};

const setCsrf = (csrf) => {
  axios.defaults.headers.common[csrf.headerName] = csrf.token;
};

const fillFile = (info) => {
  _.forEach(_.groupBy(info.files, 'name'), (files, name) => {
    info[name] = { toString: () => 'FILE_INPUT_MODEL', exist: files };
  });
};

const flattenFile = (info) => {
  if (!info) return;
  if (_.isArray(info)) return flattenFileList(info);
  _.forEach(_.groupBy(info.files, 'name'), (files, name) => {
    if (files && files[0]) info[name] = files[0].location;
  });
};

const flattenFileList = (list) => {
  return _.map(list, flattenFile);
};

const init = (s) => {
  store = s;
};


export default {
  get,
  post,
  multipart,
  put,
  patch,
  delete: del,
  validation,
  setCsrf,
  fillFile,
  flattenFile,
  init,
  stringify: qs.stringify,
};
