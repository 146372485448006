<template>
  <div module-item :class="info.type">
    <AudioPlayer :src="`${cdnHost}/${info.value.audio}`"/>
    <div class="desc" v-html="enterToBr(info.value.desc)"></div>
  </div>
</template>

<script>

import AudioPlayer from '@/views/components/AudioPlayer';
export default {
  name: 'Audio',
  components: { AudioPlayer },
  props: ['value'],
  computed: {
    info() {
      return this.value;
    },
  },
};
</script>

