<template>
  <main play-list>
    <section class="hero">
      <div class="categories">
        <Slider clickActive centeredSlides slidesPerView="auto" ref="slider" :option="{ on: {slideChangeTransitionStart: changedSlider }}" :initialSlide="activeIndex">
          <div v-for="item in categories" :key="item.code">
            <div class="slider-item">
              <locale-link :to="item.data.route">{{ item.label }}</locale-link>
            </div>
          </div>
        </Slider>
      </div>
      <p class="description">{{ description }}</p>
    </section>
    <section class="list">
      <div class="sticky" v-if="subCategories">
        <div class="sub-categories">
          <ScrollMenu :key="category">
            <div class="swiper-slide" v-for="item in subCategories" :key="item.code">
              <locale-link :to="item.data.route" :class="{ on: checkOn(item) }">{{ item.label }}</locale-link>
            </div>
          </ScrollMenu>
          <a class="more" @click="tagOpen" :class="{ tagOpen: tagV }"><i></i></a>
        </div>
      </div>
      <div class="lowest-categories" v-if="!isEmpty(lowestCategories)">
        <ScrollMenu :key="category">
          <div class="swiper-slide" v-for="item in lowestCategories" :key="item.code">
            <locale-link :to="item.data.route" :class="{ on: checkLowOn(item) }">{{ item.label }}</locale-link>
          </div>
        </ScrollMenu>
        <p></p>
      </div>
      <ul>
        <li :class="{'has-video': item.video}" v-for="(item, i) in list" :key="i" @mouseenter="over" @mouseleave="out">
          <a @click="routeViewer(item, code)">
            <img class="load-img" src="/img/common/play-loading-img-h.png" alt="loading image"/>
            <cdn-video :src="item.video" muted playsinline class="over-video" v-if="item.video && !touch"/>
            <cdn-img :src="item.image" />
            <div class="text">
              <p>{{ formDate(item.publishDtt) }} <span>{{ codeLabel(item.priorityCategory) }}</span></p>
              <p>{{ item.title }}</p>
            </div>
          </a>
          <div class="sns-wrap">
            <SnsShare type="play-main" :value="item" :share-path="getSlugUrl(item)"></SnsShare>
          </div>
        </li>
      </ul>
      <div class="view-more" v-if="paging.pageNo < paging.pageCount">
        <a @click="getList"><i></i><span>VIEW MORE</span><span>{{ paging.pageNo }}/{{ paging.pageCount }}</span></a>
      </div>
    </section>
    <div class="category-popup" :class="{ open: tagV }">
      <div class="dimed" @click="tagClose"></div>
      <div class="holder">
        <label>{{ codeLabel(code) }}</label>
        <ul>
          <li v-for="item in subCategories" :key="item.code">
            <locale-link :to="item.data.route" :class="{ on: checkOn(item) }">{{ item.label }}</locale-link>
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>

<script>
import oax from '@/utils/oax';
import Slider from '@/views/components/Slider';
import CdnImg from '@/views/components/CdnImg';
import CdnVideo from "@/views/components/CdnVideo";
import SnsShare from "@/views/components/SnsShare";
import ScrollMenu from "@/views/components/ScrollMenu";

export default {
  name: 'play-list',
  components: {ScrollMenu, SnsShare, CdnVideo, CdnImg, Slider },
  data() {
    return {
      code: null,
      list: null,
      paging: null,
      tagV:false,
    };
  },
  computed: {
    category() {
      return this.$route.params.category;
    },
    sub() {
      return this.$route.params.sub;
    },
    low() {
      return this.$route.params.low;
    },
    categories() {
      return [{label: 'All', data: { route: `/${this.root}/all` }}, ...this.codeList(this.root)];
    },
    subCategories() {
      if (this.category === 'all') return null;
      let code = this.code.split('-').slice(0,3).join('-');
      code = !this.sub || this.sub === 'all' ? code : this.codeParent(code);
      if (this.codeList(code).length < 2) return null; // 서브 카테고리가 1개일 때 안보이게
      return [{label: 'All', data: { route: `/${this.root}/${this.category}/all` }}, ...this.codeList(code)];
    },
    lowestCategories() {
      if (!this.sub || this.sub === 'all') return null;
      const code = !this.low || this.low === 'all' ? this.code : this.codeParent(this.code);
      if (this.codeList(code).length < 2) return null; // 서브 카테고리가 1개일 때 안보이게
      return [{label: 'All', data: { route: `/${this.root}/${this.category}/${this.sub}/all` }}, ...this.codeList(code)];
    },
    activeIndex() {
      return this.categories.findIndex(row => row.data.route.includes(this.category));
    },
    description() {
      if (this.category === 'all') return this.codeData(this.root)?.description || '';
      return this.categories.find(row => row.data.route.includes(this.category))?.data?.description || '';
    },
  },
  watch: {
    code: 'refresh',
  },
  methods: {
    refresh() {
      this.$refs.slider.go(this.activeIndex);
      this.arrangeData();
    },
    checkOn(item) {
      if (!this.sub) return item.label === 'All';
      if (!this.isEmpty(this.lowestCategories)) return this.$route.path.includes(item.data.route);
      return this.$route.path === `${this.$i18n('path')}${item.data.route}`;
    },
    checkLowOn(item) {
      if (!this.low) return item.label === 'All';
      return this.$route.path === `${this.$i18n('path')}${item.data.route}`;
    },
    changedSlider(e) {
      const route = this.categories[e.realIndex].data.route;
      if (!this.$route.path.includes(route)) this.localeLink(route);
    },
    async getList() {
      const { data } = await oax.get(`/api/article/${this.root}`, { lang: this.lang, category: this.code, perPage: 12, pageNo: this.paging.pageNo+1 });
      this.list = [...this.list, ...data.list];
      this.paging = data.paging;
      this.arrangeData();
    },
    arrangeData() {
      this.list.forEach(item => {
        item.video = this.matchMediaType(JSON.parse(item.contentEncode === 'base64' ? decodeURIComponent(atob(item.contents)) : item.contents)?.content[0], 'video');
      });
    },
    over(e) {
      if (this.touch) return;
      const video = e.target.querySelector('.over-video');
      if (this.isEmpty(video)) return;
      if (!this.videoIsPlaying(video)) video.play();
    },
    out(e) {
      if (this.touch) return;
      const video = e.target.querySelector('.over-video');
      if (this.isEmpty(video)) return;
      if (this.videoIsPlaying(video)) video.pause();
      video.currentTime = 0;
    },
    routeViewer(item) {
      this.$store.commit('resetPage', { code: this.code, url: this.$route.path });
      this.localeLink(this.getSlugUrl(item));
    },
    tagOpen(){
      this.tagV = true;
      this.$scroll.freeze();
    },
    tagClose(){
      this.tagV = false;
      this.$scroll.release();
    }
  },
  mounted() {
    this.$store.commit('theme', 'dark dark-list');
  },
  created() {
    this.arrangeData();
  },
  async asyncData({route, store}) {
    const lang = route.params.locale || 'ko';
    const path = route.path.replace('/all', '').replace(`/${lang}`, '');
    const category = store.state.codes.find(row => row.data.route === path)?.code;
    if (!category) throw new Error("404");
    const info = (await oax.get(`/api/article/${route.meta.root}`, { lang, category, perPage: 12 })).data;
    if (!info) throw new Error("404");

    return { list :info.list, paging: info.paging, code: category };
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";

[play-list] {.pb(141);
  section {
    &.hero { .pt(132);
      .categories {overflow: hidden; .h(48);
        .swiper-container{ .-box; overflow: visible;
          .swiper-wrapper{
            .swiper-slide { width: auto;
              &.swiper-slide-active .slider-item a{.c(#fff); -webkit-text-stroke: 1px #fff;}
              .slider-item {.m(0,24); .min-w(150);
                a { .tc; .block; .fs(48,48); text-transform:uppercase; .ff-hvd-black; -webkit-text-stroke: 1px #ebebeb; .c(#000); transition:color .4s ease;}
              }
            }
          }
        }
      }
      .description { .mt(10); .medium; .p(0,29); .tc; .fs(16,1.5,-0.4);}
    }
    &.list{ .rel; .m-m; .proj-max-w;
      .sticky { position: sticky; .mt(32); .lt; .p(16, 0); .z(2); background: #000; }
      .sub-categories { .tc; .crop;
        .locked{
          .swiper-slide:last-of-type{.mr(0);}
        }
        .swiper-slide { .p; overflow: hidden;
          &:last-of-type{.mr(75);}
          a {.-a(#fff); .fs(14); .p(3, 8, 4); .block; .medium;
            &.on{.bgc(#fff); .c(#000);}
          }
        }
        [scroll-menu].locked .swiper-wrapper { .justify-center; }
        [scroll-menu].locked + .more { .hide; }
        .more{.wh(75,100%); z-index:9; .abs; .rt(0,0);   background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #000 65%);
          i{ .abs; .rt(10,50%); transform:rotate(0deg) translateY(-50%); .wh(16); .bg('/img/common/ic-down-arrow-dark.png'); .contain; .no-repeat;}
        }
      }
      .lowest-categories { .mt(44); .rel; overflow: hidden;
        .swiper-slide {
          &:last-of-type{.mr(75);}
          a { .ib; .fs(14); .medium; .c(#666); .rel; .pb(3);
            &.on { .c(#fff);
              &:after{.wf; .bgc(#fff);}
            }
            &:after{.cnt; .block; .abs; .h(1); .w(0); .lb(0,0); .bgc(#666); }
            .no-touch &:hover{
              &:after{.wf;}
            }
          }
        }
        p{.hf; .w(75px); z-index:9; .abs; .rt(0,0); background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #000 65%);}
      }
      .lowest-categories+ul{.pt(30);}
      >ul{ .rel; .pt(67); display: grid; grid-template-columns: 1fr;
        >li{.rel; overflow: hidden; aspect-ratio: 9 / 15; .mb(20);
          [cdn-img]{.f; .rel; object-fit: cover; transform:scale(1); .o(1); transition:transform 1s cubic-bezier(0, 0, 0.13, 1.04), opacity .6s ease;}
          .over-video { .abs; .lt; .f; transform:scale(1); transition:transform 1s cubic-bezier(0, 0, 0.13, 1.04);}
          .text{.abs; .wf; .-box; .lb(0,0); .p(36,24); background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
            p:first-of-type{.fs(12); .pb(12);
              span{.medium; .ib; .ml(8);}
            }
            p:last-of-type{.fs(18,1.61,-0.25); .bold;}
          }
          &:last-of-type{.mb(0);}
          .sns-wrap {.abs; .lt(0, 0); .wf; .h(70); background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
            [sns-share] { .rel; .wh(24); .m(12, 14); .abs; .rt(0, 0);
              a.btn{.bg('/img/common/ic-share-more.png'); .no-repeat; .contain;}
            }
          }
          .no-touch &:hover{
            [cdn-img], video{transform:scale(1.1);}
            &.has-video [cdn-img] { .o(0); }
          }
        }
      }
      .view-more{.tc; .mt(96);
        a{.rel;
          span{.ib; .vam; .fs(20,1.1); .bold;
            &:last-of-type{.ml(12);}
          }
          i{.wh(24); .ib; .vam; .mr(16); .contain; .no-repeat; .bg('/img/common/ic-white-more.png');}
          &:after{content:''; .abs; .w(0); .h(2); .bgc(#fff); .lb(0,-10); transition:width .6s cubic-bezier(0.4,-0.03, 0, 1.03);}
          .no-touch &:hover{
            &:after{.wf;}
          }
        }
      }
    }
  }
  .category-popup{.fix; .lb(0,0); z-index:99; .f; .t-y(-100%); transition:transform 0s ease; transition-delay: 1s; overflow: hidden;
    &.open{transition-delay: 0s; .t-y(0%);
      .dimed{.o(0.65);}
      .holder{.t-y(0%);}
    }
    .dimed{.bgc(#000); .o(0); z-index:1; .f; transition:opacity .6s ease;}
    .holder { .abs; .lb(0,0); .bgc(#000); .wf; .-box; .p(18,18,2); z-index:2;  .t-y(100%);  transition:transform .6s ease; }
    label{.block;.fs(14); .tc; .mb(18); .bold; text-transform:uppercase;}
    ul{
      li {.ib; .mr(16); .mb(16);
        a {.-a(#fff); .fs(14); .p(3, 8, 4); .block; .medium;
          &.on{.bgc(#fff); .c(#000);}
        }
      }
    }
  }
}
@media (min-width: @screen-tp-min) {
  [play-list] {.pb(180);
    section{
      &.hero {
        .categories { .h(114);
          .swiper-container{
            .swiper-wrapper{
              .swiper-slide {
                .slider-item {.m(0,40); .min-w(200);
                  a { .fs(76,1.5); }
                }
              }
            }
          }
        }
        .description { .mt(4); .p; .mh-c; .w(70%); .fs(16,1.5,-0.4);}
      }
      &.list{.t-m;
        .sticky { .rel; .p; .mt(46);}
        .sub-categories {
          .swiper-slide { .mt(14); .ib;
            &:last-of-type{.mr(0);}
          }
          .more{.hide;}
        }
        .lowest-categories { .mt(93);
          [scroll-menu] .swiper-slide + .swiper-slide{.ml(20);}
          .locked{.wf; max-width:none;}
          .swiper-slide {.ib;
            &:last-of-type{.mr(0);}
            a {.fs(14);}
          }
          p{.hide;}
        }
        .lowest-categories+ul{.pt(24);}
        >ul{.pt(84); grid-template-columns: 1fr 1fr; column-gap: 18px;
          >li{.mb(18);
            &:last-of-type(2){.mb(0);}
            .sns-wrap {[sns-share]{.wh(20); .m(12);}}
          }
        }
      }
    }
    .category-popup{.hide;}
  }
}
@media (min-width: @screen-tl-min) {
  [play-list] {.pb(200);
    section{
      &.hero { .pt(140);
        .categories { .h(140);
          .swiper-container{
            .swiper-wrapper{
              .swiper-slide {
                .slider-item {.m(0,52); .min-w(250);
                  a { .fs(93,1.5); }
                }
              }
            }
          }
        }
        .description { .mt(6);}
      }
      &.list{
        .sticky {.mt(50);}
        .sub-categories {
          .swiper-slide { .mt(14);}
          .more{.hide;}
        }
        .lowest-categories { .mt(100);}
        .lowest-categories+ul{.pt(24);}
        >ul{.pt(120); grid-template-columns: 1fr 1fr 1fr; column-gap: 14px;
          >li{.mb(14);
            &:nth-last-of-type(3){.mb(0);}
            .text{.p(28,22);}
            .sns-wrap {[sns-share]{.m(12,14);}}
          }
        }
        .view-more{.mt(101);}
      }
    }
  }
}
@media (min-width: @screen-dm-min) {
  [play-list] {.pb(280);
    section{
      &.hero { .pt(201);
        .categories { .h(198);
          .swiper-container{
            .swiper-wrapper{
              .swiper-slide {
                .slider-item {.m(0,72); .min-w(370);
                  &.swiper-slide-active .slider-item a{ -webkit-text-stroke: 1.2px #fff;}
                  a {.fs(132,1.5); -webkit-text-stroke: 1.2px #ebebeb;}
                }
              }
            }
          }
        }
        .description { .fs(20,1.5,-0.4);}
      }
      &.list{ .pc-m;
        .sticky {.mt(82);}
        .sub-categories {
          .swiper-slide{.mt(14);}
        }
        .lowest-categories { .mt(93);
          [scroll-menu] .swiper-slide + .swiper-slide{.ml(24);}
          .swiper-slide {
            a {.fs(16);}
          }
        }
        .lowest-categories+ul{.pt(32);}
        >ul{column-gap: 24px; .pt(160);
          >li{.mb(24);
            .text{.p(36,30);
              p:first-of-type{
                span{.ml(12);}
              }
              .title{.fs(22,1.59,-0.25);}
            }
            .sns-wrap {.h(110);
              [sns-share]{.wh(24); .m(16);}
            }
          }
        }
        .view-more{.mt(143);
          a{
            span{.fs(24,0.92);}
            i{.wh(28); .mr(20); }
          }
        }
      }
    }
  }
}
@media (min-width: @screen-dl-min) {
  [play-list] {.pb(300);
    section{
      &.hero { .pt(256);
        .categories { .h(264);
          .swiper-container{
            .swiper-wrapper{
              .swiper-slide {
                .slider-item {.m(0,96); .min-w(550);
                  &.swiper-slide-active .slider-item a{ -webkit-text-stroke: 1.6px #fff;}
                  a {.fs(176,1.5); -webkit-text-stroke: 1.6px #ebebeb;}
                }
              }
            }
          }
        }
        .description { .mt(8); .fs(26,1.5,-0.25);}
      }
      &.list{ .pcl-m;
        .sticky {.mt(112);}
        .sub-categories {
          .swiper-slide  { .m(0, 12); .mt(20);
            a { .fs(18); .p(4, 11, 6);}
          }
        }
        .lowest-categories { .mt(199);
          [scroll-menu] .swiper-slide + .swiper-slide{.ml(32);}
          .swiper-slide {
            a {.fs(21);}
          }
        }
        .lowest-categories+ul{.pt(48);}
        >ul{column-gap: 33px; .pt(200);
          >li{.mb(33);
            .text{.p(48,40);
              p:first-of-type{.fs(16); .pb(16);
                span{.ml(16);}
              }
              p:last-of-type{.fs(28,1.57,-0.25);}
            }
            .sns-wrap {[sns-share]{.wh(32); .m(19,21);}}
          }
        }
        .view-more{.mt(180);
          a{
            span{.fs(30,1.5);
              &:last-of-type{.ml(18);}
            }
            i{.wh(32); .mr(26); }
          }
        }
      }
    }
  }
}

</style>