import Vue from 'vue'
import Vuex from 'vuex'
import browser from '../utils/browser';
import scrollAgency from '@/plugins/scrollAgency';

Vue.use(Vuex)

export default () => new Vuex.Store({
  state: {
    codes: null,
    images: null,
    theme: 'default',
    fetchingAsyncData: false,
    resetPage: null,
    soundMuted: true,
  },
  mutations: {
    setCodes(state, value) {
      state.codes = Object.freeze(value);
    },
    setImages(state, value) {
      state.images = Object.freeze(value);
    },
    theme(state, val) {
      state.theme = val;
    },
    startFetch(state) {
      state.fetchingAsyncData = true;
    },
    endFetch(state) {
      state.fetchingAsyncData = false;
    },
    resetPage(state, value) {
      state.resetPage = value;
    },
    soundMuted(state, value) {
      state.soundMuted = value;
    },
  },
  actions: {
  },
  getters: {
    isInertia() {
      // return !state.browser.touch;
      return false;
    }
  },
  modules: {
    browser: browser.store,
    scrollAgency: scrollAgency.store
  },
})
