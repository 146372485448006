<template>
  <div module-item :class="info.type">
    <p v-if="info.value.title" class="title" v-html="info.value.title"></p>
    <a v-if="info.value.url" @click="linkTo(info.value.url)">{{ info.value.text }} <i :class="info.value.icon"></i></a>
    <p class="none-btn" v-else>{{ info.value.text }} <i :class="info.value.icon"></i></p>
    <p v-if="info.value.desc" class="desc" v-html="info.value.desc"></p>
  </div>
</template>

<script>

export default {
  name: 'Buttons',
  props: ['value'],
  computed: {
    info() {
      return this.value;
    },
  },
};
</script>

