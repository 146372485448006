import BI18n from './module/BI18n';
import createPlugin from './module/BI18nPlugin';
import LocaleLink from './components/LocaleLink.vue';
import config from './config.json';

const data = {};

export const i18n = new BI18n({ data, config });

export const i18nLoad = async route => {
  const lang = i18n.languageByRouteLocale(route.params.locale);
  if (data[lang]) return;
  const module = await import(`./bundle/${lang}.json`);
  data[lang] = module.default;
};

export default {
  install(Vue) {
    const { directives, prototypes } = createPlugin(i18n);
    Vue.directive('t', directives.common);
    Vue.directive('te', directives.common);
    Vue.directive('tc', directives.common);
    Vue.directive('tv', directives.tv);
    Vue.directive('placeholder', directives.placeholder);
    Vue.prototype.$t = prototypes.i18nT;
    Vue.prototype.$te = prototypes.i18nTe;
    Vue.prototype.$tc = prototypes.i18nTc;
    Vue.prototype.$tv = prototypes.i18nTv;
    Vue.prototype.$fromNow = prototypes.i18nFromNow;
    Vue.prototype.$date = prototypes.i18nDate;
    Vue.prototype.$i18n = prototypes.i18nProps;
    Vue.component('LocaleLink', LocaleLink);
  }
}
