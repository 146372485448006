<template>
  <main news-list>
    <section class="hero">
      <template v-if="lang in values('ko','en')">
        <div class="categories">
          <Slider clickActive centeredSlides slidesPerView="auto" ref="slider"
                  :option="{ on: {slideChangeTransitionStart: changedSlider }}" :initialSlide="activeIndex">
            <div v-for="item in categories" :key="item.code">
              <div class="slider-item">
                <locale-link :to="item.data.route">{{ item.label }}</locale-link>
              </div>
            </div>
          </Slider>
        </div>
      </template>
      <template v-else>
        <h3>NEWS</h3>
      </template>
      <p class="description">{{ description }}</p>
    </section>
    <section class="list">
      <div class="sticky" v-if="subCategories && lang in values('ko','en')">
        <div class="sub-categories">
          <ScrollMenu :key="category">
            <div class="swiper-slide" v-for="item in subCategories" :key="item.code">
              <locale-link :to="item.data.route" :class="{ on: checkOn(item) }">{{ item.label }}</locale-link>
            </div>
          </ScrollMenu>
          <a class="more" @click="tagOpen" :class="tagOpen===true ? 'open' : null"><i></i></a>
        </div>
      </div>
      <div class="lowest-categories" v-if="!isEmpty(lowestCategories)">
        <locale-link v-for="item in lowestCategories" :key="item.code" :to="item.data.route" :class="{ on: checkLowOn(item) }">{{ item.label }}</locale-link>
      </div>
      <ul>
        <li v-for="(item, i) in list" :key="i">
          <a @click="linkTo(item.slug, '/news/article/')">
            <div class="img-wrap">
              <div class="load-news"></div>
              <cdn-img :src="item.image" />
            </div>
            <div class="text">
              <p>{{ formDate(item.publishDtt) }} <span>{{ codeLabel(item.priorityCategory) }}</span></p>
              <p>{{ item.title }}</p>
            </div>
          </a>
        </li>
      </ul>
      <div class="view-more">
        <a @click="getList" v-if="paging.pageNo < paging.pageCount"><i></i><span>VIEW MORE</span> <span>{{ paging.pageNo }}/{{ paging.pageCount }}</span></a>
      </div>
    </section>
    <div class="category-popup" :class="{ open: tagV }" v-if="lang in values('ko','en')">
      <div class="dimed" @click="tagClose"></div>
      <div class="holder">
        <label>{{ codeLabel(code) }}</label>
        <ul>
          <li v-for="item in subCategories" :key="item.code">
            <locale-link :to="item.data.route" :class="{ on: checkOn(item) }">{{ item.label }}</locale-link>
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>

<script>
import oax from '@/utils/oax';
import Slider from '@/views/components/Slider';
import CdnImg from '@/views/components/CdnImg';
import ScrollMenu from '@/views/components/ScrollMenu';

function initialState() {
  return {
    code: null,
    list: null,
    paging: { pageNo: 1 },
    tagV: false,
  };
}
export default {
  name: 'news-list',
  components: {ScrollMenu, CdnImg, Slider },
  data() {
    return initialState();
  },
  watch: {
    $route: 'refresh',
  },
  computed: {
    category() {
      return this.$route.params.category;
    },
    sub() {
      return this.$route.params.sub;
    },
    low() {
      return this.$route.params.low;
    },
    categories() {
      return [{label: 'All', data: { route: `/${this.root}/all` }}, ...this.codeList(this.root)];
    },
    subCategories() {
      if (this.category === 'all') return null;
      let code = this.code?.split('-')?.slice(0,3)?.join('-');
      code = !this.sub || this.sub === 'all' ? code : this.codeParent(code);
      if (this.codeList(code).length < 2) return null; // 서브 카테고리가 1개일 때 안보이게
      return [{label: 'All', data: { route: `/${this.root}/${this.category}/all` }}, ...this.codeList(code)];
    },
    lowestCategories() {
      if (!this.sub || this.sub === 'all') return null;
      const code = !this.low || this.low === 'all' ? this.code : this.codeParent(this.code);
      if (this.codeList(code).length < 2) return null; // 서브 카테고리가 1개일 때 안보이게
      return [{label: 'All', data: { route: `/${this.root}/${this.category}/${this.sub}/all` }}, ...this.codeList(code)];
    },
    activeIndex() {
      return this.categories.findIndex(row => row.data.route.includes(this.category));
    },
    description() {
      if (this.lang in this.values('jp', 'tw', 'vn') || this.category === 'all') return this.codeData(this.root)?.description || '';
      return this.categories.find(row => row.data.route.includes(this.category))?.data?.description || '';
    },
  },
  methods: {
    refresh(to, from) {
      if (to.params.locale !== from.params.locale) {
        Object.assign(this.$data, initialState());
        return;
      }
      this.$refs.slider?.go(this.activeIndex);
    },
    checkOn(item) {
      if (!this.sub) return item.label === 'All';
      return this.$route.path === `${this.$i18n('path')}${item.data.route}`;
    },
    checkLowOn(item) {
      if (!this.low) return item.label === 'All';
      return this.$route.path === `${this.$i18n('path')}${item.data.route}`;
    },
    changedSlider(e) {
      const route = this.categories[e.realIndex].data.route;
      if (!this.$route.path.includes(route)) this.localeLink(route);
    },
    async getList() {
      const { data } = await oax.get(`/api/article/${this.root}`, { lang: this.lang, category: this.code, perPage: 12, pageNo: this.paging.pageNo+1 });
      this.list = [...this.list, ...data.list];
      this.paging = data.paging;
    },
    tagOpen(){
      this.tagV = true;
      this.$scroll.freeze();
    },
    tagClose(){
      this.tagV = false;
      this.$scroll.release();
    }
  },
  mounted() {
    this.$store.commit('theme', 'default');
  },
  async asyncData({route, store}) {
    const lang = route.params.locale || 'ko';
    const path = route.path.replace('/all', '').replace(`/${lang}`, '');
    const category = store.state.codes.find(row => row.data.route === path)?.code;
    if (!category) throw new Error("404");
    const info = (await oax.get(`/api/article/${route.meta.root}`, { lang, category, perPage: 12 })).data;
    if (!info) throw new Error("404");

    return { list :info.list, paging: info.paging, code: category };
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";

[news-list] {.pb(141);
  section {
    &.hero { .pt(132);
      .categories {overflow: hidden; .h(96);
        .swiper-container{ .-box; overflow: visible;
          .swiper-wrapper{align-items: center;
            .swiper-slide { width: auto;
              &.swiper-slide-active .slider-item a{.c(#333);}
              .slider-item {.m(0,24); .min-w(100); .max-w(200);
                a { .tc; .block; .fs(48,48); text-transform:uppercase; .ff-hvd-black; -webkit-text-stroke: 1px #333; .c(#fff); transition:color .4s ease;}
              }
            }
          }
        }
      }
      .description { .mt(10); .medium; .p(0,29); .tc; .fs(16,1.5,-0.4);}
      #app.jp &, #app.tw &, #app.vn & {
        h3 { .m(0,24); .tc; .fs(48,48); .ff-hvd-black; }
      }
    }
    &.list{ .m-m; .proj-max-w; .rel;
      .sticky { position: sticky; .lt; .p(16, 0); .mt(32); .z(2); background: #fff; }
      .sub-categories {.rel; .tc; overflow: hidden;
        .locked{
          .swiper-slide:last-of-type{.mr(0);}
        }
        .swiper-slide { .p;
          &:last-of-type{.mr(75);}
          a {.-a(#333); .fs(14); .p(3, 8, 4); .block; .medium;
            &.on{.bgc(#333); .c(#fff);}
          }
        }
        [scroll-menu].locked .swiper-wrapper { .justify-center; }
        [scroll-menu].locked + .more { .hide; }
        .more{.wh(75,100%); z-index:9; .abs; .rt(0,0); background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 65%);
          i{ .abs; .rt(10,50%); transform:rotate(0deg) translateY(-50%); .wh(16); .bg('/img/common/ic-down-arrow.png'); .contain; .no-repeat;}
        }
      }
      .lowest-categories { .mt(50);
        a { .ib; .-a(#fff); .fs(14); .p(3, 8, 4); .medium;
          &.on {.bgc(#fff); .c(#000);}
        }
      }
      ul{display: grid; grid-template-columns: 1fr; .pt(84);
        li{.rel; .mb(48);
          a{.block;}
          .img-wrap{.mb(20); aspect-ratio: 17/9;overflow: hidden; .rel;
            img{.rel; .f; object-fit: cover; transform:scale(1); transition:transform 1s cubic-bezier(0, 0, 0.13, 1.04);}
          }
          .text{.c(#333);
            p:first-of-type{.fs(12); .pb(10);
              span{.medium; .ib; .ml(8);}
            }
            p:last-of-type{.fs(18,1.61,-0.25); .bold;}
          }
          .no-touch &:hover{
            .img-wrap img{transform:scale(1.1);}
          }
          &:last-of-type{.mb(0);}
        }
      }
      .view-more{.tc; .mt(96);
        a{.rel;
          span{.ib; .vam; .fs(20,1.1); .bold;
            &:last-of-type{.ml(12);}
          }
          i{.wh(24); .ib; .vam; .mr(16); .contain; .no-repeat; .bg('/img/common/ic-black-more.png');}
          &:after{content:''; .abs; .w(0); .h(2); .bgc(#333); .lb(0,-10); transition:width .6s cubic-bezier(0.4,-0.03, 0, 1.03);}
          .no-touch &:hover{
            &:after{.wf;}
          }
        }
      }
    }
  }
  .category-popup{.fix; .lb(0,0); z-index:99; .f; .t-y(-100%); transition:transform 0s ease; transition-delay: 1s; overflow: hidden;
    &.open{transition-delay: 0s; .t-y(0%);
      .dimed{.o(0.65);}
      ul{.t-y(0%);}
    }
    .dimed{.bgc(#000); .o(0); z-index:1; .f; transition:opacity .6s ease;}
    ul{.abs; .lb(0,0); .bgc(#fff); .wf; .-box; .p(18,18,2); z-index:2; .t-y(100%);  transition:transform .6s ease;
      label{.block;.fs(14); .tc; .mb(18); .bold; text-transform:uppercase;}
      li {.ib; .mr(16); .mb(16);
        a {.-a(#333); .fs(14); .p(3, 8, 4); .block; .medium;
          &.on{.bgc(#333); .c(#fff);}
        }
      }
    }
  }
}
@media (min-width: @screen-tp-min) {
  [news-list] {.pb(181);
    section{
      &.hero {
        .categories { .h(114);
          .swiper-container{
            .swiper-wrapper{align-items: normal;
              .swiper-slide {
                .slider-item {.m(0,40); .min-w(200); max-width:none;
                  a { .fs(76,1.5); }
                }
              }
            }
          }
        }
        .description { .mt(4); .p; .mh-c; .w(70%); .fs(16,1.5);}
        #app.jp &, #app.tw &, #app.vn & {
          h3 {.m(0); .fs(76,1.5);}
        }
      }
      &.list{.t-m;
        .sticky { .rel; .p; .mt(60);}
        .sub-categories {
          .swiper-slide { .mt(14); .ib;
            &:last-of-type{.mr(0);}
          }
          .more{.hide;}
        }
        ul{.pt(96); grid-template-columns: 1fr 1fr; column-gap: 18px;
          li{.mb(56);
            &:nth-last-of-type(2){.mb(0);}
          }
        }
      }
    }
    .category-popup{.hide;}
  }
}
@media (min-width: @screen-tl-min) {
  [news-list] {
    section{
      &.hero { .pt(140);
        .categories { .h(140);
          .swiper-container{
            .swiper-wrapper{
              .swiper-slide {
                .slider-item {.m(0,52); .min-w(250);
                  a { .fs(93,1.5); }
                }
              }
            }
          }
        }
        .description { .mt(6);}
        #app.jp &, #app.tw &, #app.vn & {
          h3 {.fs(93);}
        }
      }
      &.list{
        .sticky { .mt(50);}
        .sub-categories {
          .swiper-slide { .mt(14);}
          .more{.hide;}
        }
        ul{.pt(100); grid-template-columns: 1fr 1fr 1fr; column-gap: 14px;
          li{.mb(72);
            &:nth-last-of-type(3){.mb(0);}
          }
        }
        .view-more{.mt(101);}
      }
    }
  }
}
@media (min-width: @screen-dm-min) {
  [news-list] {.pb(283);
    section{
      &.hero { .pt(200);
        .categories { .h(198);
          .swiper-container{
            .swiper-wrapper{
              .swiper-slide {
                .slider-item {.m(0,72); .min-w(370);
                  a {.fs(132,1.5); -webkit-text-stroke: 1.2px #333;}
                }
              }
            }
          }
        }
        .description { .fs(20,1.5,-0.4);}
        #app.jp &, #app.tw &, #app.vn & {
          h3 {.fs(132);}
        }
      }
      &.list{ .pc-m;
        .sticky { .mt(82);}
        .sub-categories {
          .swiper-slide{.mt(14);}
        }
        ul{column-gap: 24px; .pt(140);
          li{.mb(84);}
        }
        .view-more{.mt(143);
          a{
            span{.fs(24,0.92);}
            i{.wh(28); .mr(20); }
          }
        }
      }
    }
  }
}
@media (min-width: @screen-dl-min) {
  [news-list] {.pb(300);
    section{
      &.hero { .pt(256);
        .categories { .h(264);
          .swiper-container{
            .swiper-wrapper{
              .swiper-slide {
                .slider-item {.m(0,96); .min-w(550);
                  a {.fs(176,1.5); -webkit-text-stroke: 1.6px #333;}
                }
              }
            }
          }
        }
        .description { .mt(8); .fs(26,1.5,-0.25);}
        #app.jp &, #app.tw &, #app.vn & {
          h3 {.fs(176);}
        }
      }
      &.list{ .pcl-m;
        .sticky { .mt(112);}
        .sub-categories {
          .swiper-slide { .m(0, 12); .mt(20);
            a { .fs(18); .p(4, 11, 6);}
          }
        }
        ul{column-gap: 33px; .pt(180);
          li{.mb(108);
            .img-wrap{.mb(26);}
            .text{
              p:first-of-type{.fs(16); .pb(13);
                span{.ml(16);}
              }
              p:last-of-type{.fs(24,1.58,-0.25);}
            }
          }
        }
        .view-more{.mt(180);
          a{
            span{.fs(30,1.5);
              &:last-of-type{.ml(18);}
            }
            i{.wh(32); .mr(26); }
          }
        }
      }
    }
  }
}

</style>