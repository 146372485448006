<template>
  <div sns-share :class="[type, on && 'on']">
    <a class="btn" @click.stop="onClick"></a>
    <div class="share-popup">
      <ul>
        <li v-if="lang in values('ko', 'en', 'jp','tw','vn')">
          <a @click.stop="shareFacebook(sharePath)">
            <img v-if="type === 'play-main'" src="/img/common/sns-share/sns-fb-black.png" alt="facebook"/>
            <img v-else src="/img/common/sns-share/sns-fb.png" alt="facebook"/>Facebook
          </a>
        </li>
        <li v-if="lang in values('en')">
          <a @click.stop="shareTwitter(sharePath)">
            <img v-if="type === 'play-main'" src="/img/common/sns-share/sns-twitter-black.png" alt="twitter"/>
            <img v-else src="/img/common/sns-share/sns-twitter.png" alt="twitter"/>Twitter
          </a>
        </li>
        <li v-if="lang in values('en')">
          <a @click.stop="shareReddit(sharePath)">
            <img v-if="type === 'play-main'" src="/img/common/sns-share/sns-reddit-black.png" alt="reddit"/>
            <img v-else src="/img/common/sns-share/sns-reddit.png" alt="reddit"/>Reddit
          </a>
        </li>
        <li v-if="lang in values('ko')">
          <a @click.stop="shareKakao(sharePath, value)">
            <img v-if="type === 'play-main'" src="/img/common/sns-share/sns-kakaotalk-black.png" alt="kakao talk"/>
            <img v-else src="/img/common/sns-share/sns-kakaotalk.png" alt="kakao talk"/>KakaoTalk
          </a>
        </li>
        <li v-if="lang in values('ko', 'en')">
          <a @click.stop="shareLinkedin(sharePath)">
            <img v-if="type === 'play-main'" src="/img/common/sns-share/sns-linked-in-black.png" alt="LinkedIn"/>
            <img v-else src="/img/common/sns-share/sns-linked-in.png" alt="LinkedIn"/>LinkedIn
          </a>
        </li>
        <li v-if="lang in values('ko', 'en', 'jp','tw','vn')">
          <a :href="`mailto:?&body=${this.url(sharePath)}`">
            <img v-if="type === 'play-main'" src="/img/common/sns-share/sns-outlook-black.png" alt="outlook"/>
            <img v-else src="/img/common/sns-share/sns-outlook.png" alt="outlook"/>Email
          </a>
        </li>
        <li v-if="lang in values('ko', 'en', 'jp','tw','vn')">
          <ClipBoard :copy-path="sharePath">
            <img v-if="type === 'play-main'" src="/img/common/sns-share/sns-copy-url-black.png" alt="copy url"/>
            <img v-else src="/img/common/sns-share/sns-copy-url.png" alt="copy url"/>Copy URL
          </ClipBoard>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import ClipBoard from '@/views/components/ClipBoard';
export default {
  name: 'SnsShare',
  components: { ClipBoard },
  props: {
    type: String,
    sharePath: String,
    value: Object,
  },
  data() {
    return {
      on: false,
    };
  },
  methods: {
    onClick() {
      this.on = !this.on;
    },
    documentDown(e) {
      if (e.target.closest('.share-popup')) return;
      this.on = false;
    },
    keyDown(e) {
      if (e.key === "Escape" && this.on) {
        this.on = false;
      }
    },
  },
  mounted() {
    if (typeof document === 'undefined') return;
    document.addEventListener('keydown', this.keyDown);
    document.addEventListener('click', this.documentDown);
  },
  beforeDestroy() {
    if (this.on) this.onClick();
    if (typeof document === 'undefined') return;
    document.removeEventListener('keydown', this.keyDown);
    document.removeEventListener('click', this.documentDown);
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[sns-share] { .rel; .pointer; z-index:1;
  a.btn { .abs; .lt; .f; .o(1); transition:opacity .2s ease;
    .no-touch &:hover{.o(.5);}
  }
  .share-popup { .abs; .w(170);  transform:scale(0); .br(12); .bgc(#fff); .crop; transition: all 0.6s cubic-bezier(0.54,-0.03, 0.06, 0.98);
    ul { .p(20);
      li { .o(0); aspect-ratio: inherit !important;  transition-delay: 0s; .crop; transform: translateY(10px);  transition: opacity .3091s cubic-bezier(0.32, 0.08, 0.24, 1) .03s, transform .3455s cubic-bezier(0.32, 0.08, 0.24, 1) .02s;
        a {  .block; .tl; .fs(14); .medium; color: #888; .bgc; white-space: nowrap; text-decoration: none;
          img { .ib; .wh(38); .mr(12); .vam; }
        }
        #shareUrl { .abs; .lt(-10000, 0); }
      }
      li + li { .mt(10); }
    }
  }
  &.on {
    .share-popup { transform:scale(1); box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
      ul li { .o(1); transform: translateY(0px); }
      li:nth-of-type(1) { transition-delay: 0.2s; }
      li:nth-of-type(2) { transition-delay: 0.3s; }
      li:nth-of-type(3) { transition-delay: 0.4s; }
      li:nth-of-type(4) { transition-delay: 0.5s; }
      li:nth-of-type(5) { transition-delay: 0.6s; }
      li:nth-of-type(6) { transition-delay: 0.7s; }
    }
  }
  &.play-main {
    .share-popup { .rt(0, 25); .bgc(#333); transform-origin:right top;
      ul{ li a{.c(#ddd);}}
    }
  }
  &.news-article {
    .share-popup { .rt(-182, 0); .-a(#ebebeb); transform-origin:left top;}
  }
}

</style>
