<template>
  <div module-item :class="info.type">
    <div class="title">
      <i></i>
      <h5 v-html="enterToBr(info.value.title)"></h5>
    </div>
    <div class="body-text" v-html="info.value.text"></div>
  </div>
</template>

<script>

export default {
  name: 'TitleText',
  props: ['value'],
  computed: {
    info() {
      return this.value;
    },
  },
}
</script>

