<template>
  <div module-item :class="info.type">
    <div class="left">
      <cdn-img :src="info.value.imageLeft.pc" filekey="news" class="hidden-ml-down"/>
      <cdn-img :src="info.value.imageLeft.mobile" filekey="news" class="hidden-tp-up"/>
    </div>
    <div class="right">
      <cdn-img :src="info.value.imageRight.pc" filekey="news" class="hidden-ml-down"/>
      <cdn-img :src="info.value.imageRight.mobile" filekey="news" class="hidden-tp-up"/>
    </div>
    <div class="desc" v-if="info.value.desc" v-html="enterToBr(info.value.desc)"></div>
  </div>
</template>

<script>
import CdnImg from '@/views/components/CdnImg';

export default {
  name: 'DoubleImage',
  components: { CdnImg },
  props: ['value'],
  computed: {
    info() {
      return this.value;
    },
  },
};
</script>

