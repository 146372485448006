<template>
  <div id="app" :class="[lang, theme, touch ? 'touch' : 'no-touch', fetching, roll]">
    <AppHeader/>
    <scroll-holder container-class="scroll-container" body-class="scroll-body">
      <router-view />
      <AppFooter/>
    </scroll-holder>
    <div class="fixed-holder"></div>
    <Cookie v-if="false"/>
  </div>
</template>

<script>
import AppHeader from '@/views/common/AppHeader';
import AppFooter from '@/views/common/AppFooter';
import { i18n } from '@/plugins/i18n';
import Cookie from '@/views/common/Cookie';
import oax from '@/utils/oax';
import _ from 'lodash';

export default {
  name: 'App',
  components: { Cookie, AppHeader, AppFooter },
  data() {
    return {
      topStyle: false,
    };
  },
  metaInfo() {
    return {
      title: this.$t('meta.title'),
      meta: [
        { vmid: 'title', content: this.$t('meta.title'), },
        { vmid: 'description', content: this.$t('meta.description'), },
        { vmid: 'keywords', content: this.$t('meta.keywords'), },
        { vmid: 'image', content: `${process.env.VUE_APP_HOST}/img/og.png`, },
        { vmid: 'path', content: this.$route.path.replace(new RegExp(`^/(${i18n.routeMatches()})`), '') },
      ],
    }
  },
  computed: {
    theme() { return this.$store.state.theme; },
    fetching() { return this.$store.state.fetchingAsyncData && 'fetching'; },
    roll() {
      return this.$store.state.browser.scrollDirection === 'down' && 'roll'; //&& this.$scroll.getScrollTop() > 0 && 'roll';
    },
  },
  watch: {
    async $route(to, from) {
      if (to.params.locale !== from.params.locale) {
        const codes = (await oax.get('/api/code/full', { lang: this.$i18n('lang') })).data;
        this.$store.commit('setCodes', _.forEach(codes, row => row.data = JSON.parse(row.data)));
      }
    }
  },
  mounted() {
    this.resizeHeight();
    this.$scroll.addListener(this.onScroll);
  },
  methods: {
    onScroll() {
      if (this.matchedMedia in this.values('MS','MM','ML')) {
        // const { height } = this.$el.querySelector('[footer]').getBoundingClientRect();
        this.topStyle = this.scrollTop + window.innerHeight > document.body.scrollHeight - 50;
      }
    },
    resizeHeight(){
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      window.addEventListener('resize', () => {
        const resizeVh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${resizeVh}px`)
      });
    }
  },
}
</script>

<style lang="less">
@import "~@/less/common";

.loading { .flex-center; .fix; .lt; .f;
  i { .block; .wh(80);
    border: 3px solid rgba(0,0,0,.3);
    border-top-color: #000;
    border-radius: 50%;
    animation: spin 1s ease-in-out infinite;
    .dark & {
      border: 3px solid rgba(255,255,255,.3);
      border-top-color: #fff;
    }
  }
}

@keyframes spin {
  to { transform: rotate(360deg); }
}
</style>
