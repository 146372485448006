<template>
  <div>
    <h1>NC PLAYの運営ポリシー</h1>
    <p>
      <s>NC PLAYの運営ポリシー</s><br>
      施行日：2022年08月12日<br>
      <br>
      <br>
      <s>1．NC PLAYの運営目標</s><br>
      株式会社NCSOFT（以下、「NC」）は、NC PLAY（https://about.ncsoft.com）を通して、当社にまつわる様々なお話をお届けすることを目指しています。NCの企業哲学と力量を盛り込んだコンテンツをデジタルに最適化した形で提供し、ユーザーの皆様に新しいブランド体験を提供するために努めてまいります。<br>
      NC PLAYの運営チームは、ユーザーの皆様に楽しく有意義な体験を提供できるよう、良質のコンテンツ制作に取り組んでまいります。<br>
      <br>
      <br>
      <br>
      <s>2．NC PLAYの利用</s><br>
      ① NC PLAYで提供されるすべての情報は、NCまたは第三者にとって大切な資産です。サイト訪問者はNC PLAYに自由にアクセスし、閲覧することができますが、それ以外の方法でコンテンツを利用する場合や外部に共有する際には、NCの「コンテンツの利用に関するご案内」を遵守しなければなりません。<br>
      ② NC PLAYのコンテンツは、NC PLAYへの掲載を目的として作者から提供された投稿や情報を含みます。NC PLAYのコンテンツは、NCの公式の意見とは異なる場合があります。なお、これはNCの意見を代表するものではありません。<br>
      ③ NC PLAYには、コンテンツの内容に関するその他のサイトのリンクが含まれています。NCは、リンクされているサイトやNC PLAYから提供されるすべての情報、提言、または広告について保証しません。<br>
      <br>
      <br>
      <s>3．プライバシー保護</s><br>
      ① NC PLAYのサイト訪問者は、個人情報を記入することなく、NC PLAYに掲載された情報やニュース、投稿を自由に閲覧、購読することができます。<br>
      ② サイト訪問者がNC PLAYにアクセスする際に、ユーザーのブラウザから送られたサーバーログ情報が自動保存される場合があります。このサーバーログは、NC PLAYのサイト訪問者のウェブリクエスト、アクセスしたサイトのアドレス、ブラウザタイプと言語、アクセスまたは投稿日時、Cookie等、ユーザーのブラウザを識別する情報を含めています。NCは、これらをNC PLAYの改善のために使用する場合があります。<br>
      ③ NC PLAYはサイト訪問者、またはサイト訪問者のコンピューター情報をウェブフォームの「Cookie」を通して収集、保存することができます。Cookieは、任意の文字が書かれた小さなテキストファイルで、初訪問時にNC PLAYのサイト訪問者のブラウザを識別し、サイト訪問者のコンピューターに保存されます。Cookieは、サイト訪問者がNC PLAYをより効率よく使用できるようにするためのものです。ほとんどのブラウザは、Cookieを適用するように設定されていますが、サイト訪問者はブラウザの再設定を直接行うことで、すべてのCookieの設定をブロックするか、Cookieを送信する際にその旨を明示するように調整することができます。ただし、Cookieが機能しない場合、または削除された場合はNC PLAYのサービスが通常通りに動かない場合があります。<br><br>
      (1) Cookie等の使用目的<br>
      サイト訪問者のアクセス頻度や閲覧時間等を分析し、ユーザーの好みや興味、導線、アクセス数を把握して、個人向けのカスタマイズサービスを提供します。ユーザーがウェブやモバイルサービスにアクセスした記録と活動ログは、人口統計学的な特性をベースに性別、年代、関心事を推定し、それらに基づいたカスタマイズ広告を表示するためにINCROSS Co., LTD、Newtype Imageworksに提供される場合があります。<br><br>
      (2) Cookieのブロック設定について<br>
      サイト訪問者は、Cookieのインストールに関する権利を有します。そのため、ユーザーはウェブブラウザでオプションを設定することで、すべてのCookieの保存を許可するか、Cookieが保存されるたびに確認を経るか、すべてのCookieの保存をブロックするかを選択することができます。ただし、Cookieの保存をブロックした場合、一部のサービスの提供に影響が生じることがあります。<br><br>
      [Cookieの設定方法]<br>
      ①Internet Explorer：ウェブブラウザ上部の[ツール] → [インターネットオプション] → [プライバシー] → [詳細設定]<br>
      ②Chrome：ウェブブラウザの右上の[⋮] → [設定] → [プライバシーとセキュリティ] → [Cookieとその他のサイトデータ]<br>
      <br>
      <br>
      <s>4．運営ポリシーの変更</s><br>
      本NC PLAYの運営ポリシーは変更される場合があります。NCは、本NC PLAYの運営ポリシーを変更する際に、その旨をお知らせします。変更されたNC PLAYの運営ポリシーの内容は、有効な日付別にページ上部に掲載されます。<br>
      <br>
      <br>
      <br>
      <s>コンテンツの利用に関するご案内</s><br>
      施行日：2022年08月12日<br>
      <br>
      <br>
      NC PLAYが直接制作したすべてのコンテンツは、その原本を編集、変形、加工しない範囲内で、プレスリリース及び学術目的で活用することができます。コンテンツを利用する際は出典を必ず記載してください。<br><br>

      ただし、外部から提供された一部のコンテンツにおいては、その使用と共有が制限されています。動画、画像、文書等のコンテンツの下部に作者名が別途表示されている場合、NC PLAYへの掲載を目的として作者から提供されたコンテンツであることを意味します。そのため、それらのコンテンツを共有もしくは使用する場合は、作者に別途使用許可を受ける必要があります。<br><br>

      NC PLAYのコンテンツを活用した二次創作を企画する場合、またはその活用に関する追加のお問い合わせがある場合は、NC PLAY運営チームまでご連絡ください。<br><br>

      NC PLAY運営チーム：ncsoft_brandcommunicationteamd@ncsoft.com
    </p>
  </div>
</template>

<script>

export default {
  name: 'PolicyJP',
};
</script>



<style lang="less">
@import "~@/less/proj.less";
[policy]{.p(140,0); .m-m;
  h1{.pb(72); .fs(28,1.5,-0.25); .bold; .tc; .c(#1e1e1e);}
  p{.fs(16,1.94,-0.25); .c(#555);
    s{.bold; .c(#333);}
  }
}
@media (min-width: @screen-tp-min) {
  [policy]{.p(132,0,180);
    h1{.pb(84);}
    p{.w(480); .mh-c;}
  }
}
@media (min-width: @screen-tl-min) {
  [policy]{.p(144,0,200);
    h1{.pb(96); .fs(36,1.5,-0.25);}
    p{.w(650); .fs(18,1.89,-0.25);}
  }
}
@media (min-width: @screen-dm-min) {
  [policy]{.p(166,0,280);
    h1{.pb(120); .fs(44,1.5,-0.25);}
  }
}
@media (min-width: @screen-dl-min) {
  [policy]{.p(204,0,300);
    h1{.pb(132); .fs(48,1.5,-0.25);}
  }
}
</style>
