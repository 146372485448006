<template>
  <popup novel-popup>
    <main play-viewer :class="[type, moreOpen ? 'more-open' : '']" v-if="apiData">
      <div class="viewer">
        <Component :is="comp" :content="content" :key="`${type}-${apiData.sq}`" @full="fullScreen"></Component>
        <div class="info" :class="{hide: infoHide}">
          <div class="inner">
            <div class="text">
              <p>{{ codeLabel(code) }}</p>
              <p>{{ enterToBr(info.title) }}</p>
            </div>
            <a @click="moreOpen=true">
              <span v-t:viewMore></span>
              <i></i>
            </a>
          </div>
        </div>
        <a class="close" @click="close"></a>
      </div>
      <div class="more-info">
        <div class="inner">
          <a class="more-close" @click="moreOpen=false"></a>
          <p class="depth">{{ codeLabel(code) }}</p>
          <p class="title" v-html="enterToBr(info.title)"></p>
          <p class="content" v-html="enterToBr(info.content)"></p>
          <a class="link" :href="info.link" :target="info.target === 'Yes' ? '_blank' : '_self'" v-if="info.link"><span>{{ info.linkText }}</span><i></i></a>
        </div>
      </div>
    </main>
  </popup>
</template>

<script>
import Popup from '@/views/components/popup/Popup';
import oax from '@/utils/oax';
import PlayShorts from '@/views/components/PlayShorts';
import PlayWide from '@/views/components/PlayWide';

export default {
  name: 'PlayNovelPopup',
  components: { Popup, PlayWide, PlayShorts },
  data() {
    return {
      apiData: null,
      moreOpen:false,
      infoHide:false,
    };
  },
  props: {
    slug: String,
  },
  computed: {
    type() {
      //console.log(this.apiData.type);
      return this.apiData.type;
    },
    comp() {
      return this.type === 'play-shorts' ? 'PlayShorts' : 'PlayWide';
    },
    info() {
      return this.apiData.info;
    },
    content() {
      return this.apiData.content;
    },
    code() {
      return this.resetPage?.code || this.apiData.priorityCategory;
    },
    resetPage() {
      return this.$store.state.resetPage;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    fullScreen() {
      if (!this.moreOpen) this.infoHide = !this.infoHide;
    },
    async getData() {
      const apiData = (await oax.get(`/api/article/play-novel/${this.slug}`, { lang: this.lang })).data;

      const contents = JSON.parse(apiData.contents);
      apiData.info = contents.info;
      apiData.content = contents.content;

      this.apiData = apiData;

      //console.log(this.apiData);
    },
  },
  mounted() {
    this.getData();
  },
};
</script>