<template>
  <div module-item :class="info.type">
    <div class="profile-img">
      <cdn-img :src="info.value.image" filekey="news"/>
    </div>
    <div class="text-area">
      <p class="name" v-html="info.value.author"></p>
      <div class="text hidden-tp-down" v-if="info.value.text" v-html="info.value.text"></div>
    </div>
    <div class="text hidden-tl-up" v-if="info.value.text" v-html="info.value.text"></div>
  </div>
</template>

<script>

import CdnImg from '@/views/components/CdnImg';
export default {
  name: 'Author',
  components: { CdnImg },
  props: ['value'],
  computed: {
    info() {
      return this.value;
    },
  },
};
</script>

