<template>
  <div module-item :class="info.type">
    <div class="name" v-html="enterToBr(info.value.writer)"></div>
    <div class="sum" v-html="enterToBr(info.value.position)"></div>
    <div class="body-text" v-html="enterToBr(info.value.text)"></div>
    <hr/>
  </div>
</template>

<script>

export default {
  name: 'Profile',
  props: ['value'],
  computed: {
    info() {
      return this.value;
    },
  },
}
</script>

