<template>
  <main play-main>
    <p-section class="hero">
      <div class="screen" v-prx:sticky>
        <div class="holder">
          <p-item class="bg" v-prx:progress="{ r:[5,12], o:[0,1] }">
            <cdn-img :src="hero.bgPc" class="hidden-ml-down" v-if="matchMediaType(hero.bgPc, 'image')"/>
            <cdn-video :src="hero.bgPc" loop autoplay muted playsinline class="hidden-ml-down" v-if="matchMediaType(hero.bgPc, 'video')"/>
            <cdn-img :src="hero.bgMobile" class="hidden-tp-up" v-if="matchMediaType(hero.bgMobile, 'image')"/>
            <cdn-video :src="hero.bgMobile" loop autoplay muted playsinline class="hidden-tp-up" v-if="matchMediaType(hero.bgMobile, 'video')"/>
          </p-item>
          <p-item class="visual" v-prx:progress="{ r:[1,12], s:[1,.63] }">
            <img class="load-img" src="/img/common/play-loading-img-wide.png" alt="loading image"/>
            <Component :is="hero.url ? 'a' : 'div'" @click="linkTo(hero.url)">
              <cdn-img :src="hero.kvPc" class="hidden-ml-down" v-if="matchMediaType(hero.kvPc, 'image')"/>
              <cdn-video :src="hero.kvPc" loop autoplay muted playsinline class="hidden-ml-down" v-if="matchMediaType(hero.kvPc, 'video')"/>
              <cdn-img :src="hero.kvMobile" class="hidden-tp-up" v-if="matchMediaType(hero.kvMobile, 'image')"/>
              <cdn-video :src="hero.kvMobile" loop autoplay muted playsinline class="hidden-tp-up" v-if="matchMediaType(hero.kvMobile, 'video')"/>
            </Component>
          </p-item>
          <p-item class="title" v-prx:progress="{ r:[1,12], ty:[0,-100], unit: '%', o:[1,0] }">
            <div class="inner">
              <h2>{{ hero.title }}</h2>
              <p v-html="hero.subTitle"></p>
            </div>
          </p-item>
        </div>
      </div>
    </p-section>
    <section class="highlights">
      <h3>PLAY HIGHLIGHTS</h3>
      <Slider scrollbar navigation slidesPerView="auto" :option="{
              slidesPerGroup: 1,
              breakpoints: {
                768: { slidesPerGroup: 2 },
                1280: { slidesPerGroup: 3 }
              }
            }" :key="`highlights-${lang}`">
        <div v-for="item in highlights" :key="item.sq">
          <div class="slider-item" :class="{'has-video': item.video}" @mouseenter="over" @mouseleave="out">
            <div class="inner" @click="routeViewer(item)">
              <img class="load-img" src="/img/common/play-loading-img-h.png" alt="loading image"/>
              <cdn-video :src="item.video" muted playsinline class="over-video" v-if="item.video && !touch"/>
              <cdn-img :src="item.image"/>
              <p v-html="enterToBr(item.txt)"></p>
            </div>
            <div class="sns-wrap">
              <SnsShare type="play-main" :value="item" :share-path="getSlugUrl(item)"></SnsShare>
            </div>
          </div>
        </div>
      </Slider>
    </section>
    <section class="categories">
      <h3>CATEGORIES</h3>
      <ul>
        <li v-for="(item, i) in categories" :key="i"><a @click="linkTo(item.url)">{{ item.text }}</a></li>
      </ul>
    </section>
  </main>
</template>

<script>
import oax from '@/utils/oax';
import CdnImg from '@/views/components/CdnImg';
import CdnVideo from '@/views/components/CdnVideo';
import Slider from '@/views/components/Slider';
import SnsShare from '@/views/components/SnsShare';

export default {
  name: 'playMain',
  components: { SnsShare, Slider, CdnVideo, CdnImg, },
  data() {
    return {
      info: null,
    };
  },
  computed: {
    hero() {
      return this.info.hero;
    },
    highlights() {
      return this.info.highlights;
    },
    categories() {
      return this.info.categories;
    },
  },
  methods: {
    over(e) {
      if (this.touch) return;
      const video = e.target.querySelector('.over-video');
      if (this.isEmpty(video)) return;
      if (!this.videoIsPlaying(video)) video.play();
    },
    out(e) {
      if (this.touch) return;
      const video = e.target.querySelector('.over-video');
      if (this.isEmpty(video)) return;
      if (this.videoIsPlaying(video)) video.pause();
      video.currentTime = 0;
    },
    routeViewer(item) {
      this.$store.commit('resetPage', { code: item.category, url: this.$route.path });
      this.localeLink(this.getSlugUrl(item));
    },
  },
  mounted() {
    this.$store.commit('theme', 'dark');
  },
  created() {
    this.info.highlights.forEach(item => {
      item.video = this.matchMediaType(JSON.parse(item.contentEncode === 'base64' ? decodeURIComponent(atob(item.contents)) : item.contents)?.content[0], 'video');
    });
  },
  async asyncData({route}) {
    const info = (await oax.get('/api/main/play', { lang: route.params.locale || 'ko' })).data;
    if (!info) throw new Error("404");

    return { info };
  }
};

</script>

<style lang="less">
@import "~@/less/proj.less";

[play-main] { .pb(140);
  section { .rel;
    h3{.fs(24); .bold;}
    &.hero { height: 200vh;
      .screen { .lt; .w(100%); .h(100vh); .-box; }
      .holder { .rel; .f;}
      img, video { .f; object-fit: cover; }
      .bg { .abs; .lt; .f; }
      .visual { .abs; .lt; .f;
        > div { .rel; .f; }
        a{.block; .f; .rel;}
      }
      .title { .abs; .lb(0, 95); .wf; .tc; pointer-events: none;
        .inner{.w(90%); .mh-c;
          h2 { .fs(30,1.44,-0.5); .bold;}
          p { .mt(15); .fs(14,1.5,-0.3); .regular; }
        }
      }
    }
    &.highlights {.m-m; .mt(120); .rel; .proj-max-w; .wf; overflow-x:hidden; .-box;
      h3{.pb(24);}
      .swiper-container{overflow: visible;
        .swiper-wrapper{
          .swiper-slide {.wh(100%, 542); .max-w(325); .mr(12);
            &:last-child { .m; }
            .slider-item {.rel; .f; .-box; cursor: pointer;
              .inner {.rel; .f; overflow: hidden;
                [cdn-img] { .rel; .f; object-fit: cover; transform: scale(1); transition: transform 1s cubic-bezier(0, 0, 0.13, 1.04), opacity .6s ease; }
                .over-video { .abs; .lt; .f; transform: scale(1); transition: transform 1s cubic-bezier(0, 0, 0.13, 1.04);}
                p { .abs; .lt; .f; .flex-center; transform: scale(1); text-transform: uppercase; .ff-hvd-black; .fs(68, 0.99); .tc; transition: transform 1s cubic-bezier(0, 0, 0.13, 1.04), opacity .6s ease;}
              }
              .sns-wrap {.abs; .lt(0, 0); .wf; .h(70); background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
                [sns-share] {.wh(24); .m(12, 26); z-index: 9; .abs; .rt(0, 0);
                  a.btn {.bg('/img/common/ic-share-more.png'); .no-repeat; .contain;}
                }
              }
              .no-touch &:hover {
                [cdn-img], [cdn-video] {transform: scale(1.1);}
                &.has-video [cdn-img] { .o(0); }
                p {.o(0); transform: scale(0.95);}
              }
            }
          }
        }
      }
      .swiper-scrollbar { .wf; .h(2); .bgc(#3e3e3e); .mt(40);
        .swiper-scrollbar-drag{.bgc(#fff);}
      }
      .swiper-navigation{.hide;}
    }
    &.categories{.pt(160); .m-m; .proj-max-w;
      h3{.pb(32);}
      ul{
        li {.mr(32);.ib;
          &:last-of-type{.mr(0);}
          a{.ff-hvd-black; -webkit-text-stroke: 1px #fff; .c(#000); .fs(50,66);  transition:color .4s ease;
            .no-touch &:hover{.c(#fff);}
          }
        }
      }
    }
  }
}

@media (min-width: @screen-tp-min) {
  [play-main] {.pb(160);
    section{
      &.hero{
        .title{.lb(0,56);
          .inner{.w(65%);
            h2{.fs(24,1.5,-0.25);}
            p{.mt(8); .fs(12,1.5,-0.25);}
          }
        }
      }
      &.highlights {.t-m; .mt(96);
        h3{.pb(22);}
        .swiper-container{
          .swiper-wrapper{
            .swiper-slide{.wh(213,355); max-width: none; .mr(16);
              .slider-item{
                .inner{
                  p{.fs(44,0.98);}
                }
                .sns-wrap{[sns-share]{.wh(20); .m(12,28);}}
              }
            }
          }
        }
        .swiper-scrollbar { .hide;}
        .swiper-navigation{.block; .abs; .rt(36,8);
          a{.rel; .wh(24); .ib; .vat; .lt(0); .t-xy(0,0); .m; .contain; .no-repeat; .bgc; .c; transition:opacity .2s ease;
            .no-touch &:hover{.o(.6);}
            &:after{.hide;}
          }
          .swiper-button-prev{.mr(20); .bg('/img/common/ic-swipe-prev.png');}
          .swiper-button-next{.bg('/img/common/ic-swipe-next.png');}
        }
      }
      &.categories{.pt(116); .t-m;
        h3{.pb(48);}
        ul{
          li {.mr(36);
            a{.fs(68,84);}
          }
        }
      }
    }
  }
}

@media (min-width: @screen-tl-min) {
  [play-main] {.pb(200);
    section{
      &.hero{
        .title{.lb(0,72);
          .inner{
            h2{.fs(32,1.5,-0.25);}
            p{.mt(12); .fs(14,1.5,-0.25);}
          }
        }
      }
      &.highlights {.mt(108);
        h3{.pb(24);}
        .swiper-container{
          .swiper-wrapper{
            .swiper-slide{.wh(250,418);
              .slider-item{
                .inner{
                  p{.fs(52,0.98);}
                }
                .sns-wrap{[sns-share]{.m(12,28);}}
              }
            }
          }
        }
      }
      &.categories{.pt(118);
        h3{.pb(48);}
        ul{
          li {.mr(56);
            a{.fs(88,106);}
          }
        }
      }
    }
  }
}

@media (min-width: @screen-dm-min) {
  [play-main] {.pb(260);
    section{
      h3{.fs(26);}
      &.hero{
        .title{.lb(0,96);
          .inner{
            h2{.fs(39,1.5,-0.25);}
            p{.mt(16); .fs(20,1.5,-0.25);}
          }
        }
      }
      &.highlights {.pc-m; .mt(160);
        h3{.pb(36);}
        .swiper-container{
          .swiper-wrapper{
            .swiper-slide{.wh(353,588); .mr(24);
              .slider-item{
                .inner{
                  p{.fs(74,0.99);}
                }
                .sns-wrap{[sns-share]{.wh(24); .m(16,40);}}
              }
            }
          }
        }
        .swiper-navigation{.rt(48,0);
          a{ .wh(32);}
          .swiper-button-prev{.mr(24);}
        }
      }
      &.categories{.pt(180); .pc-m;
        h3{.pb(60);}
        ul{
          li {.mr(80);
            a{.fs(125,145);}
          }
        }
      }
    }
  }
}
@media (min-width: @screen-dl-min) {
  [play-main] {.pb(280);
    section{
      h3{.fs(36);}
      &.hero{
        .title{.lb(0,120);
          .inner{
            h2{.fs(54,1.5,-0.25);}
            p{.mt(24); .fs(26,1.5,-0.25);}
          }
        }
      }
      &.highlights {.pcl-m; .mt(180);
        h3{.pb(48);}
        .swiper-container{
          .swiper-wrapper{
            .swiper-slide{.wh(371,665); .mr(28);
              .slider-item{
                .inner{
                  p{.fs(84,0.99);}
                }
                .sns-wrap{[sns-share]{.wh(32); .m(19,49);}}
              }
            }
          }
        }
        .swiper-navigation{.r(60);
          a{.wh(42);}
          .swiper-button-prev{.mr(32);}
        }
      }
      &.categories{.pt(200); .pcl-m;
        h3{.pb(72);}
        ul{
          li {.mr(104);
            a{.fs(167,194);}
          }
        }
      }
    }
  }
}
@media (min-width: @screen-dx-min) {
  [play-main] {
    section{
      &.hero{
        .title{
          .inner{
            h2{.fs(68,1.5,-0.25);}
          }
        }
      }
      &.highlights {
        .swiper-container{
          .swiper-wrapper{
            .swiper-slide{.wh(498,885); .mr(34);
              .slider-item{
                .sns-wrap{[sns-share]{.m(19,54);}}
              }
            }
          }
        }
        .swiper-navigation{
          a{.wh(42);}
          .swiper-button-prev{.mr(32);}
        }
      }
      &.categories{.pt(200); .pcl-m;
        h3{.pb(72);}
        ul{
          li {.mr(120);
            a{.fs(167,194);}
          }
        }
      }
    }
  }
}

</style>
