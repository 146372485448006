export default {
  characterTitle: {
    MS: { r:[3,6], ty:[50,0], o:[0,1] },
    TP: { r:[3,6], ty:[50,0], o:[0,1] },
  },
  characterDesc: {
    MS: { r:[5,8], ty:[50,0], o:[0,1] },
    TP: { r:[5,8], ty:[50,0], o:[0,1] },
  },
  characterName: {
    MS: { r:[3,6], ty:[50,0], o:[0,1] },
  },
  characterImg: {
    MS: { r:[4,7], ty:[20,0], s:[0.9,1], o:[0,1] },
  },
  characterTag: {
    MS: { r:[5,8], ty:[50,0], o:[0,1] },
  },
  collabo: {
    MS: { r:[3,6], ty:[50,0], o:[0,1] },
    TP: v => ({ r:[1+v,4+v], ty:[50,0], o:[0,1] }),
  },
  collaboImg: {
    MS: { r:[4,7], ty:[20,0], s:[0.9,1], o:[0,1] },
    TP: v => ({ r:[2.5+v,5.5+v], ty:[50,0], s:[0.9,1], o:[0,1] }),
  },
  kakaoIcon:{
    MS: { r:[1,4], ty:[50,0], o:[0,1] },
    TP: { r:[1,4], ty:[50,0], o:[0,1] },
  },
};
