<template>
  <div scroll-menu :class="{ locked }">
    <Slider usePlain slides-per-view="auto" :breakpoints="{768:{allowTouchMove: false}}" watch-overflow @initialized="init">
      <slot />
    </Slider>
  </div>
</template>

<style lang="less">
@import "~@/less/proj.less";

[scroll-menu] { z-index:9;
  .swiper-container { overflow: visible; width:auto; }
  .swiper-slide { width: auto; .medium; .pointer;}
  .swiper-slide + .swiper-slide { .ml(16); }

   .plain { .hide; }
}
@media (min-width: @screen-tp-min) {
  [scroll-menu] {.w(70%); .max-w(1400); .mh-c;
    .swiper-wrapper{ .hide;
      .swiper-slide{.ib;}
    }

    .plain { .block; }
  }
}

@media (min-width: @screen-dm-min) {
  [scroll-menu] {  }
}
</style>

<script>
import Slider from '@/views/components/Slider';

export default {
  name: 'ScrollMenu',
  components: { Slider },
  data() {
    return {
      breakPoint: { 1440: { allowTouchMove: false } },
      locked: false,
    };
  },
  methods: {
    init(swiper) {
      this.locked = swiper.isLocked;
    },
  },
};
</script>
