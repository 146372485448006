import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';

const page = path => () => import(`@/views/pages${ path }.vue`);

Vue.use(VueRouter);
Vue.use(Meta);


const play_routes = [
  {
    path: 'play',
    meta: { root: 'play' },
    component: page('/play-main'),
  },
  {
    path: 'play/article/:slug',
    meta: { root: 'play' },
    component: page('/news-article'),
  },
  {
    path: 'play/viewer/:slug',
    meta: { root: 'play' },
    component: page('/play-viewer'),
  },
  {
    path: 'play/:category',
    meta: { root: 'play' },
    component: page('/play-list'),
  },
  {
    path: 'play/:category/:sub',
    meta: { root: 'play' },
    component: page('/play-list'),
  },
  {
    path: 'play/:category/:sub/:low',
    meta: { root: 'play' },
    component: page('/play-list'),
  },
];

const news_routes = [
  {
    path: 'news',
    meta: { root: 'news' },
    component: page('/news-main'),
  },
  {
    path: 'news/article/sample',
    meta: { root: 'news' },
    component: page('/news-sample'),
  },
  {
    path: 'news/article/:slug',
    meta: { root: 'news' },
    component: page('/news-article'),
  },
  {
    path: 'news/:category',
    meta: { root: 'news' },
    component: page('/news-list'),
  },
  {
    path: 'news/:category/:sub',
    meta: { root: 'news' },
    component: page('/news-list'),
  },
  {
    path: 'news/:category/:sub/:low',
    meta: { root: 'news' },
    component: page('/news-list'),
  },
];

const news_shortly_routes = [
  {
    path: 'news',
    meta: { root: 'news' },
    component: page('/news-list'),
  },
  {
    path: 'news/article/:slug',
    meta: { root: 'news' },
    component: page('/news-article'),
  },
];
const common_routes = [
  {
    path: 'blog-operating-policy',
    component: page('/policy'),
  },
  {
    path: 'play-novel',
    component: page('/play-novel'),
  },
  {
    path: 'search',
    component: page('/search'),
  },
];


export default () => new VueRouter({
  mode: 'history',
  scrollBehavior: () => null,
  routes: [
    {
      path: `/`,
      component: page('/language-root'),
      children: [
        {
          path: '/',
          redirect: 'play',
        },
        ...play_routes,
        ...news_routes,
        ...common_routes,
      ],
    },
    {
      path: `/:locale(en)?`,
      component: page('/language-root'),
      children: [
        {
          path: '/',
          redirect: 'play',
        },
        ...play_routes,
        ...news_routes,
        ...common_routes,
      ],
    },
    {
      path: `/:locale(jp)?`,
      component: page('/language-root'),
      children: [
        {
          path: '/',
          redirect: 'play',
        },
        ...play_routes,
        ...news_shortly_routes,
        ...common_routes,
      ],
    },
    {
      path: `/:locale(tw)?`,
      component: page('/language-root'),
      children: [
        {
          path: '/',
          redirect: 'news',
        },
        ...news_shortly_routes,
        ...common_routes,
      ],
    },
    // {
    //   path: '/test',
    //   component: page('/parallax-test'),
    // },
  ],
})
