<template>
  <div footer :class="theme">
    <div class="inner">
      <a class="go-top" @click="goTop">
        <span></span>
        <span></span>
      </a>
      <div class="map">
        <div class="family" :class="sitemapOpen === true ? 'active' : null">
          <a @click="sitemapOpen = !sitemapOpen">FAMILY SITE<i></i></a>
          <div class="menus">
            <ul v-if="lang in values('ko')">
              <li><a href="https://kr.ncsoft.com/kr/index.do" target="_blank">NCSOFT</a></li>
              <li><a href="https://www.ncdinos.com" target="_blank">NC Dinos</a></li>
              <li><a href="https://www.ncfoundation.or.kr/main" target="_blank">NC Cultural Foundation</a></li>
              <li><a href="https://www.plaync.com/kr/index" target="_blank">PLAYNC</a></li>
              <li><a href="https://careers.ncsoft.com" target="_blank">NCSOFT Career</a></li>
              <li><a href="https://ncsoft.github.io/ncresearch/" target="_blank">NC RESEARCH</a></li>
              <li><a href="https://ncruitingblog.com/" target="_blank">NC RECRUITING BLOG</a></li>
            </ul>
            <ul v-if="lang in values('en')">
              <li><a href="https://kr.ncsoft.com/en/index.do" target="_blank">NCSOFT</a></li>
              <li><a href="https://www.ncfoundation.or.kr/en/main" target="_blank">NC Cultural Foundation</a></li>
              <li><a href="https://www.plaync.com/global/index?locale=en-NC" target="_blank">PLAYNC</a></li>
              <li><a href="https://us.ncsoft.com/en-us" target="_blank">NC West</a></li>
              <li><a href="https://www.arena.net/en" target="_blank">ArenaNet</a></li>
              <li><a href="https://www.ncjapan.co.jp/" target="_blank">NC Japan</a></li>
              <li><a href="http://www.nctaiwan.com/" target="_blank">NC Taiwan</a></li>
              <li><a href="https://www.ncvvs.vn/" target="_blank">NC Vietnam Visual Studio</a></li>
            </ul>
            <ul v-if="lang in values('jp')">
              <li><a href="https://kr.ncsoft.com/jp/index.do" target="_blank">NCSOFT</a></li>
              <li><a href="https://www.ncfoundation.or.kr/en/main" target="_blank">NC Cultural Foundation</a></li>
              <li><a href="https://www.plaync.com/global/index?locale=ja-NC" target="_blank">PLAYNC</a></li>
              <li><a href="https://www.ncjapan.co.jp/" target="_blank">NC Japan</a></li>
              <li><a href="http://www.nctaiwan.com/" target="_blank">NC Taiwan</a></li>
              <li><a href="https://us.ncsoft.com/en-us" target="_blank">NC West</a></li>
              <li><a href="https://www.arena.net/en" target="_blank">ArenaNet</a></li>
              <li><a href="https://www.ncvvs.vn/" target="_blank">NC Vietnam Visual Studio</a></li>
            </ul>
            <ul v-if="lang in values('tw')">
              <li><a href="https://kr.ncsoft.com/cn/index.do" target="_blank">NCSOFT</a></li>
              <li><a href="https://www.ncfoundation.or.kr/en/main" target="_blank">NC Cultural Foundation</a></li>
              <li><a href="https://www.plaync.com/global/index?locale=zh-NC" target="_blank">PLAYNC</a></li>
              <li><a href="http://www.nctaiwan.com/" target="_blank">NC Taiwan</a></li>
              <li><a href="https://us.ncsoft.com/en-us" target="_blank">NC West</a></li>
              <li><a href="https://www.arena.net/en" target="_blank">ArenaNet</a></li>
              <li><a href="https://www.ncvvs.vn/" target="_blank">NC Vietnam Visual Studio</a></li>
            </ul>
            <ul v-if="lang in values('vn')">
              <li><a href="https://kr.ncsoft.com/en/index.do" target="_blank">NCSOFT</a></li>
              <li><a href="https://www.ncfoundation.or.kr/en/main" target="_blank">NC Cultural Foundation</a></li>
              <li><a href="https://www.ncvvs.vn/" target="_blank">NC Vietnam Visual Studio</a></li>
              <li><a href="http://www.nctaiwan.com/" target="_blank">NC Taiwan</a></li>
              <li><a href="https://us.ncsoft.com/en-us" target="_blank">NC West</a></li>
              <li><a href="https://www.arena.net/en" target="_blank">ArenaNet</a></li>
            </ul>
          </div>
        </div>
        <div class="global" :class="langOpen === true ? 'active' : null">
          <a @click="langOpen = !langOpen">GLOBAL<i></i></a>
          <div class="menus">
            <ul>
              <li class="lng-menu-li" :class="{ on: code === lang }" v-for="(label, code) in languages" @click="changeLang(code,$event)" :key="code">
                {{ label }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="logo">
        <p>
          <video class="visual" autoplay muted playsinline loop src="/video/footer/NC_footer_241210.mp4" ></video>
          <span></span>
        </p>
        <img class="dark" src="/img/footer/footer-logo-dark.png" alt=""/>
        <img class="light" src="/img/footer/footer-logo.png" alt=""/>
      </div>
      <div class="info-share">
        <ul class="ir">
          <li v-if="lang in values('ko')"><a href="https://www.facebook.com/NCSOFT.Korea" target="_blank">ncsoft facebook</a></li>
          <li v-if="lang in values('en','jp','tw','vn')"><a href="https://www.facebook.com/NCSOFT" target="_blank">ncsoft facebook</a></li>
          <li><a href="https://www.instagram.com/ncsoft.official/" target="_blank">ncsoft instagram</a></li>
          <li><a href="https://www.youtube.com/user/ncsoft" target="_blank">ncsoft youtube channel</a></li>
<!--          <li v-if="lang in values('ko')"><a href="https://post.naver.com/ncsoft-corp" target="_blank">ncsoft naver post</a></li>-->
          <li><a href="https://www.linkedin.com/company/ncsoft/" target="_blank">ncsoft linkedin</a></li>
          <li><a href="https://www.tiktok.com/@ncsoft" target="_blank">ncsoft TIKTOK</a></li>
        </ul>
        <p class="copy">
          <locale-link to="/blog-operating-policy">{{ $t('_.policy') }}</locale-link> ㅣ <a href="tel:02-2186-3300">{{ $t('_.contact') }} : 02-2186-3300</a><br>
          ⓒ NCSOFT Corporation. All Rights Reserved.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  lexicon: 'footer',
  data() {
    return {
      sitemapOpen:false,
      langOpen:false,
    };
  },
  watch: {
    $route: 'reset',
  },
  computed: {
    theme() {
      return this.$store.state.theme;
    },
  },
  methods: {
    documentDown(e) {
      if (!this.$el.querySelector('.map').contains(e.target)) {
        this.reset();
      }
    },
    reset() {
      this.sitemapOpen = false;
      this.langOpen = false;
    },
    goTop() {
      this.$scroll.scrollMove(0, 2000);
    },
  },
  mounted() {
    document.addEventListener('click', this.documentDown);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.documentDown);
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[footer] {.rel; .bgc(#fff);
  &.viewer, &.search { .hide; }

  .inner {.proj-max-w; .rel; .f; .block; .p(37, 18, 24); .-box;}
  .go-top {.wh(44, 55);  .block; .mh-c; .mb(120); .bgc; overflow: hidden;
    span{.f; .block; transform:translateY(0%); .bg('/img/common/go-top-arrow.png'); .no-repeat; .contain; transition: transform .6s cubic-bezier(0.4,-0.03, 0, 1.03);}
    .no-touch &:hover{
      span{transform:translateY(-100%);}
    }
  }
  .map{.mb(180);
    a{.p(20,0); .-b(#1e1e1e); .block; .wf; .bgc; .tl; .fs(14); .medium; .c(#1e1e1e); transition:color .2s ease;
      i{.wh(16); transform:rotate(0deg); .bg('/img/common/ic-more.png'); .no-repeat; .contain; .ib; .fr; transition:transform .4s ease;}
      .no-touch &:hover{.c(#666);}
    }
    .menus{overflow: hidden; height:auto; .max-h(0); transition: max-height .7s cubic-bezier(0, 0, 0.3, 1.02);
      ul{.pt(12);
        li{ text-transform:uppercase; .p(8,0); .fs(14); .c(#1e1e1e);
          .no-touch &:hover{.bold;}
          a{.block; .p; .-a;}
          &:last-of-type{.pb(0);}
        }
      }
    }
    .global {
      a{.mt(28);}
      .menus ul{
        li{.c(#666); cursor: pointer;
          &.on{.medium; .c(#1e1e1e);}
        }
      }
    }
    .active{
      a{
        i{transform:rotate(-135deg);}
      }
      .menus{.max-h(500); transition: max-height 1.2s cubic-bezier(0.32, 0.08, 0.24, 1) .02s;}
    }
  }
  .logo{.wf; .mb(60); .block; .rel; height:auto;
    .dark{.hide;}
    > img{.wf; .block;}
    p{.hf; .block; .abs; .lt(25%,0); width:auto;
      span{.h(100%); .w(102%); .abs; .lt(-1,0); .bg('/img/footer/mask-white.png'); .cover; .no-repeat;}
      video{.hf; .rel; }
    }
  }
  .info-share{.tc;
    ul{.mb(60);
      &.ir{text-indent:0;}
      li{.ib; .m(0,10);
        &:nth-of-type(1) a{.bg('/img/common/sns-fb.svg');}
        &:nth-of-type(2) a{.bg('/img/common/sns-instagram.svg');}
        &:nth-of-type(3) a{.bg('/img/common/sns-youtube.svg');}
        &:nth-of-type(4) a{.bg('/img/common/sns-linkedin.svg');}
        &:nth-of-type(5) a{.bg('/img/common/sns-tictok.svg');}
        a{.wh(24); .block; .contain; .no-repeat; .o(0.8); transition:opacity .2s ease;
          .no-touch &:hover{.o(1);}
          img{.wf; .block;}
        }
      }
    }
    .copy{.fs(10,1.9); .c(#333);
      a{.ib; transition:color .2s ease;
        .no-touch &:hover{.c(#000);}
      }
    }
  }
  &.dark{.bgc(#000);
    .go-top span{.bg('/img/common/go-top-arrow-dark.png');}
    .map {
      a {.c(#ebebeb); .-b(#ebebeb);
        i {.bg('/img/common/ic-more-dark.png');}
        .no-touch &:hover{.c(#888);}
      }
      .menus{
        ul li{.c(#ebebeb);}
      }
      .global{
        .menus ul{
          li {.c(#888);
            &.on { .c(#ebebeb);}
          }
        }
      }
    }
    .logo{
      p{
        span{.bg('/img/footer/mask-dark.png');}
      }
      .light{.hide;}
      .dark{.block;}
    }
    .info-share{
      ul{
        li{
          &:nth-of-type(1) a{.bg('/img/common/sns-fb-dark.svg');}
          &:nth-of-type(2) a{.bg('/img/common/sns-instagram-dark.svg');}
          &:nth-of-type(3) a{.bg('/img/common/sns-youtube-dark.svg');}
          &:nth-of-type(4) a{.bg('/img/common/sns-linkedin-dark.svg');}
          &:nth-of-type(5) a{.bg('/img/common/sns-tictok-dark.svg');}
        }
      }
      .copy{.c(#888);
        a{
          .no-touch &:hover{.c(#ddd);}
        }
      }
    }

  }
}
@media (min-width: @screen-tp-min) {
  [footer] {
    .inner{.p(36,36,24);}
    .map{
      .global {
        a{.mt(40);}
      }
    }
    .logo{.mb(72);}
    .info-share {
      ul {.mb(72);}
    }
  }
}
@media (min-width: @screen-tl-min) {
  [footer] {
    .inner{.p(44,36,28);}
    .go-top{.abs; .m(0); .rt(36,32);}
    .map{.abs; .lt(36,44);
      a {.p(0, 0, 16); .fs(12);
        i {.wh(14); .mt(2);}
      }
      .family, .global{.ib; .w(186); .vat; .rel; .fr;}
      .family{.ml(52);}
      .global{
        a{.mt(0);}
      }
      .menus{.abs; .lt(0,37);
        ul{ li{.p(4,0); .fs(12);} }
      }
    }
    .logo{.w(606); .mb(0); .mt(260);
      #app.en &, #app.jp &{.mt(340);}
      #app.tw &{.mt(320);}
      #app.vn &{.mt(280);}
    }
    .info-share{.abs; .rb(36,28); .tr;
      ul{.mb(22);
        li{.m(0,9);
          &:last-of-type{.mr(0);}
          a{.wh(17);}
        }
      }
      .copy{.fs(8,1.8);}
    }
  }
}
@media (min-width: @screen-dm-min) {
  [footer] {
    .inner{.p(62,48,36);}
    .go-top{.rt(48,45); .wh(60,75);}
    .map{.lt(48,62);
      a {.p(0, 0, 24); .fs(16);
        i {.wh(20); .mt(2);}
      }
      .family, .global{.w(258);}
      .family{.ml(72);}
      .menus{.lt(0,53);
        ul{.pt(18);
          li{.p(6,0); .fs(16);}
        }
      }
    }
    .logo{.w(853); .mt(366);
      p{
        span{.h(102%); .w(102%); .lt(-1,-1);}
      }
      #app.en &, #app.jp &{.mt(480);}
      #app.tw &{.mt(460);}
      #app.vn &{.mt(442);}
    }
    .info-share{.rb(52,36);
      ul{.mb(34);
        li{.m(0,13);
          a{.wh(24);}
        }
      }
      .copy{.fs(10,1.7);}
    }
  }
}
@media (min-width: @screen-dl-min) {
  [footer] {
    .inner{.p(83,60,48);}
    .go-top{.rt(60,60); .wh(80,100);}
    .map{.lt(60,83);
      a {.p(0, 0, 30); .fs(21);
        i {.wh(28); .mt(0);}
      }
      .family, .global{.w(344);}
      .family{.ml(96);}
      .menus{ .lt(0,67);
        ul{.pt(24);
          li{.p(8,0); .fs(21);}
        }
      }
    }
    .logo{.w(1137); .mt(487);
      #app.en &, #app.jp &{.mt(630);}
      #app.tw &{.mt(560);}
      #app.vn &{.mt(500);}
    }
    .info-share{.rb(60,48);
      ul{.mb(46);
        li{.m(0,17);
          a{.wh(32);}
        }
      }
      .copy{.fs(14,1.7);}
    }
  }
}
</style>
