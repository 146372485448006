<template>
  <div popup :class="{ on }">
    <div class="dim"></div>
    <div class="holder">
      <slot />
    </div>
  </div>
</template>

<style lang="less">
@import "~@/less/proj.less";

[popup] { z-index: 200; .fix; .lt; .wf; .fvh(); .o(0); .tr-o(0.5s);
  .dim { .fix; .lt; width:100%; height:120%; background: rgba(0,0,0,0.8); z-index: 50; }
  .holder { .abs; .lt; z-index: 51; .wf; .fvh; }
  .close { .contain('/img/common/btn-close-white.png'); .no-repeat; .wh(20); .abs; .rt(23,18); .contain; z-index: 1; }
  .panel { .rel; .t(50%); .t-yc; }

  &.on { .o(1); }
}
@media (min-width: @screen-tp-min) {
  [popup] {
    .holder { position: relative; }
    .close { .wh(30); .rt(30,30); }
  }
 
}
@media (min-width: @screen-tl-min) {
  [popup] {
    .close { .wh(30); .rt(40,40); }
  }
}



</style>

<script>
export default {
  name: 'Popup',
  data() {
    return {
      on: false,
      height: '100vh',
    };
  },
  props: {
    freezing: { type: Boolean, default: true },
  },
  methods: {
    documentDown(e) {
      if (e.target.classList.contains('holder')) {
        this.$emit('close');
      }
    },
    keyDown(e) {
      if(e.key === "Escape") {
        this.$emit('close');
      }
    },
    resized() {
      this.height = window.innerHeight + 'px';
    },
  },
  mounted() {
    document.querySelector('.fixed-holder').appendChild(this.$el);
    this.$props.freezing && this.$scroll.freeze();
    this.on = true;
    this.resized();
    window.addEventListener('resize', this.resized);
    document.addEventListener('keydown', this.keyDown);
    document.addEventListener('click', this.documentDown);
  },
  beforeDestroy() {
    this.$props.freezing && this.$scroll.release();
    window.removeEventListener('resize', this.resized);
    document.removeEventListener('keydown', this.keyDown);
    document.removeEventListener('click', this.documentDown);
  },
};
</script>
