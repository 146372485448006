<template>
  <div :class="[info.type, info.value.id]"></div>
</template>

<script>

export default {
  name: 'Blank',
  props: ['value'],
  computed: {
    info() {
      return this.value;
    },
  },
};
</script>

