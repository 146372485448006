<template>
  <a clip-board class="clipboard-btn" :data-clipboard-text="shareUrl">
    <slot />
  </a>
</template>

<script>
import ClipboardJS from "clipboard";

export default {
  name: 'ClipBoard',
  props: {
    copyPath: String,
  },
  data() {
    return {
      shareUrl: '',
      clipboard: null,
    };
  },
  watch: {
    copyPath: {
      handler(v) {
        this.shareUrl =  document.location.origin + ( v ? `${this.$i18n('path')}${v}` : document.location.pathname );
      },
      deep: true,
    }
  },
  mounted() {
    this.shareUrl =  document.location.origin + ( this.$props.copyPath ? `${this.$i18n('path')}${this.$props.copyPath}` : document.location.pathname );

    if (!this.clipboard) {
      this.clipboard = new ClipboardJS('.clipboard-btn');
    }

    this.clipboard.on('success', (e) => {
      if(this.$el === e.trigger) {
        e.clearSelection();
        if(this.lang === 'ko') window.alert('URL 복사가 완료되었습니다.');
        else window.alert('Link copied to clipboard');
      }
    });
  },
  beforeDestroy() {
    this.clipboard.destroy();
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";

[clip-board] { .rel;
}
</style>
