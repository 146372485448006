<template>
  <div app-header :class="[ theme, roll && 'roll', scrolled && 'scrolled' ]">
    <header>
      <div class="inner">
        <locale-link to="/" class="home"><h1 class="ir">NC</h1></locale-link>
        <div class="h-tab" :class="path" v-if="lang in values('ko','en','jp')">
          <locale-link to="/play">PLAY<span></span></locale-link>
          <locale-link to="/news">NEWS<span></span></locale-link>
          <!--
          <locale-link to="/news/article/nc-creators-season-1" class="hidden-ml-down" v-if="lang in values('ko')">NC CREATORS<span></span></locale-link>
          <locale-link to="/news/article/nc-creators-season-1" class="hidden-tp-up hidden-ms-down" v-if="lang in values('ko')">CREATORS<span></span></locale-link>
          -->
        </div>
        <div class="h-btn" >
          <a class="search" @click="clickSearch"></a>
          <a class="menu" @click="clickMenu" v-if="lang in values('ko','en','jp')"></a>
        </div>
      </div>
    </header>
    <HeaderSearch :class="{ open: searchOpen }" @close="searchClose" ref="search"/>
    <div class="gnb" :class="{ open: menuOpen }">
      <div class="inner">
        <a class="close" @click="clickClose"></a>
        <div class="section menu">
          <ul>
            <li class="label"><locale-link to="/play">PLAY</locale-link></li>
            <li v-for="row in categories('play')" :key="row.code"><locale-link :to="row.data.route">{{ row.label }}</locale-link></li>
          </ul>
        </div>
        <div class="section menu">
          <ul v-if="lang in values('ko','en')">
            <li class="label"><locale-link to="/news/">NEWS</locale-link></li>
            <li v-for="row in categories('news')" :key="row.code"><locale-link :to="row.data.route">{{ row.label }}</locale-link></li>
          </ul>
          <ul v-else>
            <li class="label"><locale-link to="/news/">NEWS</locale-link></li>
            <li><locale-link to="/news">All</locale-link></li>
          </ul>
        </div>
        <div class="section social">
          <ul>
            <li class="label"><span>SOCIAL</span></li>
            <li v-if="lang in values('ko')"><a href="https://www.facebook.com/NCSOFT.Korea" target="_blank">FACEBOOK<i/></a></li>
            <li v-if="lang in values('en','jp','tw','vn')"><a href="https://www.facebook.com/NCSOFT" target="_blank">FACEBOOK<i/></a></li>
            <li><a href="https://www.instagram.com/ncsoft.official/" target="_blank">INSTAGRAM<i/></a></li>
            <li><a href="https://www.youtube.com/user/ncsoft" target="_blank">YOUTUBE<i/></a></li>
            <li><a href="https://www.linkedin.com/company/ncsoft/" target="_blank">LINKEDIN<i/></a></li>
            <li><a href="https://www.tiktok.com/@ncsoft" target="_blank">TIKTOK<i/></a></li>
          </ul>
          <ul>
            <li class="label"><span>GLOBAL</span></li>
            <li class="lng-menu-li" :class="{ on: code === lang }" v-for="(label, code) in languages" @click.stop="changeLang(code)" :key="code">
              <span>{{ label }}</span>
            </li>
          </ul>
        </div>
        <div class="lang hidden-dm-up" :class="{ open: globalOpen }">
          <a @click="globalOpen = !globalOpen"><i class="global-ic"></i><span>{{ language }}</span><i class="dd-ic"></i></a>
          <div class="lang-popup">
            <ul>
              <li class="lng-menu-li" :class="{ on: code === lang }" v-for="(label, code) in languages" @click.stop="changeLang(code)" :key="code">
                {{ label }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import HeaderSearch from "@/views/components/HeaderSearch";

export default {
  name: 'AppHeader',
  components: {HeaderSearch},
  data() {
    return {
      menuOpen:false,
      globalOpen:false,
      searchOpen: false,
    };
  },
  computed: {
    theme() {
      return this.$store.state.theme;
    },
    roll() {
      return this.$store.state.browser.scrollDirection === 'down' //&& this.$store.state.scrollAgency.scrollTop > 0;
    },
    scrolled() {
      return this.$store.state.scrollAgency.scrollTop > 5;
    },
    path() {
      return this.$route.path.split('/').at(-1);
    },
  },
  watch: {
    $route: 'reset',
  },
  mounted() {
    this.$root.$on('search', this.clickSearch);
  },
  beforeDestroy() {
    this.$root.$off('search', this.clickSearch);
  },
  methods: {
    categories(root) {
      return [{label: 'All', data: { route: `/${root}/all` }}, ...this.codeList(root)];
    },
    reset() {
      this.menuOpen = false;
      this.searchOpen = false;
      this.globalOpen = false;

      this.$refs.search.reset();
      this.$scroll.release();
    },
    clickMenu(){
      this.$scroll.freeze();
      this.menuOpen = true;
    },
    clickSearch(){
      this.$scroll.freeze();
      this.searchOpen = true;
    },
    clickClose(){
      this.$scroll.release();
      this.menuOpen = false;
    },
    searchClose(){
      this.$scroll.release();
      this.searchOpen = false;
    }
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";

[app-header] { .fix; z-index:49;
  &.viewer, &.search { .hide; }
  &.roll header{.t-y(-100%);}
  header{.fix; .bgc(#fff); .wf; .h(60); .t-y(0); transition: transform 0.6s ease;
    .inner{.rel; .p(14,18); .f; .-box; .proj-max-w; .fs(0);
      .home{.ib; .wh(37,20); .rel; .t(50%); .t-yc; .bg('/img/common/header-nc-icon.png'); .contain; .no-repeat;}
      .h-tab{.abs; .t-xyc; .lt(50%,50%); white-space: nowrap;
        a{.ib; .m(0,6); .c(#a9a9a9); .fs(15); .medium; .pb(4); .rel; transition: all .3s ease;
          span{.wh(0,2); .abs; .lb(50%,0); .t-xc; .bgc(#a9a9a9); transition:width .8s cubic-bezier(.86,0,.07,.995), background-color .3s ease;}
          .no-touch &:hover{.c(#1e1e1e);}
          &.router-link-active{.c(#1e1e1e); .bold;
            span{.wf; .bgc(#1e1e1e);}
          }
        }
        &.nc-creators a:nth-of-type(2) { .c(#a9a9a9); .medium;
          span{.w(0); .bgc(#1e1e1e);}
        }
      }
      .h-btn{.fr; .rel; .t(50%); .t-yc; .h(20);
        a{.wh(20); .ml(16); .contain; .no-repeat; .bgc; .ib; transition: opacity .3s ease;
          .no-touch &:hover{.o(0.6);}
          &.search{.bg('/img/common/header-search-icon.png');}
          &.menu{.bg('/img/common/header-menu-icon.png');}
        }
      }
    }
  }
  [header-search]{ transform: translateY(-100%); .o(0); transition:opacity .4s ease, transform 0s ease; transition-delay: 0s,1s;
    &.open{transform: translateY(0%); .o(1); transition-delay: 0s,0s;}
  }
  .gnb{.fix; .wf; .fvh(); .bgc(#fff); .lt(0,-150%); overflow-y:auto; .p(0,18,25); .-box; .c(#1e1e1e); .o(0); transition: opacity 1s ease, top 0s ease; transition-delay:.5s, 1.5s;
    .inner{.proj-max-w; .rel; .t(-100%); transition-delay:1s;}
    .close{.fix; .rt(18,20); .wh(20); z-index:9; .contain; .no-repeat; .bg('/img/common/ic-close.png'); .bgc; transform:translateY(-100px); transition:opacity .3s ease, transform 0s ease; transition-delay: 0s, 1s;
      .no-touch &:hover{.o(0.6);}
    }
    li, a{.c(#1e1e1e);}
    ul{
      li.label{.block;
        span{
          &:after{.hide;}
        }
      }
      li{
        a, span{.ib; .rel;
          &:after{content:""; .w(0); .h(1); .bgc(#1e1e1e); .abs; .l(0); .b(0); transition:width .6s cubic-bezier(0.49,-0.01, 0.07, 1);}
          .no-touch &:hover{
            &:after{.wf; }
          }
        }
      }
    }
    .menu{
      &:first-of-type ul{.pt(84);}
      ul{.pt(72);
        li.label {.mb(12); .block;
          a{.fs(20); .ff-en; .bold; .ib;
            &:after{.h(2); .b(0);}
          }
        }
        li{overflow:hidden;
          a, span{transform: translateY(100%); .block; transition:transform 1.2s cubic-bezier(0.49,-0.01, 0.07, 1); transition-delay: 0s;}
          a{.fs(46); .ff-hvd-black; .rel; .ib;
            &:after{ .h(3); .b(10);}
          }
        }
      }
    }
    .lang{.rel;
      &.open{
        a{
          i{transform:rotate(180deg);}
        }
        .lang-popup{transform:scale(1);
          ul li{.o(1); transform: translateY(0px);}
          li:nth-of-type(1) { transition-delay: 0s, 0.2s, 0.2s;}
          li:nth-of-type(2) { transition-delay: 0s, 0.3s, 0.3s;}
          li:nth-of-type(3) { transition-delay: 0s, 0.4s, 0.4s;}
          li:nth-of-type(4) { transition-delay: 0s, 0.5s, 0.5s;}
          li:nth-of-type(5) { transition-delay: 0s, 0.6s, 0.6s;}
        }
      }
      a{.mt(158); .ib; .bgc; .c(#1e1e1e);
        span{.ib; .vam; .p(0,4,0,8); .fs(14); .medium;}
        i{.ib; .vam; .contain; .no-repeat;
          &.global-ic{.wh(16); .bg('/img/common/icon-global.png');}
          &.dd-ic{.wh(14); .bg('/img/common/ic-dropdown.png');}
        }
      }
      .lang-popup{.abs; .lb(0,28); .p(18,20,20); transform:scale(0); .bgc(#fff); .br(10); transform-origin:left bottom; .-a(#ebebeb); box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1); transition: transform 0.6s cubic-bezier(0.54,-0.03, 0.06, 0.98);
        ul li{.fs(14); .medium; .c(#888); .p(4); transition-delay: 0s; transition:color .2s ease, opacity .3091s cubic-bezier(0.32, 0.08, 0.24, 1) .03s, transform .3455s cubic-bezier(0.32, 0.08, 0.24, 1) .02s;  .o(0); transform: translateY(10px);
          &.on, .no-touch &:hover{.c(#1e1e1e);}
          &:first-of-type{.pt(0);}
          &:last-of-type{.pb(0);}
        }
      }
    }
    .social {.hide;
      ul{.o(0); transition:opacity .8s ease; transition-delay:0s;
        .lng-menu-li{
          &:nth-of-type(2){.ff-kr;}
          &:nth-of-type(3){.ff-en;}
          &:nth-of-type(4){.ff-ja;}
          &:nth-of-type(5){.ff-zh;}
          &:nth-of-type(6){.ff-vi;}
        }
      }
    }
    &.open{.o(1); .t(0); transition-delay:0s, 0s;
      .inner{ .lt(0,0%); transition-delay:0s;}
      .close{transform:translateY(0); transition-delay:0s,0s;}
      .menu{
        ul li{
          a, span{transform: translateY(0);}
        }
      }
      .social ul{.o(1); transition-delay:1s;}
      .section{
        &:after{.h(100%);}
      }
      .menu{
        &:first-of-type{
          ul li{
            &:nth-of-type(2) a, span{transition-delay: .1s}
            &:nth-of-type(3) a, span{transition-delay: .2s}
            &:nth-of-type(4) a, span{transition-delay: .3s}
            &:nth-of-type(5) a, span{transition-delay: .4s}
            &:nth-of-type(6) a, span{transition-delay: .5s}
          }
        }
        &:nth-of-type(2){
          ul li.label a{transition-delay: .6s}
          ul li{
            &:nth-of-type(1) a, span{transition-delay: .6s}
            &:nth-of-type(2) a, span{transition-delay: .7s}
            &:nth-of-type(3) a, span{transition-delay: .8s}
            &:nth-of-type(4) a, span{transition-delay: .9s}
            &:nth-of-type(5) a, span{transition-delay: 1s}
            &:nth-of-type(6) a, span{transition-delay: 1.1s}
            &:nth-of-type(7) a, span{transition-delay: 1.2s}
            &:nth-of-type(8) a, span{transition-delay: 1.3s}
            &:nth-of-type(9) a, span{transition-delay: 1.4s}
          }
        }
      }
    }
  }
  &.dark, &.dark-header{
    header{.bgc;  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(30, 30, 30, 0));
      .inner{
        .home{.bg('/img/common/header-nc-icon-dark.png');}
        .h-tab{
          a{
            span{.bgc(#a9a9a9);}
            .no-touch &:hover{.c(#fff);}
            &.router-link-active {.c(#fff);
              span{.bgc(#fff);}
            }
          }
        }
        .h-btn{
          a{
            &.search{.bg('/img/common/header-search-icon-dark.png');}
            &.menu{.bg('/img/common/header-menu-icon-dark.png');}
          }
        }
      }
    }
    .gnb{.bgc(#000); .c(#fff);
      .close{.bg('/img/common/ic-close-dark.png');}
      li, a{.c(#fff);}
      .section{
        &:after{.bgc(#666);}
        &.social{
          ul li i{.bg('/img/common/ic-out-dark.png');}
        }
      }
      ul{
        li{
          a, span{
            &:after{.bgc(#fff); }
          }
        }
      }
      .lang{
        a{
          i{
            &.global-ic{.bg('/img/common/icon-global-dark.png');}
            &.dd-ic{.bg('/img/common/ic-dropdown-dark.png');}
          }
        }
        .lang-popup{.bgc(#1e1e1e); .-a;
          ul li{
            &.on, .no-touch &:hover{.c(#fff);}
          }
        }
      }
    }
  }
   &.dark-list header{.bgc(#000);}
}
@media (min-width: @screen-tp-min) {
  [app-header] {
    header{
      .inner{.p(14,36);
        .h-tab {
          a {.m(0, 12);}
        }
      }
    }
    .gnb{.p(0,38,48);
      .close{.rt(36,20);}
      .menu{
        ul{.pt(96);
          li.label{ a{.fs(22);}}
          li{
            a{.fs(62);}
          }
        }
      }
      .lang{
        a{.mt(200);
          span{.fs(18)}
          i{
            &.global-ic{.wh(18);}
            &.dd-ic{.wh(14);}
          }
        }
        .lang-popup{.lb(0,32); .p(20,22,22);
          ul li{.fs(18);
          }
        }
      }
    }
   &.dark-list header{.bgc;}
  }
}
@media (min-width: @screen-tl-min) {
  [app-header] {
    header{
      .inner{
        .h-tab {
          a {.m(0, 18);}
        }
        .h-btn{
          a {.ml(20);}
        }
      }
    }
    .gnb{.p(0,36,48);
      .lang{ .lang-popup{.lb(0,36);} }
    }
    @media (min-width: @screen-tl-min) and (max-width: @screen-dm-min) and (max-height: 900px) {
      .gnb{min-height: calc(var(--vh, 1vh) * 100);.p;
        .section.menu, .section.social{.min-h(560);}
        .lang{.hide;}
        .inner{.f;}
        .menu{
          ul{
            li.label a{.fs(18);}
            li a{.fs(40);}
          }
        }
        &.open{
          .menu{
            &:first-of-type ul li,
            &:nth-of-type(2) ul li{
              &:nth-of-type(1) a, span{transition-delay: 0s}
              &:nth-of-type(2) a, span{transition-delay: .1s}
              &:nth-of-type(3) a, span{transition-delay: .2s}
              &:nth-of-type(4) a, span{transition-delay: .3s}
              &:nth-of-type(5) a, span{transition-delay: .4s}
              &:nth-of-type(6) a, span{transition-delay: .5s}
              &:nth-of-type(7) a, span{transition-delay: .6s}
              &:nth-of-type(8) a, span{transition-delay: .7s}
              &:nth-of-type(9) a, span{transition-delay: .8s}
            }
          }
          &:nth-of-type(2) ul li.label a{transition-delay: 0s !important;}
        }
        .close{.rt(36,20); .wh(20); .abs;}
        .section{width:calc(100% / 3); .hf; .ib; .vat; .p(60,36); .-box; .min-h(805); .rel;
          &:after{.wh(1,0%); .bgc(#c9c9c9); content:''; .abs; .rt(0,0); transition:height 2s cubic-bezier(0.49,-0.01, 0.07, 1);}
          &.social {
            &:after{.bgc;}
          }
          &:first-of-type{ .pl(48);
            ul{.p;}
          }
          ul{.p(0);}
        }
        .social{.ib;
          ul{
            &:last-of-type{.pt(76);}
            li.label{.fs(14); .medium; .mb(14);}
            li{.p(4,0); .fs(14);
              i{.bg('/img/common/ic-out.png'); .wh(14); .contain; .no-repeat; .ib; .ml(6); .vam;}
            }
          }
        }
      }
    }
  }
}
@media (min-width: @screen-dm-min) {
  [app-header] {
    header{.h(80);
      .inner{.p(20,48);
        .home{.wh(52,28);}
        .h-tab {
          a {.m(0, 24); .fs(20); .pb(5);
            span {.h(3);}
          }
        }
        .h-btn{.h(28);
          a{.wh(28); .ml(24);}
        }
      }
    }
    .gnb{min-height: calc(var(--vh, 1vh) * 100);.p;
      .inner{.f;}
      &.open{
        .menu{
          &:first-of-type ul li,
          &:nth-of-type(2) ul li{
            &:nth-of-type(1) a, span{transition-delay: 0s}
            &:nth-of-type(2) a, span{transition-delay: .1s}
            &:nth-of-type(3) a, span{transition-delay: .2s}
            &:nth-of-type(4) a, span{transition-delay: .3s}
            &:nth-of-type(5) a, span{transition-delay: .4s}
            &:nth-of-type(6) a, span{transition-delay: .5s}
            &:nth-of-type(7) a, span{transition-delay: .6s}
            &:nth-of-type(8) a, span{transition-delay: .7s}
            &:nth-of-type(9) a, span{transition-delay: .8s}
          }
          &:nth-of-type(2) ul li.label a{transition-delay: 0s;}
        }
      }
      .close{.rt(48,26); .wh(28); .abs;}
      .section{width:calc(100% / 3); .hf; .ib; .vat; .p(60,36); .-box; .min-h(805); .rel;
        &:after{.wh(1,0%); .bgc(#c9c9c9); content:''; .abs; .rt(0,0); transition:height 2s cubic-bezier(0.49,-0.01, 0.07, 1);}
        &.social {
          &:after{.bgc;}
        }
        &:first-of-type{ .pl(48);
          ul{.p;}
        }
        ul{.p(0);}
      }
      .social{.ib;
        ul{
          &:last-of-type{.pt(96);}
          li.label{.fs(14); .medium; .mb(14);}
          li{.p(4,0); .fs(18);
            i{.bg('/img/common/ic-out.png'); .wh(14); .contain; .no-repeat; .ib; .ml(6); .vam;}
          }
        }
      }
    }
  }
}
@media (min-width: @screen-dx-min) {
  [app-header] {
    header{.h(96);
      .inner{.p(22,60);
        .home{.wh(65,35);}
        .h-tab {
          a {.m(0, 29.5); .fs(25); .pb(6);
            span {.h(4);}
          }
        }
        .h-btn{.h(32);
          a{.wh(32); .ml(32);}
        }
      }
    }
    .gnb{
      .close{.rt(60,32); .wh(32);}
      .section{.p(82,48); .min-h(1066);
        &:first-of-type{ .pl(60);}
      }
      .menu{
        ul {
          li.label {.mb(18);
            a {.fs(28);}
          }
          li {
            a {.fs(82);}
          }
        }
      }
      .social{
        ul{
          &:last-of-type{.pt(120);}
          li{.p(5,0); .fs(24);
            &.label{.fs(18); .mb(19);}
            i{.wh(20); .ml(8);}
          }
        }
      }
    }
  }
}
</style>
